import React from "react";

// lib
import { MdLuggage } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { BsCreditCardFill } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { format, addDays } from "date-fns";

// custom
import v_plan from "../../../assets/imgs/v_plane.svg";
import point from "../../../assets/imgs/point.svg";
import { useFlightContext } from "../../../contexts/flight_context";
import { getDuration, getLocalStorage } from "../../../utils/helpers";

function FlightAside() {
	const { single_flight } = useFlightContext();

	// get current search data
	const currentSearchData = getLocalStorage("flight_form");

	//collect data
	const passengersCount =
		currentSearchData?.AdultCount +
		currentSearchData?.ChildCount +
		currentSearchData?.InfantCount;

	const flightTripsNames =
		single_flight?.FlightDetails?.Details.length > 0 &&
		single_flight?.FlightDetails?.Details.map((item: any) => {
			const firstStop = item[0];
			const lastStop = item[item.length - 1];
			return (
				firstStop.Origin.CityName +
				" (" +
				firstStop.Origin.AirportCode +
				") - " +
				lastStop.Destination.CityName +
				" (" +
				lastStop.Destination.AirportCode +
				")"
			);
		});

	const tripsCount = single_flight?.FlightDetails?.Details.length;
	const firstTripStops =
		tripsCount == 0 ? 0 : single_flight?.FlightDetails?.Details[0].length;
	const lastTripStops =
		tripsCount == 0
			? 0
			: single_flight?.FlightDetails?.Details[tripsCount - 1].length;
	const firstOrigin =
		tripsCount > 0 ? single_flight?.FlightDetails?.Details[0][0] : {};

	const lastDestination =
		tripsCount > 0
			? single_flight?.FlightDetails?.Details[tripsCount - 1][lastTripStops - 1]
			: {};

	let total = 0;
	const fullDuration = single_flight?.FlightDetails?.Details[0]?.reduce(
		(next: any, item: any) => {
			const { Duration, LayOverTime } = item;
			return (total +=
				parseInt(Duration) + parseInt(LayOverTime ? LayOverTime : 0));
		},
		{ total: 0 }
	);

	return (
		<aside className="s_1000:col-span-3 col-span-12 flex s_1000:flex-col flex-wrap s_1000:justify-start justify-center  s_1000:space-y-4 s_1000:mt-0 mt-12">
			{/* price */}
			<div className="bg-white shadow-listShadow rounded-md s_1000:mt-0 mt-4 s_1000:mr-0 mr-10">
				<h1 className="py-2 px-2 text-center text-clr-primary-green text-lg font-semibold border-b border-clr-primary-green">
					Booking Price
				</h1>
				<div className="flex flex-col py-4 px-6">
					<div className="flex flex-col space-y-2 pb-4">
						{/* passengers */}
						<div className="flex flex-row items-center justify-between">
							<p className="flex flex-row items-center space-x-1 text-clr-simple-bg text-base font-normal">
								<span>{`${passengersCount}x Passengers`}</span>
								<IoIosArrowDown className="" />
							</p>
							<span className="text-lg text-clr-simple-bg font-semibold">
								{`$${single_flight?.Price?.PriceBreakup?.BasicFare}`}
							</span>
						</div>
						{/* tax */}
						<div className="flex flex-row items-center justify-between">
							<p className="flex flex-row items-center space-x-1 text-clr-simple-bg text-base font-normal">
								<span>Taxes and charges</span>
								<IoIosArrowDown className="" />
							</p>
							<span className="text-lg text-clr-simple-bg font-semibold">
								{`$${single_flight?.Price?.PriceBreakup?.Tax}`}
							</span>
						</div>
					</div>
					<div className="flex flex-row items-center justify-between border-t pt-2">
						<span className="text-xl text-clr-simple-bg font-bold">Total</span>
						<span className="text-xl text-clr-primary-green font-bold">
							{`$${single_flight?.Price?.TotalDisplayFare}`}
						</span>
					</div>
				</div>
			</div>

			{/* baggage */}
			<div className="bg-white shadow-listShadow rounded-md s_1000:mt-0 mt-4 s_1000:mr-0 mr-10">
				<h1 className="py-2 px-2 text-center text-clr-primary-green text-lg font-semibold border-b border-clr-primary-green">
					Baggage Dimensions
				</h1>
				<div className="flex flex-col py-4 px-6">
					<div className="flex flex-col space-y-2 pb-4">
						{/* passengers */}
						<div className="flex flex-row items-center justify-between">
							<p className="flex flex-row items-center space-x-1 text-clr-simple-bg text-base font-normal">
								<MdLuggage className="text-clr-primary-green" />
								<span>Carry-on bag</span>
							</p>
							<span className="text-base text-clr-simple-bg font-semibold">
								55x20x40cm, 8kg
							</span>
						</div>
					</div>
				</div>
			</div>

			{/* flight details */}
			<div className="bg-white shadow-listShadow rounded-md s_1000:mt-0 mt-4 s_1000:mr-0 mr-10">
				<h1 className="py-2 px-2 text-center text-clr-primary-green text-lg font-semibold border-b border-clr-primary-green">
					flight details
				</h1>
				<div className="flex flex-col py-4 px-6">
					<div className="flex flex-col space-y-2 pb-4">
						{/* from & to */}
						<p className="text-clr-simple-bg text-base font-semibold">
							{flightTripsNames}
						</p>
						<div className="flex flex-row space-x-4">
							{/* texts */}
							<div className="flex flex-col space-y-10 relative">
								<span className="absolute left-2 border-2 border-dashed text-clr-primary-gray h-[68%] top-12"></span>
								<div className="flex flex-row space-x-2">
									<img src={point} alt="point" className="z-10 w-5 h-5" />
									<p className="flex flex-row space-x-1">
										<span className="text-base text-clr-simple-bg font-semibold">
											{`${
												firstOrigin?.Origin?.DateTime &&
												format(new Date(firstOrigin?.Origin?.DateTime), "HH:mm")
											} ${firstOrigin?.Origin?.CityName}`}
										</span>
										<span className="text-base text-clr-simple-bg font-medium">
											{`${firstOrigin?.Origin?.AirportName} (${firstOrigin?.Origin?.AirportCode})`}
										</span>
									</p>
								</div>
								<div className="flex flex-row space-x-2">
									<img
										src={v_plan}
										alt="point"
										className="z-10 w-8 h-8 -ml-[6px]"
									/>
									<p className="flex flex-col space-y-0">
										<span className="text-base font-semibold text-clr-primary-green">
											{single_flight.OperatorName}
										</span>
										<span className="flex flex-row space-x-1 text-base text-clr-primary-green font-medium">
											<span>Travel time: </span>
											<span className="text-clr-primary-green">
												{getDuration(fullDuration, true)}
											</span>
										</span>
									</p>
								</div>
								<div className="flex flex-row space-x-2">
									<img src={point} alt="point" className="z-10 w-5 h-5" />
									<p className="flex flex-row space-x-1">
										<span className="text-base text-clr-simple-bg font-semibold">
											{`${
												lastDestination?.Destination?.DateTime &&
												format(
													new Date(lastDestination?.Destination?.DateTime),
													"HH:mm"
												)
											} ${lastDestination?.Destination?.CityName} `}
										</span>
										<span className="text-base text-clr-simple-bg font-medium">
											{`${lastDestination?.Destination?.AirportName} (${lastDestination?.Destination?.AirportCode})`}
										</span>
									</p>
								</div>
							</div>
						</div>
						<p className="text-sm text-clr-simple-bg font-semibold px-5 py-2 bg-clr-stroke rounded-2xl w-1/2">
							{currentSearchData.CabinClass}
						</p>
					</div>
				</div>
			</div>
		</aside>
	);
}

export default FlightAside;
