import React, { useState, useEffect } from "react";

// lib
import { toast } from "react-toastify";
import Cookies from "js-cookie";

// custom
import { classNames } from "../../../utils/helpers";
import { axios_auth, axios } from "../../../utils/axios";

//regex
const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

function PasswordForm() {
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [passwordData, setPasswordData] = useState({
		old_password: "",
		new_password: "",
		new_password_confirmation: "",
	});

	const [passwordDataValid, setPasswordDataValid] = useState({
		old_password: false,
		new_password: false,
		new_password_confirmation: false,
	});

	const [passwordDataFocus, setPasswordDataFocus] = useState({
		old_password: false,
		new_password: false,
		new_password_confirmation: false,
	});

	const handleInput = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		//console.log("input", name, value);
		setPasswordData({ ...passwordData, [name]: value });
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (
			!passwordDataValid.old_password &&
			!passwordDataValid.new_password &&
			!passwordDataValid.new_password_confirmation
		) {
			toast.error(`there was an error. please fill all fields in right way.`, {
				position: "top-left",
			});
		} else {
			axios_auth(Cookies.get("token"))
				.post("/user/profile/change-password", passwordData)
				.then((res) => {
					if (res.data.status === true) {
						//console.log("flight - booking", res.data.data);
						if (res.data.data) {
							toast.success(res.data.message, {
								position: "top-left",
							});
						} else {
							toast.error(res.data.message, {
								position: "top-left",
							});
						}
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				})
				.catch((error) => {
					toast.error(error.response?.data.message, {
						position: "top-left",
					});
				});
		}
	};

	// validations
	useEffect(() => {
		setPasswordDataValid({
			...passwordDataValid,
			old_password: PASSWORD_REGEX.test(passwordData.old_password),
			new_password: PASSWORD_REGEX.test(passwordData.new_password),
			new_password_confirmation:
				passwordData.new_password === passwordData.new_password_confirmation,
		});
	}, [passwordData, passwordData.new_password_confirmation]);

	return (
		<form className="flex flex-col space-y-4">
			{/* current Password */}
			<div className="grid grid-cols-1 gap-4">
				{/* current Password */}
				<div className="flex flex-col space-y-1 relative">
					<label
						htmlFor="old_password"
						className="text-lg font-semibold text-clr-simple-bg"
					>
						Current Password
					</label>
					<input
						type={showCurrentPassword ? "text" : "password"}
						id="old_password	"
						name="old_password"
						value={passwordData.old_password}
						onChange={handleInput}
						autoComplete="off"
						aria-invalid={passwordDataValid.old_password ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() =>
							setPasswordDataFocus({ ...passwordDataFocus, old_password: true })
						}
						onBlur={() =>
							setPasswordDataFocus({
								...passwordDataFocus,
								old_password: false,
							})
						}
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
							passwordDataFocus.old_password && !passwordDataValid.old_password
								? "border-red-600"
								: passwordDataFocus.old_password &&
								  passwordDataValid.old_password
								? "border-green-600"
								: "border-clr-stroke"
						)}
						placeholder="Current Password"
					/>
					<button
						className="absolute top-10 right-5 text-clr-gray hover:underline text-sm"
						type="button"
						onClick={() => setShowCurrentPassword(!showCurrentPassword)}
					>
						{showCurrentPassword ? "hide" : "show"}
					</button>
					<div
						id="uidnote"
						className={
							passwordDataFocus.old_password &&
							passwordData.old_password &&
							!passwordDataValid.old_password
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						Your Password weak must have @-#-$-% /Capital letter/numbers
					</div>
				</div>
			</div>

			{/* new Password */}
			<div className="grid grid-cols-1 gap-4">
				{/* new Password */}
				<div className="flex flex-col space-y-1 relative">
					<label
						htmlFor="new_password"
						className="text-lg font-semibold text-clr-simple-bg"
					>
						New Password
					</label>
					<input
						type={showPassword ? "text" : "password"}
						id="new_password	"
						name="new_password"
						value={passwordData.new_password}
						onChange={handleInput}
						autoComplete="off"
						aria-invalid={passwordDataValid.new_password ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() =>
							setPasswordDataFocus({ ...passwordDataFocus, new_password: true })
						}
						onBlur={() =>
							setPasswordDataFocus({
								...passwordDataFocus,
								new_password: false,
							})
						}
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
							passwordDataFocus.new_password && !passwordDataValid.new_password
								? "border-red-600"
								: passwordDataFocus.new_password &&
								  passwordDataValid.new_password
								? "border-green-600"
								: "border-clr-stroke"
						)}
						placeholder="New Password"
					/>
					<button
						className="absolute top-10 right-5 text-clr-gray hover:underline text-sm"
						type="button"
						onClick={() => setShowPassword(!showPassword)}
					>
						{showPassword ? "hide" : "show"}
					</button>
					<div
						id="uidnote"
						className={
							passwordDataFocus.new_password &&
							passwordData.new_password &&
							!passwordDataValid.new_password
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						Your Password weak must have @-#-$-% /Capital letter/numbers
					</div>
				</div>
			</div>

			{/* retype new Password */}
			<div className="grid grid-cols-1 gap-4">
				{/* retype new Password */}
				<div className="flex flex-col space-y-1 relative">
					<label
						htmlFor="new_password_confirmation"
						className="text-lg font-semibold text-clr-simple-bg"
					>
						Retype New Password
					</label>
					<input
						type={showConfirmPassword ? "text" : "password"}
						id="new_password_confirmation	"
						name="new_password_confirmation"
						value={passwordData.new_password_confirmation}
						onChange={handleInput}
						autoComplete="off"
						aria-invalid={
							passwordDataValid.new_password_confirmation ? "false" : "true"
						}
						aria-describedby="uidnote"
						onFocus={() =>
							setPasswordDataFocus({
								...passwordDataFocus,
								new_password_confirmation: true,
							})
						}
						onBlur={() =>
							setPasswordDataFocus({
								...passwordDataFocus,
								new_password_confirmation: false,
							})
						}
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
							passwordDataFocus.new_password_confirmation &&
								!passwordDataValid.new_password_confirmation
								? "border-red-600"
								: passwordDataFocus.new_password_confirmation &&
								  passwordDataValid.new_password_confirmation
								? "border-green-600"
								: "border-clr-stroke"
						)}
						placeholder="Retype New Password"
					/>
					<button
						className="absolute top-10 right-5 text-clr-gray hover:underline text-sm"
						type="button"
						onClick={() => setShowConfirmPassword(!showConfirmPassword)}
					>
						{showConfirmPassword ? "hide" : "show"}
					</button>
					<div
						id="uidnote"
						className={
							passwordDataFocus.new_password_confirmation &&
							passwordData.new_password_confirmation &&
							!passwordDataValid.new_password_confirmation
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						Your Password weak must have @-#-$-% /Capital letter/numbers
					</div>
				</div>
			</div>

			<button
				onClick={handleSubmit}
				className="flex flex-row items-center justify-center space-x-1 bg-clr-primary-green text-white py-2 px-4 rounded-lg shadow-2xl hover:shadow-btnShadow font-medium text-lg w-[200px]"
			>
				<span>Save Changes</span>
			</button>
		</form>
	);
}

export default PasswordForm;
