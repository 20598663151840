import React, { useContext, useEffect, useState, useReducer } from "react";

// libs
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { format, addDays } from "date-fns";
import CryptoJS from "crypto-js";

// custom
import reducer from "../reducers/flight_reducer";
import {
	GET_FLIGHTS_BEGIN,
	GET_FLIGHTS_SUCCESS,
	GET_FLIGHTS_ERROR,
	SET_FLIGHTS_SESSION,
	UPDATE_FILTERS,
	FILTER_FLIGHTS,
	CLEAR_FILTERS,
	FILTER_MODAL_OPEN,
	FILTER_MODAL_CLOSE,
	GET_SINGLE_FLIGHTS_BEGIN,
	GET_SINGLE_FLIGHTS_SUCCESS,
	GET_SINGLE_FLIGHTS_ERROR,
	SET_PASSENGERS_DATA,
	SET_BOOKING_BODY,
	FLIGHT_BOOKING_BEGIN,
	FLIGHT_BOOKING_SUCCESS,
	FLIGHT_BOOKING_ERROR,
} from "../utils/actions";
import { axios, axios_auth, axios_search } from "../utils/axios";
import {
	addOrUpdateItem,
	getLocalStorage,
	reshapSegmentsArr,
} from "../utils/helpers";
import { useMainContext } from "./main_context";

type Passenger = {
	id: string;
	title: string;
	is_lead_pax: string; //"0"
	pax_type: string; //"1"
	first_name: string;
	last_name: string;
	email: string;
	passport_number: string;
	nationality: string;
	gender: string;
	phone: string;
	country_code: string;
	country_name: string;
	city: string;
	address_one: string;
	birthday: string; // "1997-01-01"
	passport_expire_at: string; //"2024-01-01";
};

const initialContext = {
	/* ---- content of search form ---- */
	flight_search: {},
	/* ---- fetch flights progress ---- */
	flights_loading: false,
	flights_error: false,
	flights: [],
	filterCategories: {
		stops: [],
		price: [],
		air_lines: [],
		duration: [],
		time: {
			arrivalTime: {
				startTime: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
				endTime: format(
					new Date().setHours(23, 59, 59, 999),
					"yyyy-MM-dd HH:mm:ss"
				),
			},
			departTime: {
				startTime: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
				endTime: format(
					new Date().setHours(23, 59, 59, 999),
					"yyyy-MM-dd HH:mm:ss"
				),
			},
		},
	},
	pagination_details: {},
	//setFlightSession: () => {},
	fetchFlights: (reqData: any) => {},
	/* ---- flights filter ---- */
	filters: {
		stops: -1,
		min_price: 0,
		max_price: 1000000,
		min_duration: 0,
		max_duration: 1000000,
		airports: "",
		time: "",
		sort: "price",
	},
	updateFilters: (currentFilter: any) => {},
	clearFilters: () => {},
	fetchFilteredFlight: () => {},
	/* ---- fetch single flight progress ---- */
	single_flight_loading: false,
	single_flight_error: false,
	single_flight: {} as any,
	fetchsingleFlight: (flightToken: string) => {},
	/* --------- passengers ------------- */
	passengers: [] as Passenger[],
	setPassengers: (passengerData: any) => {},
	/* --------- booking ------------- */
	bookingBody: {
		passengers: [] as Passenger[],
		baggage_id: [],
		meal_id: [],
		seat_id: [],
		credit_notes: [],
		cashback: [],
		price: 0,
		payment_method: 1,
		AppReference: "FB11-154127-883271",
		SequenceNumber: 1,
		private_key: "",
		result_token: "",
	},
	setBookingBody: (bookingParams: any) => {},
	flight_booking_loading: false,
	flight_booking_error: false,
	flight_booking_success: false,
	callBooking: () => {},
	/* ------------ filter modal ---------- */
	isFilterModalOpen: false,
	openFilterModal: () => {},
	closeFilterModal: () => {},
};

const FlightContext = React.createContext(initialContext);

export const FlightProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(reducer, initialContext);
	const { creditNote, cashback, user_token } = useMainContext();

	// get available flight
	const fetchFlights = async (reqBody: any) => {
		dispatch({ type: GET_FLIGHTS_BEGIN });
		try {
			const tempReqBody = {
				...reqBody,
				Segments: reshapSegmentsArr(reqBody.Segments),
			};
			axios_search(Cookies.get("user_temp_token"))
				.post("/v1/flight/search", tempReqBody)
				.then((res) => {
					if (res.data.status === true) {
						//console.log("flight", res.data.data);
						if (res.data.data) {
							dispatch({
								type: GET_FLIGHTS_SUCCESS,
								payload: {
									flights: res.data.data.flights,
									available_filters: res.data.data.available_filters,
								},
							});
						} else {
							dispatch({ type: GET_FLIGHTS_ERROR });
							toast.error(res.data.message, {
								position: "top-left",
							});
						}
					} else {
						dispatch({ type: GET_FLIGHTS_ERROR });
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				})
				.catch((error) => {
					dispatch({ type: GET_FLIGHTS_ERROR });
					toast.error(error.response?.data.message, {
						position: "top-left",
					});
				});
		} catch (error: any) {
			toast.error(error.response?.data.message, {
				position: "top-left",
			});
		}
	};

	// get single flight
	const fetchsingleFlight = (flightToken: string) => {
		dispatch({ type: GET_SINGLE_FLIGHTS_BEGIN });
		try {
			const tempFlight = state.flights.find(
				(item: any) => item.ResultToken == flightToken
			);
			setBookingBody({
				...state.bookingBody,
				result_token: flightToken,
				//price: tempFlight.Price.TotalDisplayFare,
				price: 1,
			});
			//console.log("flight", tempFlight);
			dispatch({
				type: GET_SINGLE_FLIGHTS_SUCCESS,
				payload: {
					currentFlight: tempFlight,
				},
			});
		} catch (error) {
			dispatch({ type: GET_SINGLE_FLIGHTS_ERROR });
			toast.error("can't find the required Flight", {
				position: "top-left",
			});
		}
	};

	// collect passengers data
	const setPassengers = (passengerData: any) => {
		//console.log("passengerData", passengerData);
		const tempArr = addOrUpdateItem(state.passengers, passengerData);
		setBookingBody({ ...state.bookingBody, passengers: tempArr });
		dispatch({
			type: SET_PASSENGERS_DATA,
			payload: {
				currentPassengers: tempArr,
			},
		});
	};

	// collect booking data
	const setBookingBody = (bookingBody: any) => {
		dispatch({
			type: SET_BOOKING_BODY,
			payload: {
				bookingBodyReq: bookingBody,
			},
		});
	};

	// book flight
	const callBooking = () => {
		dispatch({ type: FLIGHT_BOOKING_BEGIN });

		try {
			// check credit note before booking
			if (
				[2, 5, 6, 7].includes(state.bookingBody.payment_method) &&
				creditNote.length <= 0
			) {
				dispatch({ type: FLIGHT_BOOKING_ERROR });
				toast.error(
					"you're using a credit note, please check the credit note table",
					{
						position: "top-left",
					}
				);
				return;
			}

			// check cashback before booking
			else if (
				[3, 4, 6, 7].includes(state.bookingBody.payment_method) &&
				cashback.length <= 0
			) {
				dispatch({ type: FLIGHT_BOOKING_ERROR });
				toast.error(
					"you're using a cashback, please check the cashback table",
					{
						position: "top-left",
					}
				);
				return;
			} else {
				const tempReqBooking = {
					...state.bookingBody,
					private_key:
						Cookies.get("key") != undefined
							? CryptoJS.AES.decrypt(
									Cookies.get("key"),
									process.env.REACT_APP_KEY_HASH
							  ).toString(CryptoJS.enc.Utf8)
							: "",
					credit_notes: creditNote,
					cashback: cashback,
				};
				//console.log("tempReqBooking", tempReqBooking);
				//call
				axios_auth(Cookies.get("token"))
					.post("/v1/flight/v2/booking", tempReqBooking)
					.then((res) => {
						if (res.data.status === true) {
							//console.log("flight - booking", res.data.data);
							if (res.data.data) {
								dispatch({
									type: FLIGHT_BOOKING_SUCCESS,
									// payload: {
									// 	flights: res.data.data.flights,
									// 	available_filters: res.data.data.available_filters,
									// },
								});
								toast.success(res.data.message, {
									position: "top-left",
								});
							} else {
								dispatch({ type: FLIGHT_BOOKING_ERROR });
								toast.error(res.data.message, {
									position: "top-left",
								});
							}
						} else {
							dispatch({ type: FLIGHT_BOOKING_ERROR });
							toast.error(res.data.message, {
								position: "top-left",
							});
						}
					})
					.catch((error) => {
						dispatch({ type: FLIGHT_BOOKING_ERROR });
						toast.error(error.response?.data.message, {
							position: "top-left",
						});
					});
			}
		} catch (error: any) {
			dispatch({ type: FLIGHT_BOOKING_ERROR });
			toast.error(error.response?.data.message, {
				position: "top-left",
			});
		}
	};

	// update filter
	const updateFilters = (newFilter: any) => {
		dispatch({
			type: UPDATE_FILTERS,
			payload: {
				newFilter: newFilter,
			},
		});
	};

	// fetch flights after filter
	const fetchFilteredFlight = () => {
		try {
			axios_search(Cookies.get("user_temp_token"))
				.post("/v1/flight/search/filter", state.filters)
				.then((res) => {
					if (res.data.status === true) {
						//console.log("flight -filter", res.data.data);
						if (res.data.data) {
							dispatch({
								type: FILTER_FLIGHTS,
								payload: {
									filterdFlights: res.data.data.flights,
								},
							});
						} else {
							dispatch({ type: GET_FLIGHTS_ERROR });
							toast.error(res.data.message, {
								position: "top-left",
							});
						}
					} else {
						dispatch({ type: GET_FLIGHTS_ERROR });
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				})
				.catch((error) => {
					dispatch({ type: GET_FLIGHTS_ERROR });
					toast.error(error.response?.data.message, {
						position: "top-left",
					});
				});
		} catch (error: any) {
			toast.error(error.response?.data.message, {
				position: "top-left",
			});
		}
	};

	// clear filter
	const clearFilters = () => {
		dispatch({
			type: CLEAR_FILTERS,
		});
	};

	const openFilterModal = () => {
		dispatch({ type: FILTER_MODAL_OPEN });
	};
	const closeFilterModal = () => {
		dispatch({ type: FILTER_MODAL_CLOSE });
	};

	// get filtered flights
	useEffect(() => {
		if (state.flights.length > 0) {
			fetchFilteredFlight();
			closeFilterModal();
		}
	}, [state.filters]);

	useEffect(() => {
		const currentPath = window.location.pathname;
		if (currentPath == "/flights")
			if (Object.keys(getLocalStorage("flight_form").length > 0)) {
				fetchFlights(getLocalStorage("flight_form"));
			}
	}, []);

	return (
		<FlightContext.Provider
			value={{
				...state,
				fetchFlights,
				fetchsingleFlight,
				setPassengers,
				setBookingBody,
				callBooking,
				updateFilters,
				clearFilters,
				fetchFilteredFlight,
				openFilterModal,
				closeFilterModal,
			}}
		>
			{children}
		</FlightContext.Provider>
	);
};
// make sure use
export const useFlightContext = () => {
	return useContext(FlightContext);
};
