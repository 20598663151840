import React from "react";

// lib
import { Modal } from "antd";

// custom
import { useFlightContext } from "../../../contexts/flight_context";
import { SkeletonFilter } from "../../Shared";
import FlightFilter from "./FlightFilter";

function FlightFilterModal() {
	const {
		isFilterModalOpen,
		openFilterModal,
		closeFilterModal,
		filterCategories,
	} = useFlightContext();

	return (
		<Modal
			title={
				<div className="flex flex-row space-x-1 items-center ">
					<h1 className="text-3xl font-bold text-clr-primary-green bg-transparent">
						Flight Filter
					</h1>
				</div>
			}
			style={{ top: 20 }}
			width={400}
			footer={<div></div>}
			open={isFilterModalOpen}
			onOk={closeFilterModal}
			onCancel={closeFilterModal}
		>
			{/* private */}
			<div className="flex flex-col overflow-x-auto min-w-full w-full rounded-lg mb-10 max-h-[800px] overflow-y-auto">
				{filterCategories.stops.length > 0 && <FlightFilter />}

				{filterCategories.stops.length <= 0 && <SkeletonFilter />}
			</div>
		</Modal>
	);
}

export default FlightFilterModal;
