import React from "react";
import FlightForm from "../../Home/SearchForms/Flight/FlightForm";

function FlightHero() {
	return (
		<div className="bg-flight_bg_hero bg-no-repeat bg-center bg-cover py-28">
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col space-y-10">
				<div className="bg-white rounded-lg py-2 px-1">
					<FlightForm />
				</div>
			</div>
		</div>
	);
}

export default FlightHero;
