import React, { useEffect, useState } from "react";

// lib
import { Modal } from "antd";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

// custom
import { useMainContext } from "../../contexts/main_context";
import { axios_auth } from "../../utils/axios";
import { classNames, getColorPerStatus } from "../../utils/helpers";

function CashbackModal() {
	const [currentCashback, setCurrentCashback] = useState([]);
	const {
		isCashbackModalOpen,
		openCashbackModal,
		closeCashbackModal,
		setCashback,
	} = useMainContext();

	const getCreditNotes = async () => {
		axios_auth(Cookies.get("token"))
			.get("/v1/flight/cashback")
			.then((res) => {
				if (res.data.status === true) {
					//console.log("flight - booking", res.data.data);
					if (res.data.data) {
						setCurrentCashback(res.data.data.data);
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				} else {
					toast.error(res.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.response?.data.message, {
					position: "top-left",
				});
			});
	};

	const handleCashback = (e: any) => {
		const name = e.target.name;
		const value = e.target.value;
		//console.log("data", name, value);
		setCashback({ id: name });
	};

	useEffect(() => {
		getCreditNotes();
	}, []);

	return (
		<Modal
			title={
				<div className="flex flex-row space-x-1 items-center ">
					<h1 className="text-3xl font-bold text-clr-primary-green bg-transparent">
						Cashback
					</h1>
				</div>
			}
			style={{ top: 20 }}
			width={800}
			footer={<div></div>}
			open={isCashbackModalOpen}
			onOk={closeCashbackModal}
			onCancel={closeCashbackModal}
		>
			{/* private */}
			<div className="flex flex-col overflow-x-auto min-w-full w-full rounded-lg mb-10 max-h-80 overflow-y-auto">
				{currentCashback.length > 0 && (
					<table className="border-collapse  w-full rounded-t-xl overflow-x-auto min-w-[680px] py-2">
						<thead className="bg-clr-stroke text-clr-simple-bg font-semibold items-center text-center rounded-t-xl">
							<tr className="rounded-t-xl">
								<th className="py-2 px-4 "></th>
								<th className="">id</th>
								<th className="">Amount</th>
								<th className="">Status</th>
								<th className="">Expiration Date</th>
							</tr>
						</thead>
						<tbody>
							{currentCashback.map((item: any) => {
								return (
									<tr key={item.id}>
										<td className="py-3 font-medium border border-clr-stroke-gray flex flex-col items-center justify-center">
											<input
												className="cursor-pointer"
												onChange={handleCashback}
												type="checkbox"
												name={item.id}
												value={`${item.remaining_dollar}`}
												id={item.id}
												disabled={
													item.status === "used" || item.status === "invalid"
												}
											/>
										</td>
										<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
											{item.id}
										</td>
										<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
											{item.remaining_amount}
										</td>
										<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
											<span
												className={classNames(
													"px-3 py-2 text-white rounded-lg shadow-card text-xs",
													getColorPerStatus(item.status)
												)}
											>
												{item.status}
											</span>
										</td>
										<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
											{item.expire_at}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
		</Modal>
	);
}

export default CashbackModal;
