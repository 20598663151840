import React, { useEffect, useState } from "react";

// lib
import { Modal } from "antd";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

//custom
import { axios_auth } from "../../utils/axios";
import { classNames } from "../../utils/helpers";

// regex
const key_REGEX = /^[a-zA-Z0-9]{64,66}$/;

function WalletWithdrawalModal({ isActive, setDisActive }: any) {
	const [loading, setLoading] = useState(false);
	const [data, setdata] = useState({
		to: "",
		amount: "",
		pKey: "",
	});
	const [dataValid, setDataValid] = useState({
		to: false,
		amount: false,
		pKey: false,
	});
	const [dataFocus, setDatafocus] = useState({
		to: false,
		amount: false,
		pKey: false,
	});

	//set private key
	const handleInput = (e: any) => {
		const val = e.currentTarget.value;
		const name = e.currentTarget.name;
		setdata({ ...data, [name]: val });
	};

	const handleTransfer = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		if (!dataValid.to && !dataValid.amount && !dataValid.pKey) {
			toast.error("there was an error. please fill all fields in right way.", {
				position: "top-left",
			});
			setLoading(false);
			return;
		} else {
			//console.log("data", data);
			const tempData = {
				address: data.to,
				private_key: data.pKey,
				amount: data.amount,
			};
			axios_auth(Cookies.get("token"))
				.post("/v1/wallet/withdraw", tempData)
				.then((res) => {
					if (res.data.status === true) {
						//console.log("flight - booking", res.data.data);
						if (res.data.data) {
							toast.success(res.data.message, {
								position: "top-left",
							});
						} else {
							toast.error(res.data.message, {
								position: "top-left",
							});
						}
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
					setLoading(false);
					setDisActive();
				})
				.catch((error) => {
					toast.error(error.response?.data.message, {
						position: "top-left",
					});
					setLoading(false);
				});
		}
	};

	//check the validation for private key
	useEffect(() => {
		setDataValid({
			...dataValid,
			to: data.to.length > 0 && data.to.length <= 40 ? true : false,
			amount: data.amount.length > 0 ? true : false,
			pKey: key_REGEX.test(data.pKey),
		});
	}, [data]);

	return (
		<Modal
			title={
				<div className="flex flex-row space-x-1 items-center ">
					<h1 className="text-3xl font-bold text-clr-primary-green bg-transparent">
						Withdrawal
					</h1>
				</div>
			}
			style={{ top: 20 }}
			width={800}
			footer={<div></div>}
			open={isActive}
			onOk={setDisActive}
			onCancel={setDisActive}
		>
			<div className="flex flex-col space-y-4 overflow-x-auto min-w-full w-full rounded-lg mb-10 max-h-80 overflow-y-auto mt-5">
				<div className="flex s_700:flex-row flex-col">
					{/* to */}
					<div className="flex flex-col items-start justify-start s_700:w-[65%] w-full space-y-1 mr-3 mb-5">
						<h2 className="text-lg font-semibold text-clr-simple-bg ">To:</h2>
						<input
							id="to"
							name="to"
							type={"text"}
							autoComplete="off"
							aria-invalid={dataValid.to ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setDatafocus({ ...dataFocus, to: true })}
							onBlur={() => setDatafocus({ ...dataFocus, to: false })}
							value={data.to}
							onChange={(e) => handleInput(e)}
							className={classNames(
								"px-4 py-2 rounded-xl bg-transparent w-full font-bold text-clr-primary-gray text-base outline-none",
								dataValid.to
									? "border-2 border-clr-primary-green"
									: "border border-clr-stroke",
								!dataValid.to && dataFocus.to
									? "border-2 border-red-600"
									: "border border-clr-stroke"
							)}
						/>
					</div>
					{/* amount */}
					<div className="flex flex-col items-start justify-start s_700:w-[30%] w-full space-y-1  mb-5">
						<h2 className="text-lg font-semibold text-clr-simple-bg ">
							Amount:
						</h2>
						<input
							id="amount"
							name="amount"
							type={"text"}
							autoComplete="off"
							aria-invalid={dataValid.amount ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setDatafocus({ ...dataFocus, amount: true })}
							onBlur={() => setDatafocus({ ...dataFocus, amount: false })}
							value={data.amount}
							onChange={(e) => handleInput(e)}
							className={classNames(
								"px-4 py-2 rounded-xl bg-transparent w-full font-bold text-clr-primary-gray text-base outline-none",
								dataValid.amount
									? "border-2 border-clr-primary-green"
									: "border border-clr-stroke",
								!dataValid.amount && dataFocus.amount
									? "border-2 border-red-600"
									: "border border-clr-stroke"
							)}
						/>
					</div>
				</div>
				{/* private */}
				<div className="flex flex-col items-start justify-start w-full space-y-1">
					<h2 className="text-lg font-semibold text-clr-simple-bg ">
						Private key:
					</h2>
					<input
						id="pKey"
						name="pKey"
						type={"password"}
						autoComplete="off"
						aria-invalid={dataValid.pKey ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() => setDatafocus({ ...dataFocus, pKey: true })}
						onBlur={() => setDatafocus({ ...dataFocus, pKey: false })}
						value={data.pKey}
						onChange={(e) => handleInput(e)}
						className={classNames(
							"px-4 py-2 rounded-xl bg-transparent w-full font-bold text-clr-primary-gray text-base outline-none",
							dataValid.pKey
								? "border-2 border-clr-primary-green"
								: "border border-clr-stroke",
							!dataValid.pKey && dataFocus.pKey
								? "border-2 border-red-600"
								: "border border-clr-stroke"
						)}
					/>
				</div>

				<button
					onClick={handleTransfer}
					className="flex flex-row items-center justify-center space-x-2 bg-clr-primary-green text-white text-lg font-semibold py-2 px-2  border border-clr-primary-green rounded-lg w-[150px] mx-auto"
				>
					<span>Transfer</span>
					{loading && (
						<svg
							aria-hidden="true"
							role="status"
							className="inline w-4 h-4 mr-3 text-clr-main-dark font-semibold animate-spin"
							viewBox="0 0 100 101"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="#E5E7EB"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentColor"
							/>
						</svg>
					)}
				</button>
			</div>
		</Modal>
	);
}

export default WalletWithdrawalModal;
