import React, { useState, useEffect } from "react";

// lib
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { GiHotMeal } from "react-icons/gi";
import { MdOutlineAirlineSeatReclineNormal } from "react-icons/md";

// custom
import { classNames, getLocalStorage } from "../../../utils/helpers";
import { useFlightContext } from "../../../contexts/flight_context";

function FightServicesCard({ type, level }: any) {
	const [showDetails, setShowDetails] = useState(false);
	const { single_flight } = useFlightContext();

	// get current search data
	const currentSearchData = getLocalStorage("flight_form");

	// keep first card open
	useEffect(() => {
		if (type == "Adult" && level == 0) {
			setShowDetails(true);
		}
	}, [type]);

	return (
		<div className="py-2 px-8 bg-white shadow-2xl shadow-clr-primary-gray rounded-md relative flex flex-col">
			<h1 className="text-clr-simple-bg text-lg font-semibold py-8">
				{type == "Child"
					? `Passenger ${level + 1 + currentSearchData.AdultCount}: (${type})`
					: type == "Infant"
					? `Passenger ${
							level +
							1 +
							currentSearchData.AdultCount +
							+currentSearchData.ChildCount
					  }: (${type})`
					: `Passenger ${level + 1}: (${type})`}
			</h1>
			<div
				className={classNames(
					" flex-col space-y-4 border-t pt-10 pb-16",
					showDetails ? "flex" : "hidden"
				)}
			>
				{/* row */}
				<div className="flex flex-row items-center justify-between w-full">
					<span className="text-sm text-clr-simple-bg font-normal">
						Carry-on Baggage
					</span>
					<div className="flex flex-row items-center justify-between border border-clr-stroke rounded-md py-2 px-2 w-[70%]">
						<div className="flex flex-row space-x-1">
							<input
								type="radio"
								className=" "
								checked={
									single_flight?.FlightDetails?.Details[0][0]?.Attr
										?.CabinBaggage &&
									single_flight?.FlightDetails?.Details[0][0]?.Attr
										?.CabinBaggage != ""
								}
							/>
							<p className="text-xs font-normal flex flex-row">
								<span className="text-clr-simple-bg">1 x Cabin bag </span>

								<span className="text-clr-light-gray">
									{/* (55 x 20 x 40cm, 8kg) */}
									{`(${single_flight?.FlightDetails?.Details[0][0]?.Attr?.CabinBaggage})`}
								</span>
							</p>
						</div>
						<span className="text-clr-simple-bg font-semibold text-sm">$0</span>
					</div>
				</div>

				{/* row */}
				<div className="flex flex-row items-center justify-between w-full">
					<span className="text-sm text-clr-simple-bg font-normal">
						Baggage
					</span>
					<div className="flex flex-row items-center justify-between border border-clr-stroke rounded-md py-2 px-2 w-[70%]">
						<div className="flex flex-row space-x-1">
							<input
								type="radio"
								checked={
									single_flight?.FlightDetails?.Details[0][0]?.Attr?.Baggage &&
									single_flight?.FlightDetails?.Details[0][0]?.Attr?.Baggage !=
										""
								}
							/>
							<p className="text-xs font-normal flex flex-row">
								<span className="text-clr-simple-bg">1 x bag </span>
								<span className="text-clr-light-gray">
									{/* (55 x 20 x 40cm, 8kg) */}
									{`(${single_flight?.FlightDetails?.Details[0][0]?.Attr?.Baggage})`}
								</span>
							</p>
						</div>
						<span className="text-clr-simple-bg font-semibold text-sm">$0</span>
					</div>
				</div>

				{/* row */}
				<div className="flex flex-row items-center space-x-3 bg-clr-stroke rounded w-[180px] py-3 px-4">
					<span>IsRefundable:</span>
					<span>{single_flight?.Attr?.IsRefundable ? "yes" : "No"}</span>
				</div>

				{/* row */}
				{/* <div className="flex flex-row items-center justify-end w-full">
					<div className="flex flex-row items-center justify-between border border-clr-stroke rounded-md py-3 px-2 w-[70%]">
						<div className="flex flex-row space-x-1">
							<p className="flex flex-row items-center justify-center space-x-1">
								<GiHotMeal className="w-4 h-4 text-clr-primary-green" />
								<span className="text-clr-simple-bg text-sm font-semibold">
									Select Meal
								</span>
							</p>
						</div>
					</div>
				</div> */}

				{/* row */}
				{/* <div className="flex flex-row items-center justify-end w-full">
					<div className="flex flex-row items-center justify-between border border-clr-stroke rounded-md py-3 px-2 w-[70%]">
						<div className="flex flex-row space-x-1">
							<p className="flex flex-row items-center justify-center space-x-1">
								<MdOutlineAirlineSeatReclineNormal className="w-4 h-4 text-clr-primary-green" />
								<span className="text-clr-simple-bg text-sm font-semibold">
									Select Seat (from AED 10)
								</span>
							</p>
						</div>
					</div>
				</div> */}
			</div>
			<span
				className="bg-white py-1 px-4 rounded-full shadow-xl shadow-clr-primary-gray cursor-pointer w-5 flex flex-col items-center justify-center absolute -bottom-3 left-1/2"
				onClick={() => setShowDetails(!showDetails)}
			>
				{showDetails ? (
					<IoIosArrowUp className="w-6 h-6" />
				) : (
					<IoIosArrowDown className="w-6 h-6" />
				)}
			</span>
		</div>
	);
}

export default FightServicesCard;
