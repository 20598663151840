import React, { useEffect, useState } from "react";

// custom
import { useMainContext } from "../../contexts/main_context";
import { Subscribe } from "../../components/Home";
import {
	BookingHistory,
	DeleteAccount,
	ProfileAside,
	Settings,
	Wallet,
} from "../../components/Profile";

function DeleteAccountPage() {
	const { changeTheme } = useMainContext();
	const [currnetTab, setCurrentTab] = useState(4);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "white",
			footerShow: true,
		});
	}, []);

	return (
		<main>
			<div className="page max-w-custom-full w-smallWidth mx-auto">
				<div className="section-center flex flex-col my-16 mx-auto">
					<DeleteAccount />
				</div>
			</div>
			<Subscribe />
		</main>
	);
}

export default DeleteAccountPage;
