import React from "react";

import bg_img from "../../assets/imgs/bg_about.png";

function Hero() {
	return (
		<div className="py-32 relative">
			<img
				src={bg_img}
				className="absolute top-0 left-0 w-full h-full -z-10"
				alt=""
			/>
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col space-y-10 items-center justify-center ">
				<h1 className="text-center text-5xl text-white font-bold">About Us</h1>
			</div>
		</div>
	);
}

export default Hero;
