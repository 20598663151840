import React from "react";

function SkeletonCard() {
	return (
		<div className="flex flex-col space-y-5 p-8 bg-white shadow-cardShadow rounded-lg animate-pulse w-full pt-10">
			{/* hint */}
			<div className="flex flex-col space-y-3 border-b pb-5">
				{/* airline & price */}
				<div className="flex flex-row items-center justify-between py-2 bg-gray-200 rounded-lg">
					<h3 className="text-lg text-clr-simple-bg font-semibold bg-gray-200 "></h3>
					<p className="text-2xl font-bold text-clr-simple-bg bg-gray-200 "></p>
				</div>
				{/* from & to */}
				<div className="flex flex-row items-center justify-between">
					<div className="flex flex-col space-y-2 w-[75%]">
						{/* cities */}
						<div className="flex flex-row items-center justify-between py-2 bg-gray-200 rounded-lg">
							<span className="text-clr-simple-bg text-lg font-bold"></span>
							<p className="relative flex flex-row justify-between w-[70%]"></p>
							<span className="text-clr-simple-bg text-lg font-bold"></span>
						</div>
						{/* date */}
						<div className="flex flex-row items-start justify-between py-2 bg-gray-200 rounded-lg">
							<span className="text-clr-primary-gray text-lg font-medium"></span>
							<p className="relative flex flex-col items-center justify-start"></p>
							<span className="text-clr-primary-gray text-lg font-medium"></span>
						</div>
						{/* time */}
						<div className="flex flex-row items-start justify-between py-2 bg-gray-200 rounded-lg">
							<span className="text-clr-primary-gray text-lg font-medium"></span>
							<span className="text-clr-primary-gray text-lg font-medium"></span>
						</div>
					</div>
					<button className="bg-gray-200 text-white text-base font-medium py-2 px-16 rounded-lg shadow-btnShadow"></button>
				</div>
			</div>
			{/* footer */}
			<div className="flex flex-row items-center justify-between ">
				{/* class & baggage */}
				<div className="flex flex-row items-center justify-center space-x-2 py-2 w-full">
					<p className="text-sm text-clr-simple-bg font-semibold bg-gray-200  rounded-md py-2 px-4 w-[40%]"></p>
					<p className="text-sm text-clr-simple-bg font-semibold bg-gray-200  rounded-md py-2 px-4 w-[40%] flex flex-row space-x-1 items-center justify-center"></p>
				</div>
				{/* btn */}
				<button className="flex flex-row items-center justify-center space-x-1 w-[50%] rounded-lg bg-gray-200 py-2"></button>
			</div>
		</div>
	);
}

export default SkeletonCard;
