import React, { useState, useEffect } from "react";

// lib
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Pagination } from "antd";

// custom
import { axios_auth } from "../../utils/axios";

function BookingHistory() {
	const [currentBookings, setCurrentBookings] = useState([]);
	const [usersTotal, setUsersTotal] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const handleChangePage = (page: any, pageSize: any) => {
		setCurrentPage(page);
		setPerPage(pageSize);
	};

	const getBookingHistory = async () => {
		axios_auth(Cookies.get("token"))
			.get("/v1/flight/web/bookings?page=1&perPage=" + perPage)
			.then((res) => {
				if (res.data.status === true) {
					//console.log("flight - booking", res.data.data[0]);
					if (res.data.data) {
						setCurrentBookings(res.data.data[0].bookings);
						setUsersTotal(res.data.data[0].total);
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				} else {
					toast.error(res.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.response?.data.message, {
					position: "top-left",
				});
			});
	};

	useEffect(() => {
		getBookingHistory();
	}, []);

	// for changing the page
	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get(
				"/v1/flight/web/bookings?page=" + currentPage + "&perPage=" + perPage
			)
			.then((res) => {
				if (res.data.status === true) {
					//console.log("flight - booking", res.data.data[0]);
					if (res.data.data) {
						setCurrentBookings(res.data.data[0].bookings);
						setUsersTotal(res.data.data[0].total);
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				} else {
					toast.error(res.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.response?.data.message, {
					position: "top-left",
				});
			});
	}, [currentPage, perPage]);

	return (
		<div className="flex flex-col items-center justify-between w-full shadow-listShadow rounded-lg">
			<div className="flex flex-col space-y-5 w-[90%] mx-auto py-10">
				{/* head */}
				<div className="flex flex-col">
					<h1 className="px-5 text-3xl font-bold text-clr-simple-bg">
						Booking History
					</h1>
				</div>
				{/* table */}
				<div className="flex flex-col overflow-x-auto min-w-full w-full rounded-lg mb-10 max-h-[500px] overflow-y-auto">
					{currentBookings.length > 0 && (
						<table className="border-collapse w-full mx-auto rounded-t-xl overflow-x-auto min-w-[680px] py-2">
							<thead className="bg-clr-stroke text-clr-simple-bg font-semibold items-center text-center rounded-t-xl ">
								<tr className="rounded-t-xl ">
									<th className="py-2">Type</th>
									<th className="">Trip</th>
									<th className="">PNR</th>
									<th className="">Date</th>
									<th className="">Amount</th>
									<th className="">Status</th>
								</tr>
							</thead>
							<tbody>
								{currentBookings.map((item: any) => {
									return (
										<tr key={item.id}>
											<td className="py-3 font-medium border border-clr-stroke-gray flex flex-col items-center justify-center">
												{item.booking_type}
											</td>
											<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
												{`${item.details?.CommitBooking?.BookingDetails?.JourneyList?.FlightDetails?.Details[0][0]?.Origin?.CityName}-${item.details?.CommitBooking?.BookingDetails?.JourneyList?.FlightDetails?.Details[0][0]?.Destination?.CityName}`}
											</td>
											<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
												{item.details?.CommitBooking?.BookingDetails?.PNR}
											</td>
											<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
												{item.date}
											</td>
											<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
												{
													item.details?.CommitBooking?.BookingDetails?.Price
														?.TotalDisplayFare
												}
											</td>
											<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
												{item.status}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</div>

				{currentBookings.length > 0 && (
					<div className="flex flex-col items-center">
						<Pagination
							className={"custom-pagination "}
							defaultCurrent={currentPage}
							total={usersTotal}
							pageSize={perPage}
							onChange={handleChangePage}
							pageSizeOptions={[10, 20, 50, 100]}
							hideOnSinglePage={false}
							showSizeChanger={true}
						/>
					</div>
				)}

				{currentBookings.length == 0 && (
					<div className="flex flex-col items-center justify-center py-14">
						<p className="text-center text-clr-simple-bg font-semibold text-3xl">
							There is no booking history
						</p>
					</div>
				)}
			</div>
		</div>
	);
}

export default BookingHistory;
