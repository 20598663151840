import React, { useEffect, useState } from "react";

// custom
import { useMainContext } from "../../contexts/main_context";
import { Subscribe } from "../../components/Home";
import {
	BookingHistory,
	DeleteAccount,
	ProfileAside,
	Settings,
	Wallet,
} from "../../components/Profile";

function ProfilePage() {
	const { changeTheme } = useMainContext();
	const [currnetTab, setCurrentTab] = useState(1);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "white",
			footerShow: true,
		});
	}, []);

	return (
		<main>
			<div className="page max-w-custom-full w-smallWidth mx-auto">
				<div className="section-center grid grid-cols-12 gap-y-12 gap-x-8 my-16 mx-auto">
					<div className="s_900:col-span-3 col-span-12 flex ">
						<ProfileAside isActive={currnetTab} setActive={setCurrentTab} />
					</div>

					<div className="flex flex-col space-y-5 s_900:col-span-9 col-span-12 s_900:mb-0 mb-10">
						{currnetTab == 1 && <BookingHistory />}

						{currnetTab == 2 && <Wallet />}

						{currnetTab == 3 && <Settings />}

						{currnetTab == 4 && <DeleteAccount />}
					</div>
				</div>
			</div>
			<Subscribe />
		</main>
	);
}

export default ProfilePage;
