import React, { useEffect, useState } from "react";

//lib
import { Slider } from "antd";
import { format, addDays } from "date-fns";

//custom
import { useFlightContext } from "../../../contexts/flight_context";
import {
	convertArrtoBase64String,
	getDuration,
	getTextStopByCount,
	getTimePerMinutes,
} from "../../../utils/helpers";

function FlightFilter() {
	const { filterCategories, filters, updateFilters, clearFilters } =
		useFlightContext();
	const [reverse, setReverse] = useState(false);

	// stops
	const handleStops = (e: any) => {
		updateFilters({ ...filters, stops: parseInt(e.target.value) });
	};

	// get price
	const [priceRange, setPriceRange] = useState<any>({
		min: 150,
		max: 100000,
	});
	const handlePriceInput = (e: any) => {
		if (Array.isArray(e)) {
			//console.log("price", e);
			setPriceRange({ min: e[0], max: e[1] });
		} else {
			const id = e.target.id;
			const value = e.target.value;
			//console.log(id, value);
			if (id === "min") {
				setPriceRange({
					...priceRange,
					min:
						value < 0 || value > filterCategories.price[1]
							? priceRange.min
							: value,
				});
			}
			if (id === "max") {
				setPriceRange({
					...priceRange,
					max:
						value > filterCategories.price[1]
							? filterCategories.price[1]
							: value,
				});
			}
		}
	};
	// update price in filters
	const updatePriceInFilters = () => {
		//console.log("test - update price", priceRange);
		updateFilters({
			...filters,
			min_price: priceRange.min,
			max_price: priceRange.max,
		});
	};

	// ------ time --------
	// departure
	const [departureRange, setdepartureRange] = useState<any>({
		start: getTimePerMinutes(format(new Date(), "yyyy-MM-dd HH:mm:ss")),
		end: getTimePerMinutes(
			format(new Date().setHours(23, 59, 59, 999), "yyyy-MM-dd HH:mm:ss")
		),
	});
	const handleDepartureInput = (e: any) => {
		setdepartureRange({ start: e[0], end: e[1] });
	};

	// arrival
	const [arrivalRange, setArrivalRange] = useState<any>({
		start: getTimePerMinutes(format(new Date(), "yyyy-MM-dd HH:mm:ss")),
		end: getTimePerMinutes(
			format(new Date().setHours(23, 59, 59, 999), "yyyy-MM-dd HH:mm:ss")
		),
	});
	const handleArrivalInput = (e: any) => {
		setArrivalRange({ start: e[0], end: e[1] });
	};
	// update time in filters
	const updateTimeInFilters = () => {
		const tempArr = {
			departTime: {
				startTime: departureRange.start,
				endTime: departureRange.end,
			},
			arrivalTime: {
				startTime: arrivalRange.start,
				endTime: arrivalRange.end,
			},
		};
		updateFilters({
			...filters,
			time: convertArrtoBase64String(tempArr),
		});
	};

	// get duration
	const [durationRange, setdurationRange] = useState<any>({
		min: 60,
		max: 600,
	});
	const handleDurationInput = (e: any) => {
		setdurationRange({ min: e[0], max: e[1] });
	};
	// update duration in filters
	const updateDurationInFilters = () => {
		//console.log("test - update price", priceRange);
		updateFilters({
			...filters,
			min_duration: durationRange.min,
			max_duration: durationRange.max,
		});
	};

	// airlines
	const [currentAirlines, setCurrentAirlines]: any = useState([]);
	const handleAirlines = (e: any) => {
		const value = e.target.value;
		if (currentAirlines.includes(value)) {
			const temp = currentAirlines.filter((item: any) => item !== value);
			setCurrentAirlines(temp);
		} else {
			setCurrentAirlines([...currentAirlines, value]);
		}
	};
	// update airlines in filter
	useEffect(() => {
		if (currentAirlines.length > 0) {
			const tempArr = {
				airports: currentAirlines,
			};
			updateFilters({
				...filters,
				airports: convertArrtoBase64String(tempArr),
			});
		}
	}, [currentAirlines]);

	useEffect(() => {
		// set price range
		setPriceRange({
			min: filterCategories.price[0],
			max: filterCategories.price[1],
		});
		//set departure
		setdepartureRange({
			start: getTimePerMinutes(filterCategories.time.departTime?.startTime),
			end: getTimePerMinutes(filterCategories.time.departTime?.endTime),
		});

		// set duration
		setdurationRange({
			min: filterCategories.duration[0],
			max: filterCategories.duration[1],
		});

		// set current airlines
		setCurrentAirlines(filterCategories.air_lines);
	}, [filterCategories]);

	return (
		<section className="s_900:sticky s_900:top-10">
			<div className=" bg-white shadow-listShadow rounded-lg py-2">
				<div className="flex flex-wrap items-center justify-between border-b-2 border-clr-primary-green py-2 px-4">
					<h2 className="text-lg font-semibold text-clr-primary-green">
						Filter departing flight
					</h2>
					<button
						className="text-sm text-clr-primary-green font-medium"
						onClick={clearFilters}
					>
						Clear All
					</button>
				</div>
				<form onSubmit={(e) => e.preventDefault()} className="px-8 py-4">
					{/* stops */}
					{filterCategories.stops.length > 0 && (
						<div className="mb-5 flex flex-col space-y-2 border-b pb-5">
							<h3 className="text-base text-clr-simple-bg font-semibold">
								Stops
							</h3>
							{getTextStopByCount(filterCategories.stops).map(
								(item: any, index: number) => {
									return (
										<div key={item} className={"flex flex-row space-x-2 ml-3"}>
											<input
												type="radio"
												// className="rounded w-4 bg-clr-stroke border-clr-stroke accent-clr-primary-green focus:ring-0 focus:ring-clr-stroke focus:bg-clr-primary-green hover:bg-clr-primary-green  checked:bg-clr-primary-green checked:border-clr-primary-green"
												name="stopOption"
												id={item}
												value={filterCategories.stops[index]}
												onChange={(e) => handleStops(e)}
												checked={filterCategories.stops[index] == filters.stops}
											/>
											<label
												htmlFor={item}
												className={
													"text-clr-primary-gray text-base font-medium cursor-pointer"
												}
											>
												{item}
											</label>
										</div>
									);
								}
							)}
						</div>
					)}
					{/* end stops */}

					{/* price */}
					{filterCategories.price.length > 0 && (
						<div className="mb-5 flex flex-col space-y-2 border-b pb-5">
							<h3 className="text-base text-clr-simple-bg font-semibold">
								Price
							</h3>

							<div className="ml-3 relative w-full">
								<Slider
									range
									onChange={handlePriceInput}
									min={filterCategories.price[0]}
									max={filterCategories.price[1]}
									defaultValue={[
										filterCategories.price[0],
										filterCategories.price[1],
									]}
									value={[priceRange.min, priceRange.max]}
									reverse={reverse}
								/>
								<div
									className={
										"flex s_1000:flex-row flex-col s_1000:space-x-2 justify-between items-center "
									}
								>
									{/* min */}
									<div className={"flex flex-col "}>
										<div className={"text-sm text-clr-simple-bg font-medium"}>
											Min
										</div>
										<div className={"relative text-clr-primary-gray text-sm"}>
											<p className="absolute right-10 top-2 font-bold">$</p>
											<input
												className={
													"outline-none py-2 px-2 w-[80%] border border-clr-stroke rounded-lg"
												}
												value={priceRange.min}
												onChange={(e) => handlePriceInput(e)}
												id="min"
											/>
										</div>
									</div>
									{/* max */}
									<div className={"flex flex-col "}>
										<div className={"text-sm text-clr-simple-bg font-medium"}>
											Max
										</div>
										<div className={"relative text-clr-primary-gray text-sm"}>
											<p className="absolute right-10 top-2 font-bold">$</p>
											<input
												className={
													"outline-none py-2 px-2 w-[80%] border border-clr-stroke rounded-lg"
												}
												value={priceRange.max}
												onChange={(e) => handlePriceInput(e)}
												id="max"
											/>
										</div>
									</div>
								</div>
								<button
									className="px-2 py-2 mt-2 w-full text-sm shadow-formsShadow text-white font-semibold bg-clr-primary-green rounded-lg hover:shadow-btnShadow"
									onClick={updatePriceInFilters}
								>
									Apply
								</button>
							</div>
						</div>
					)}

					{/* end stops */}
					{/* time */}
					{Object.keys(filterCategories.time.departTime).length > 0 && (
						<div className="mb-5 flex flex-col space-y-2 border-b pb-5">
							<h3 className="text-base text-clr-simple-bg font-semibold mb-2">
								Time
							</h3>
							<div className="ml-3 flex flex-col space-y-4">
								{/* departure */}
								<div className="flex flex-col space-y-5">
									{/* text */}
									<div className="flex flex-wrap items-center justify-between">
										<p className="flex flex-row space-x-1 text-base text-clr-primary-gray">
											<span>Departure : </span>
											<span>{getDuration(departureRange.start, false)}</span>
											<span> - </span>
											<span>{getDuration(departureRange.end, false)}</span>
										</p>
										{/* <button className="text-sm text-clr-primary-green font-medium">
											Clear
										</button> */}
									</div>
									<Slider
										range
										onChange={handleDepartureInput}
										min={getTimePerMinutes(
											format(new Date(), "yyyy-MM-dd HH:mm:ss")
										)}
										max={getTimePerMinutes(
											format(
												new Date().setHours(23, 59, 59, 999),
												"yyyy-MM-dd HH:mm:ss"
											)
										)}
										defaultValue={[
											getTimePerMinutes(
												format(new Date(), "yyyy-MM-dd HH:mm:ss")
											),
											getTimePerMinutes(
												format(
													new Date().setHours(23, 59, 59, 999),
													"yyyy-MM-dd HH:mm:ss"
												)
											),
										]}
										value={[departureRange.start, departureRange.end]}
										tooltip={{
											formatter: (value) => `${getDuration(value, false)}`,
										}}
										reverse={reverse}
									/>
								</div>
								{/* return */}
								<div className="flex flex-col space-y-5">
									{/* text */}
									<div className="flex flex-wrap items-center justify-between">
										<p className="flex flex-row space-x-1 text-base text-clr-primary-gray">
											<span>Arrival : </span>
											<span>{getDuration(arrivalRange.start, false)}</span>
											<span> - </span>
											<span>{getDuration(arrivalRange.end, false)}</span>
										</p>
										{/* <button className="text-sm text-clr-primary-green font-medium">
											Clear
										</button> */}
									</div>
									<Slider
										range
										onChange={handleArrivalInput}
										min={getTimePerMinutes(
											format(new Date(), "yyyy-MM-dd HH:mm:ss")
										)}
										max={getTimePerMinutes(
											format(
												new Date().setHours(23, 59, 59, 999),
												"yyyy-MM-dd HH:mm:ss"
											)
										)}
										defaultValue={[
											getTimePerMinutes(
												format(new Date(), "yyyy-MM-dd HH:mm:ss")
											),
											getTimePerMinutes(
												format(
													new Date().setHours(23, 59, 59, 999),
													"yyyy-MM-dd HH:mm:ss"
												)
											),
										]}
										value={[arrivalRange.start, arrivalRange.end]}
										tooltip={{
											formatter: (value) => `${getDuration(value, false)}`,
										}}
										reverse={reverse}
									/>
								</div>
							</div>
							<button
								className="px-2 py-2 mt-5 w-full text-sm shadow-formsShadow text-white font-semibold bg-clr-primary-green rounded-lg hover:shadow-btnShadow"
								onClick={updateTimeInFilters}
							>
								Apply
							</button>
						</div>
					)}
					{/* end time */}
					{/* duration */}
					{filterCategories.duration.length > 0 && (
						<div className="mb-5 flex flex-col space-y-2 border-b pb-5">
							<h3 className="text-base text-clr-simple-bg font-semibold mb-2">
								Duration
							</h3>
							<div className="ml-3 flex flex-col space-y-4">
								{/* departure */}
								<div className="flex flex-col space-y-5">
									{/* text */}
									{/* <div className="flex flex-row items-center justify-between">
										<p className="flex flex-row space-x-1 text-base text-clr-primary-gray">
											Any
										</p>
										<button className="text-sm text-clr-primary-green font-medium">
											Clear
										</button>
									</div> */}
									<Slider
										range
										onChange={handleDurationInput}
										min={filterCategories.duration[0]}
										max={filterCategories.duration[1]}
										defaultValue={[
											filterCategories.duration[0],
											filterCategories.duration[1],
										]}
										reverse={reverse}
										value={[durationRange.min, durationRange.max]}
									/>
								</div>
								<button
									className="px-2 py-2 mt-2 w-full text-sm shadow-formsShadow text-white font-semibold bg-clr-primary-green rounded-lg hover:shadow-btnShadow"
									onClick={updateDurationInFilters}
								>
									Apply
								</button>
							</div>
						</div>
					)}
					{/* end duration */}
					{/* airline */}
					{filterCategories.air_lines.length > 0 && (
						<div className="mb-5 flex flex-col space-y-2 pb-5">
							<h3 className="text-base text-clr-simple-bg font-semibold">
								Airlines
							</h3>
							{filterCategories.air_lines.map((item: any) => {
								return (
									<div key={item} className={"flex flex-row space-x-2 ml-3"}>
										<input
											type="checkbox"
											className="rounded w-4 bg-clr-stroke border-clr-stroke accent-clr-primary-green focus:ring-0 focus:ring-clr-stroke focus:bg-clr-primary-green hover:bg-clr-primary-green  checked:bg-clr-primary-green checked:border-clr-primary-green"
											name="fareTypeOption"
											id={item}
											value={item}
											onChange={(e) => handleAirlines(e)}
										/>
										<label
											htmlFor={item}
											className={
												"text-clr-primary-gray text-base font-medium cursor-pointer"
											}
										>
											{item}
										</label>
									</div>
								);
							})}

							{/* <button className="text-sm text-clr-primary-green font-medium">
								Show All
							</button> */}
						</div>
					)}

					{/* end airline */}
				</form>
			</div>
		</section>
	);
}

export default FlightFilter;
