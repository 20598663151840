import React, { useEffect, useState } from "react";

// lib
import { MdLuggage } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { BsCreditCardFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// custom
import { useMainContext } from "../../contexts/main_context";
import { Subscribe } from "../../components/Home";
import { classNames, getLocalStorage } from "../../utils/helpers";
import {
	FlightAside,
	FlightPassengers,
	FlightPayment,
	FlightServices,
} from "../../components/Flight/FlightBooking";
import { useFlightContext } from "../../contexts/flight_context";
import { Loading } from "../../components/Shared";

function FlightBookingPage() {
	const navigate = useNavigate();
	const { changeTheme } = useMainContext();
	const {
		single_flight,
		passengers,
		callBooking,
		flight_booking_loading,
		flight_booking_success,
	} = useFlightContext();
	const [currentTab, setCurrentType] = useState(1);

	// get current search data
	const currentSearchData = getLocalStorage("flight_form");

	const handleCancel = () => {
		if (currentTab == 1) {
			navigate("/flights");
		} else {
			setCurrentType(currentTab - 1);
		}
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const handleNext = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
		if (currentTab == 3) {
			callBooking();
		} else {
			if (
				currentTab == 2 &&
				passengers.length !==
					currentSearchData.AdultCount +
						currentSearchData.ChildCount +
						currentSearchData.InfantCount
			) {
				toast.error("please fill in all required input feild", {
					position: "top-left",
				});
				return 0;
			}
			setCurrentType(currentTab + 1);
		}
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "white",
			footerShow: true,
		});

		if (Object.keys(single_flight).length <= 0) {
			navigate("/flights");
		}
	}, []);

	useEffect(() => {
		if (flight_booking_success) navigate("/booking-sucess");
	}, [flight_booking_success]);

	return (
		<main>
			{flight_booking_loading && <Loading />}
			<div className="page max-w-custom-full w-smallWidth mx-auto my-10">
				{/* tabs */}
				<ul className="flex s_600:flex-row flex-col s_600:items-center items-start s_600:ml-0 ml-16 s_600:justify-between justify-center s_600:space-y-0 space-y-4">
					<li
						className="flex flex-row items-center space-x-2 cursor-pointer"
						onClick={() => setCurrentType(1)}
					>
						<span
							className={classNames(
								"p-2 rounded-md border",
								currentTab == 1
									? "bg-clr-simple-bg text-white border-clr-simple-bg"
									: " bg-transparent text-clr-light-gray border-clr-light-gray"
							)}
						>
							<MdLuggage className="w-6 h-6" />
						</span>
						<span
							className={classNames(
								"text-base font-medium",
								currentTab == 1 ? "text-clr-simple-bg" : "text-clr-light-gray"
							)}
						>
							Customize your trip
						</span>
					</li>

					<li
						className="flex flex-row items-center space-x-2 cursor-pointer"
						onClick={() => setCurrentType(2)}
					>
						<span
							className={classNames(
								"p-2 rounded-md border",
								currentTab == 2
									? "bg-clr-simple-bg text-white border-clr-simple-bg"
									: " bg-transparent text-clr-light-gray border-clr-light-gray"
							)}
						>
							<HiUsers className="w-6 h-6" />
						</span>
						<span
							className={classNames(
								"text-base font-medium",
								currentTab == 2 ? "text-clr-simple-bg" : "text-clr-light-gray"
							)}
						>
							Who's flying?
						</span>
					</li>

					<li
						className="flex flex-row items-center space-x-2 cursor-pointer"
						onClick={() => setCurrentType(3)}
					>
						<span
							className={classNames(
								"p-2 rounded-md border",
								currentTab == 3
									? "bg-clr-simple-bg text-white border-clr-simple-bg"
									: " bg-transparent text-clr-light-gray border-clr-light-gray"
							)}
						>
							<BsCreditCardFill className="w-6 h-6" />
						</span>
						<span
							className={classNames(
								"text-base font-medium",
								currentTab == 3 ? "text-clr-simple-bg" : "text-clr-light-gray"
							)}
						>
							Check and pay
						</span>
					</li>
				</ul>
				{/* content*/}
				<div className="grid grid-cols-12 gap-3 my-20">
					{/* section */}
					<section className="s_1000:col-span-9 col-span-12">
						{currentTab == 1 && <FlightServices />}

						{currentTab == 2 && <FlightPassengers />}

						{currentTab == 3 && <FlightPayment />}
					</section>
					{/* aside */}
					<FlightAside />
				</div>
				{/* btns */}
				<div className="flex flex-row space-x-4 relative">
					<button
						className="py-2 px-4 rounded-md shadow-lg hover:shadow-btnShadow border border-clr-stroke text-clr-simple-bg text-lg font-semibold"
						onClick={handleCancel}
					>
						Cancel
					</button>
					<button
						className="py-2 px-8 rounded-md shadow-lg hover:shadow-btnShadow border border-clr-primary-green text-white bg-clr-primary-green text-lg font-semibold"
						onClick={handleNext}
					>
						{currentTab == 3 ? "Complete reservation" : "Next"}
					</button>
				</div>
			</div>
			<Subscribe />
		</main>
	);
}

export default FlightBookingPage;
