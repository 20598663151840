import {
	FaFacebookF,
	FaInstagram,
	FaTelegramPlane,
	FaTwitter,
} from "react-icons/fa";

import Twitter from "../assets/imgs/twitter 2.svg";
import {
	Binance,
	Express,
	Master,
	Pay,
	Solar,
	Stripe,
	Visa,
	Wechat,
} from "../assets/imgs/footer_cards";

import { User_1, User_2, User_3, User_4 } from "../assets/imgs/users_icon";
import {
	Popular_1,
	Popular_2,
	Popular_3,
	Popular_4,
} from "../assets/imgs/popular_destination";
import {
	Package_1_icon,
	Package_1_img,
	Package_2_icon,
	Package_2_img,
	Package_3_icon,
	Package_3_img,
} from "../assets/imgs/packages";
import {
	BusinessIcon,
	BusinessIcon_small,
	CarIcon,
	CarIcon_small,
	FlightIcon,
	FlightIcon_small,
	HomeIcon,
	HomeIcon_small,
	HotelIcon,
	HotelIcon_small,
} from "../assets/imgs/experiences_icons";
import {
	Destination_1,
	Destination_2,
	Destination_3,
	Destination_4,
} from "../assets/imgs/destinations";
import { approch, mission, vision } from "../assets/imgs/about_goals";
import {
	managment,
	payment,
	plane,
	world,
} from "../assets/imgs/about_features";
import { p1, p2, p3, p4, p5 } from "../assets/imgs/about_partners";

export const api_url =
	process.env.NODE_ENV === "production"
		? process.env.REACT_APP_Back_URL_SERVER
		: process.env.REACT_APP_Back_URL_TEST;

export const shared_url: any =
	process.env.NODE_ENV === "production"
		? process.env.REACT_APP_SHARED_URL_SERVER
		: process.env.REACT_APP_SHARED_URL_TEST;

export const navbarList = [
	{
		id: "navList_1",
		text: "Home",
		href: "/",
	},
	{
		id: "navList_2",
		text: "About",
		href: "/about",
	},
	{
		id: "navList_3",
		text: "Packages",
		href: "/packages",
	},
	{
		id: "navList_4",
		text: "Contact Us",
		href: "/contact",
	},
];

export const navbarAuthList = [
	{
		id: "navAuthList_1",
		text: "Sign In",
		href: "/login",
	},
	{
		id: "navAuthList_2",
		text: "Register",
		href: "/register",
	},
];

export const socialList = [
	{
		id: "socialList_1",
		icon: <FaFacebookF />,
		href: "https://www.facebook.com/cashcareofficial",
	},
	{
		id: "socialList_2",
		icon: <FaInstagram />,
		href: "https://www.instagram.com/cashcare.co/",
	},
	{
		id: "socialList_3",
		icon: <img src={Twitter} alt="twitter logo" className="w-5 h-5" />,
		href: "https://twitter.com/CashCare_co",
	},
	{
		id: "socialList_4",
		icon: <FaTelegramPlane />,
		href: "https://t.me/CashCare12",
	},
];

export const footer_cards = [
	{
		id: "footer_cards_1",
		img: Visa,
		alt: "Visa Card",
	},
	{
		id: "footer_cards_2",
		img: Master,
		alt: "Master Card",
	},
	{
		id: "footer_cards_3",
		img: Stripe,
		alt: "Stript Gateway",
	},
	{
		id: "footer_cards_4",
		img: Solar,
		alt: "Solar Gateway",
	},
	{
		id: "footer_cards_5",
		img: Pay,
		alt: "Pay Gateway",
	},
	{
		id: "footer_cards_6",
		img: Express,
		alt: "Express Gateway",
	},
	{
		id: "footer_cards_7",
		img: Wechat,
		alt: "Wechat Gateway",
	},
	{
		id: "footer_cards_8",
		img: Binance,
		alt: "Binance Gateway",
	},
];

export const footerLinks = [
	{
		id: "footer_col_1",
		name: "Company",
		options: [
			{
				id: "footer_col_1_1",
				text: "About Us",
				href: "/about",
			},
			{
				id: "footer_col_1_2",
				text: "My Trip",
				href: "/trips",
			},
			{
				id: "footer_col_1_3",
				text: "Smart Program",
				href: "/smart-program",
			},
			{
				id: "footer_col_1_4",
				text: "Invite Program",
				href: "/invite-program",
			},
			{
				id: "footer_col_1_5",
				text: "Price Guarantee",
				href: "/price-guarantee",
			},
			{
				id: "footer_col_1_6",
				text: "Affiliate Program",
				href: "/affiliate-program",
			},
		],
	},
	{
		id: "footer_col_2",
		name: "Support",
		options: [
			// {
			// 	id: "footer_col_2_1",
			// 	text: "Help Center",
			// 	href: "/",
			// },
			{
				id: "footer_col_2_2",
				text: "FAQs",
				href: "/faqs",
			},
			{
				id: "footer_col_2_3",
				text: "Terms & Conditions",
				href: "/terms",
			},
			{
				id: "footer_col_2_4",
				text: "Privacy Policy",
				href: "/policy",
			},
			{
				id: "footer_col_2_5",
				text: "Contact Us",
				href: "/contact",
			},
		],
	},
	{
		id: "footer_col_3",
		name: "Useful Links",
		options: [
			{
				id: "footer_col_3_1",
				text: "Mobile App",
				href: "/apps",
			},
			{
				id: "footer_col_3_2",
				text: "Payment Options",
				href: "/payments",
			},
		],
	},
	{
		id: "footer_col_4",
		name: "Resources",
		options: [
			{
				id: "footer_col_4_1",
				text: "Official Blog",
				href: "/blogs",
			},
			{
				id: "footer_col_4_2",
				text: "Travel Advice",
				href: "/travel-advice",
			},
		],
	},
];

export const testimonials = [
	{
		id: "testimonials_1",
		title: "It was a very good experience 1",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Cursus nibh mauris, nec turpis orci lectus maecenas.Suspendisse sed magna eget nibh in turpis. Consequat duis diamlacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium intincidunt. Suspendisse sed magna eget nibh in turpis.",
		icon: User_1,
	},
	{
		id: "testimonials_2",
		title: "It was a very good experience 2",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Cursus nibh mauris, nec turpis orci lectus maecenas.Suspendisse sed magna eget nibh in turpis. Consequat duis diamlacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium intincidunt. Suspendisse sed magna eget nibh in turpis.",
		icon: User_2,
	},
	{
		id: "testimonials_3",
		title: "It was a very good experience 3",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Cursus nibh mauris, nec turpis orci lectus maecenas.Suspendisse sed magna eget nibh in turpis. Consequat duis diamlacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium intincidunt. Suspendisse sed magna eget nibh in turpis.",
		icon: User_3,
	},
	{
		id: "testimonials_4",
		title: "It was a very good experience 4",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Cursus nibh mauris, nec turpis orci lectus maecenas.Suspendisse sed magna eget nibh in turpis. Consequat duis diamlacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium intincidunt. Suspendisse sed magna eget nibh in turpis.",
		icon: User_4,
	},
];

export const popDestinations = [
	{
		id: "popDestinations_1",
		title: "france",
		icon: Popular_1,
	},
	{
		id: "popDestinations_2",
		title: "france",
		icon: Popular_2,
	},
	{
		id: "popDestinations_3",
		title: "france",
		icon: Popular_3,
	},
	{
		id: "popDestinations_4",
		title: "france",
		icon: Popular_4,
	},
];

export const packages = [
	{
		id: "packages_1",
		title: "Greece",
		img: Package_1_img,
		icon: Package_1_icon,
		duration: "8 Days",
		people: "25 People going",
		old_price: "1,200 $",
		new_price: "1,000 $",
		rate: 4,
		text: "Embark on a sun-kissed Mediterranean voyage, where charming villages, crystal-clear waters, and ancient wonders await.",
		link: "/",
	},
	{
		id: "packages_2",
		title: "Australia",
		img: Package_2_img,
		icon: Package_2_icon,
		duration: "8 Days",
		people: "75 People going",
		old_price: "1,200 $",
		new_price: "1,000 $",
		rate: 5,
		text: "For the adventurer in you. Explore picturesque mountains and diverse landscapes from the Outback to pristine beaches.",
		link: "/",
	},
	{
		id: "packages_3",
		title: "Saudi Arabia",
		img: Package_3_img,
		icon: Package_3_icon,
		duration: "8 Days",
		people: "125 People going",
		old_price: "1,200 $",
		new_price: "1,000 $",
		rate: 3,
		text: "Discover a desert oasis where ancient heritage and modern luxury converge for an unforgettable Arabian adventure.",
		link: "/",
	},
];

export const experiences = [
	{
		id: "experiences_1",
		title: "Flight",
		icon: FlightIcon,
		icon_sm: FlightIcon_small,
		text: "View the best rates and book international or domestic flights.",
	},

	{
		id: "experiences_2",
		title: "Hotels",
		icon: HotelIcon,
		icon_sm: HotelIcon_small,
		text: "Select from cozy inns to standard or luxurious hotels globally. ",
	},
	{
		id: "experiences_3",
		title: "Homestays",
		icon: HomeIcon,
		icon_sm: HomeIcon_small,
		text: "When you need to feel at home, away from home.",
	},
	{
		id: "experiences_4",
		title: "Car Rentals",
		icon: CarIcon,
		icon_sm: CarIcon_small,
		text: "A smooth, uninterrupted journey right from take-off.",
	},
	{
		id: "experiences_5",
		title: "Charter Flights",
		icon: BusinessIcon,
		icon_sm: BusinessIcon_small,
		text: "Jet set like a VIP with a fleet of private jets.",
	},
];

export const destinations = [
	{
		id: "destinations_1",
		img: Destination_1,
		price: "700 $",
	},
	{
		id: "destinations_2",
		img: Destination_2,
		price: "800 $",
	},
	{
		id: "destinations_3",
		img: Destination_3,
		price: "500 $",
	},
	{
		id: "destinations_4",
		img: Destination_4,
		price: "400 $",
	},
];

export const paymentMethods = {
	0: [],
	1: ["wallet"],
	2: ["creditNote"],
	3: ["cashback"],
	4: ["wallet", "cashback"],
	5: ["wallet", "creditNote"],
	6: ["creditNote", "cashback"],
	7: ["wallet", "creditNote", "cashback"],
};

export const goals = [
	{
		id: "goal_1",
		imgSrc: mission,
		title: "Our Mission",
		desc: "Enabling a seamless experience for the traveler on the go and digitizing the travel process to allow travelers to give in to their travel spontaneity anytime, anywhere.",
	},
	{
		id: "goal_2",
		imgSrc: vision,
		title: "Our Vision",
		desc: "Empowering the vibrant global travel community to explore the option of paying to travel with virtual currency and truly live the globetrotter lifestyle, sans financial limitations.",
	},
	{
		id: "goal_3",
		imgSrc: approch,
		title: "Our Approach",
		desc: "We aim to reach out to the tech savvy traveler who want to explore their options beyond traditional payment methods and the stresses that follow, made better with competitive cash back rewards.",
	},
];

export const aboutFeatures = [
	{
		id: "aboutFeature_1",
		imgSrc: plane,
		title: "Best Price Guarantee",
		desc: "If you find a lower price on another platform, we'll match it or refund the difference, so you don’t need to worry about finding the best deal!",
	},
	{
		id: "aboutFeature_2",
		imgSrc: world,
		title: "Affiliate Program",
		desc: "Join our Affiliate Program and turn your passion for travel into a rewarding opportunity. Earn generous commissions through your unique referral link.",
	},
	{
		id: "aboutFeature_3",
		imgSrc: payment,
		title: "Smart Program",
		desc: "Our Smart Program is designed exclusively for savvy travelers like you. By joining the Smart Program, you gain access to a world of exclusive perks",
	},
	{
		id: "aboutFeature_4",
		imgSrc: managment,
		title: "Secure and Transparent Transactions",
		desc: "Our platform utilizes cutting-edge blockchain technology to enable secure and transparent transactions which are paramount to us, and to you.",
	},
];

export const aboutStatics = [
	{
		id: "aboutStatics_1",
		title: "Travel Experience",
		count: "24k",
	},
	{
		id: "aboutStatics_2",
		title: "Countries Serve",
		count: "55+",
	},
	{
		id: "aboutStatics_3",
		title: "Overall USD Discount",
		count: "50M",
	},
	{
		id: "aboutStatics_4",
		title: "User per Year",
		count: "88M",
	},
];

export const aboutPartners = [
	{
		id: "aboutPartners_1",
		imgSrc: p1,
	},
	{
		id: "aboutPartners_2",
		imgSrc: p2,
	},
	{
		id: "aboutPartners_3",
		imgSrc: p3,
	},
	{
		id: "aboutPartners_4",
		imgSrc: p4,
	},
	{
		id: "aboutPartners_5",
		imgSrc: p5,
	},
];
