import React, { useEffect } from "react";

// custom
import { useMainContext } from "../../contexts/main_context";
import { Subscribe } from "../../components/Home";
import {
	Filter,
	FlightList,
	Hero,
	Sort,
} from "../../components/Flight/FlightResult";
import {
	Loading,
	SkeletonCard,
	SkeletonFilter,
	SkeletonSort,
} from "../../components/Shared";
import { useFlightContext } from "../../contexts/flight_context";
import FlightFilterModal from "../../components/Flight/FlightResult/FlightFilterModal";

function FlightPage() {
	const { changeTheme } = useMainContext();
	const { filterCategories, flights, flights_loading } = useFlightContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "white",
			footerShow: true,
		});
	}, []);

	return (
		<main>
			{flights_loading && <Loading />}
			<Hero />
			<div className="page max-w-custom-full w-smallWidth mx-auto">
				<div className="section-center grid grid-cols-12 gap-y-12 gap-x-8 my-16 mx-auto ">
					<div className="s_1000:col-span-4 s_900:col-span-3 s_1000:flex hidden sticky top-20">
						{filterCategories.stops.length > 0 && <Filter />}

						{filterCategories.stops.length <= 0 && <SkeletonFilter />}
					</div>

					<div className="flex flex-col space-y-5 s_1000:col-span-8 s_900:col-span-12 col-span-12">
						{flights.length > 0 && (
							<>
								<Sort />
								<FlightList />
							</>
						)}

						{flights.length <= 0 && (
							<>
								<SkeletonSort />
								<SkeletonCard />
								<SkeletonCard />
							</>
						)}
					</div>
				</div>
			</div>
			<Subscribe />
			<FlightFilterModal />
		</main>
	);
}

export default FlightPage;
