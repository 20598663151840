import React from "react";
import { Navbar } from "../Shared";
import { IoIosArrowForward } from "react-icons/io";

function Hero() {
	return (
		<div className="bg-home_bg_hero bg-cover bg-no-repeat bg-center h-[90vh]">
			<Navbar />
			<div className="max-w-custom-full w-smallWidth mx-auto md:items-start items-center md:justify-start justify-center flex flex-col">
				<div className="flex flex-col md:items-start items-center md:mt-16 mt-16 text-white md:space-y-5 space-y-5 relative">
					<h3 className="text-2xl font-medium md:text-start text-center">
						Hop on aboard.
					</h3>
					<h1 className="text-7xl tracking-wider font-bold md:w-2/3 md:text-start text-center">
						The world is waiting!
					</h1>
					<p className="text-base font-normal md:w-[40%] w-[80%] md:text-start text-center">
						From spirited leisure vacations or gearing you up for business
						travels. Simplified travel is now yours to experience.
					</p>
					<button className="flex flex-row items-center justify-center space-x-2 w-[200px]  bg-clr-primary-green border border-clr-primary-green py-3 rounded-md hover:shadow-btnShadow">
						<span className="text-base font-semibold">Let's Start Now</span>
						<span className="bg-white p-1 rounded-full">
							<IoIosArrowForward className="text-clr-primary-green" />
						</span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Hero;
