import React from "react";

//lib
import { FaRegCopyright } from "react-icons/fa";
import { Link } from "react-router-dom";

// custom
import Dark_logo from "../../assets/imgs/dark_logo.svg";
import { footerLinks, footer_cards } from "../../utils/constants";

function Footer() {
	return (
		<div className="relative bg-white pt-10 pb-2">
			<div className="flex flex-col space-y-10 items-center justify-center max-w-custom-full w-smallWidth mx-auto">
				{/* row */}
				<div className="grid grid-cols-12 s_1000:gap-3 gap-8">
					{/* first col */}
					<div className="s_1000:col-span-3 col-span-12 flex flex-col s_1000:items-start s_1000:justify-center items-center justify-center space-y-12">
						{/* logo & title */}
						<div className="flex flex-row items-center justify-start space-x-2">
							<img src={Dark_logo} alt="logo" />
							<span className="text-clr-simple-bg text-2xl font-medium">
								Ticks ‘n Travel
							</span>
						</div>
						{/* desc & cards */}
						<div className="flex flex-col s_1000:items-start s_1000:justify-start items-center justify-center space-y-4">
							<p className="text-clr-light-gray text-base font-medium s_1000:w-[75%] s_1000:text-start text-center">
								We accept ToLcoin as a primary payment method, and other leading
								cryptocurrencies and credit and debit cards.
							</p>
							<ul className="grid grid-cols-4 gap-1 w-1/2">
								{footer_cards.map((item) => {
									return (
										<li className="" key={item.id}>
											<img src={item.img} alt={item.alt} />
										</li>
									);
								})}
							</ul>
						</div>
					</div>
					{/* second col */}
					<div className="s_1000:col-span-9 col-span-12 grid gap-5 s_1000:grid-cols-4 s_500:grid-cols-3 s_370:grid-cols-2 grid-cols-1">
						{footerLinks.map((linkItem: any) => {
							return (
								<div
									key={linkItem.id}
									className="flex flex-col items-start s_1000::ml-20 mx-auto"
								>
									<h3 className="text-2xl font-semibold mb-5 text-clr-simple-bg">
										{linkItem.name}
									</h3>
									<ul className="flex flex-col space-y-[2px] text-clr-primary-gray">
										{linkItem.options.map((colItem: any) => {
											return (
												<li
													key={colItem.id}
													className="text-sm font-normal pb-1 hover:underline "
												>
													<Link to={colItem.href}>{colItem.text}</Link>
												</li>
											);
										})}
									</ul>
								</div>
							);
						})}
					</div>
				</div>
				{/* row */}
				<div className="flex flex-row items-center justify-center text-center text-clr-simple-bg">
					<span>
						<FaRegCopyright />
					</span>
					<span>2023 Ticks'nTravel</span>
				</div>
			</div>
		</div>
	);
}

export default Footer;
