import React, { useState, useEffect } from "react";

// lib
import Cookies from "js-cookie";

// custom
import { classNames, findIndexByPaymentMethods } from "../../../utils/helpers";
import { useAuthContext } from "../../../contexts/auth_context";
import { useMainContext } from "../../../contexts/main_context";
import { useFlightContext } from "../../../contexts/flight_context";
import { paymentMethods } from "../../../utils/constants";

function FlightPaymentGatewayCard() {
	const [currentGateway, setCurrentGateWay] = useState(["wallet"]);
	const { user } = useAuthContext();
	const { openPrivateKeyModal, openCashbackModal, openCreditNoteModal } =
		useMainContext();
	const { bookingBody, setBookingBody } = useFlightContext();

	const currentPkey = Cookies.get("key");

	// choose geteway
	const handleGateway = (type: string) => {
		if (currentGateway.includes(type)) {
			const temp = currentGateway.filter((item) => item != type);
			setCurrentGateWay(temp);
		} else {
			setCurrentGateWay([...currentGateway, type]);
		}
	};

	const handleWalletConnect = () => {
		if (currentPkey != undefined) {
		} else {
			openPrivateKeyModal();
		}
	};

	// save private key from storage
	useEffect(() => {
		if (currentPkey != undefined) {
			setBookingBody({ ...bookingBody, private_key: currentPkey });
		}
	}, [currentPkey]);

	useEffect(() => {
		if (currentGateway.length > 0) {
			const currentIndex = findIndexByPaymentMethods(
				paymentMethods,
				currentGateway
			);
			// console.log(currentGateway, "methods", currentIndex);
			setBookingBody({ ...bookingBody, payment_method: currentIndex });
		}
	}, [currentGateway]);

	return (
		<div className="py-2 px-8 bg-white shadow-2xl shadow-clr-primary-gray rounded-md relative flex flex-col">
			<h1 className="text-clr-simple-bg text-lg font-semibold py-8">
				Your payment method:
			</h1>
			<div className={"flex flex-col space-y-8 border-t pt-10 pb-16"}>
				{/* options */}
				<div className="flex flex-wrap">
					{/* <div className="flex flex-col space-y-1">
						<span
							onClick={(e) => setCurrentGateWay("visaCard")}
							className={classNames(
								"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2 font-semibold",
								currentGateway == "visaCard"
									? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
									: "border-clr-light-gray text-clr-light-gray cursor-pointer"
							)}
						>
							<span className="text-sm font-medium">Credit/Debit Card</span>
						</span>
					</div> */}
					{/* wallet */}
					<div className="flex flex-col space-y-1">
						<span
							onClick={(e) => handleGateway("wallet")}
							className={classNames(
								"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2 font-semibold",
								currentGateway.includes("wallet")
									? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
									: "border-clr-light-gray text-clr-light-gray cursor-pointer"
							)}
						>
							<span className="text-sm font-medium">Your Wallet</span>
						</span>
					</div>
					{/* credit note */}
					<div className="flex flex-col ">
						<span
							onClick={(e) => handleGateway("creditNote")}
							className={classNames(
								"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2 font-semibold",
								currentGateway.includes("creditNote")
									? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
									: "border-clr-light-gray text-clr-light-gray cursor-pointer"
							)}
						>
							<span className="text-sm font-medium">Credit Note</span>
						</span>
						{currentGateway.includes("creditNote") && (
							<span
								className="text-xs text-blue-400 underline italic cursor-pointer mx-auto"
								onClick={openCreditNoteModal}
							>
								show details
							</span>
						)}
					</div>
					{/* cashback */}
					<div className="flex flex-col ">
						<span
							onClick={(e) => handleGateway("cashback")}
							className={classNames(
								"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2 font-semibold",
								currentGateway.includes("cashback")
									? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
									: "border-clr-light-gray text-clr-light-gray cursor-pointer"
							)}
						>
							<span className="text-sm font-medium">Cashback</span>
						</span>
						{currentGateway.includes("cashback") && (
							<span
								className="text-xs text-blue-400 underline italic cursor-pointer mx-auto"
								onClick={openCashbackModal}
							>
								show details
							</span>
						)}
					</div>
				</div>
				{/* row */}
				<div className="flex flex-col space-y-2">
					<h1 className="text-lg text-clr-primary-green font-semibold">
						Wallet Address
					</h1>
					<p className=" text-clr-simple-bg text-sm py-3 px-2 md:w-2/3 w-[80%] rounded-md border border-clr-stroke break-all">
						{user.user.walletAddress}
					</p>
				</div>

				<button
					className={classNames(
						"text-lg font-semibold py-2 px-5 w-[140px] rounded-lg shadow-xl hover:shadow-btnShadow",
						currentPkey != undefined
							? "bg-clr-primary-green text-white "
							: "bg-transparent text-clr-primary-green "
					)}
					onClick={handleWalletConnect}
				>
					{currentPkey != undefined ? "Connected" : "Connect"}
				</button>
			</div>
		</div>
	);
}

export default FlightPaymentGatewayCard;
