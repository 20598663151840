import React from "react";

// lib
import { Link } from "react-router-dom";

// custom
import Airplane from "../../assets/imgs/airplane_icon.svg";
import spot_u from "../../assets/imgs/g_spot_u.svg";
import spot_d from "../../assets/imgs/g_spot_d.svg";
import base_tour from "../../assets/imgs/tour_base_img.svg";
import { destinations } from "../../utils/constants";
import FormList from "./SearchForms/FormList";

function Tours() {
	return (
		<div className="relative h-auto py-10 flex flex-col items-center">
			<img src={spot_u} alt="spot" className="absolute top-32 right-20 -z-10" />
			<FormList />
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col space-y-20 mt-10 relative">
				{/* desc */}
				<div className="flex flex-col items-start justify-start space-y-10 md:w-1/2 w-2/3 mt-14">
					<h1 className="text-clr-simple-bg font-bold md:text-5xl text-4xl flex flex-row items-start space-x-0  md:leading-[4.2rem]">
						<img src={Airplane} alt="airline" className="mr-2 w-20 mt-1" />
						<span>Your travel itinerary, in your hands.</span>
					</h1>
					<p className="text-clr-primary-gray text-lg font-normal md:w-[70%] md:text-start w-[90%] ml-14">
						The thrill of travel begins in the planning—and you’re at the right
						place!
					</p>
					<p className="text-clr-primary-gray text-lg font-normal md:w-[70%] md:text-start w-[90%] ml-14">
						Ticks’nTravel brings all your travel needs together. Cave into your
						wanderlust and take on the world solo and plan your business travels
						in one go. Enjoy our generous cash back rewards and get a head start
						on your vacation state of mind as you book with us.
					</p>
					{/* btn */}
					<button className="bg-clr-primary-green py-3 px-2 text-white text-base font-semibold md:w-1/3 w-1/2 rounded-lg ml-14">
						Explore Now
					</button>
				</div>
				{/* tours */}
				<div className="grid md:grid-cols-5 s_600:grid-cols-4 s_430:grid-cols-3 grid-cols-2 gap-5">
					{destinations.map((item: any) => {
						return (
							<Link
								to={"/"}
								className="group w-full relative shadow-picShadow "
								key={item.id}
							>
								<img
									src={item.img}
									alt={item.id}
									className="group-hover:shadow-boxShadow w-full h-full rounded-lg"
								/>
								<div className="absolute top-6 flex flex-row items-center justify-end px-5 w-full">
									<span className="bg-clr-primary-green px-4 py-1 rounded-full text-white opacity-70 uppercase text-sm ">
										{item.price}
									</span>
								</div>
							</Link>
						);
					})}
				</div>
				<img
					src={base_tour}
					alt="base tour"
					className="absolute -z-30 bottom-0 xl:-right-10 right-0 opacity-70 s_430:flex hidden"
				/>
			</div>
			<img
				src={spot_d}
				alt="spot"
				className="absolute bottom-10 left-20 -z-10"
			/>
		</div>
	);
}

export default Tours;
