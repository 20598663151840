import {
    SET_USER_TOKEN,
    SIDEBAR_OPEN,
    SIDEBAR_CLOSE,
    CHANGE_THEME,
    PRIVATE_KEY_MODAL_OPEN,
	  PRIVATE_KEY_MODAL_CLOSE,
    CREDIT_NOTE_MODAL_OPEN,
	  CREDIT_NOTE_MODAL_CLOSE,
    SET_CREDIT_NOTE,
	  CASHBACK_MODAL_OPEN,
	  CASHBACK_MODAL_CLOSE,
    SET_CASHBACK,
    SET_PREV_PAGE,
  } from '../utils/actions'
  
  const main_reducer = (state : any , action : any) => {
  
    switch (action.type) {
      case SET_USER_TOKEN:
        return {...state, user_token :action.payload}
      case SIDEBAR_OPEN:
        return {...state, isSidebarOpen :true}
      case SIDEBAR_CLOSE:
        return { ...state, isSidebarOpen: false } 
        
      case CHANGE_THEME :  
        return { ...state, theme: action.payload } 

      case PRIVATE_KEY_MODAL_OPEN:
      return {...state, isPrivateKeyModalOpen :true}
      case PRIVATE_KEY_MODAL_CLOSE:
        return { ...state, isPrivateKeyModalOpen: false } 

      case CREDIT_NOTE_MODAL_OPEN:
        return {...state, isCreditNoteModalOpen :true}
      case CREDIT_NOTE_MODAL_CLOSE:
        return { ...state, isCreditNoteModalOpen: false } 
      case SET_CREDIT_NOTE:  
        return { ...state, creditNote: action.payload }

      case CASHBACK_MODAL_OPEN:
        return {...state, isCashbackModalOpen :true}
      case CASHBACK_MODAL_CLOSE:
        return { ...state, isCashbackModalOpen: false }
      case SET_CASHBACK:  
        return { ...state, cashback: action.payload }   
      case SET_PREV_PAGE:
        return { ...state, prevPage: action.payload }  
      default:
        return state
      //throw new Error(`No Matching "${action.type}" - action type`)
    }
  }
  
  export default main_reducer