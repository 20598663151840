import React, { useEffect } from "react";

// custom
import { useMainContext } from "../../contexts/main_context";
import {
	Experiences,
	Hero,
	Packages,
	PopularDestinations,
	Subscribe,
	Testimonials,
	TopView,
	Tours,
} from "../../components/Home";

function Homepage() {
	const { changeTheme } = useMainContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: false,
			navbg: "transparent",
			footerShow: true,
		});
	}, []);

	return (
		<div className="relative">
			<div className="flex flex-col">
				<Hero />
				<Tours />
				<Experiences />
				<Packages />
				{/* <TopView /> */}
				<PopularDestinations />
				<Testimonials />
				<Subscribe />
			</div>
		</div>
	);
}

export default Homepage;
