import React from "react";

// custom
import RegisterForm from "./RegisterForm";
// import RegImg from "../../assets/imgs/reg_img.svg";
// import AppleStore from "../../assets/imgs/apple_store.svg";
// import GoogleStore from "../../assets/imgs/google_store.svg";

function RegisterContent() {
	return (
		<div className="flex flex-col items-center justify-between">
			<RegisterForm />
		</div>
	);
}

export default RegisterContent;
