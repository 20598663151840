import React from "react";

// custom
import { IoIosArrowForward } from "react-icons/io";

function Subscribe() {
	return (
		<div className="relative bg-home_bg_subscribe bg-cover bg-no-repeat bg-center h-[420px] flex flex-col items-center justify-center">
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col items-center justify-center space-y-10 mt-10">
				<h1 className="text-white md:text-3xl text-2xl font-bold text-center w-[50%] mx-auto">
					Subscribe and be the first to receive member exclusive offers, deals
					and updates!
				</h1>
				<div className="flex flex-row items-center justify-center bg-white p-2 md:w-[60%] w-[90%] mx-auto rounded-lg shadow-boxShadow">
					<input
						type="text"
						className="w-full py-3 px-2 text-clr-simple-bg placeholder:text-clr-light-gray outline-none"
						placeholder="Email Address"
					/>
					<button className="flex flex-row items-center justify-center s_430:space-x-2 s_430:w-[140px]  bg-clr-primary-green border border-clr-primary-green py-3 px-1 rounded-md hover:shadow-btnShadow">
						<span className="text-base font-semibold text-white s_430:flex hidden">
							Subscribe
						</span>
						<span className="bg-white p-1 rounded-full">
							<IoIosArrowForward className="text-clr-primary-green" />
						</span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Subscribe;
