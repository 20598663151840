import React, { useEffect, useState } from "react";

// lib
import { Modal } from "antd";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { FaRegCopy } from "react-icons/fa";

//custom
import { axios_auth } from "../../utils/axios";
import { useAuthContext } from "../../contexts/auth_context";

function WalletDepositModal({ isActive, setDisActive }: any) {
	const { user } = useAuthContext();

	const handleCopy = () => {
		const key: any = document.getElementById("key");
		navigator.clipboard.writeText(key.innerHTML);
		setDisActive();
		toast.success("key is copied", {
			position: "top-left",
		});
	};

	return (
		<Modal
			title={
				<div className="flex flex-row space-x-1 items-center ">
					<h1 className="text-3xl font-bold text-clr-primary-green bg-transparent">
						Deposit
					</h1>
				</div>
			}
			style={{ top: 20 }}
			width={800}
			footer={<div></div>}
			open={isActive}
			onOk={setDisActive}
			onCancel={setDisActive}
		>
			<div className="flex flex-col overflow-x-auto min-w-full w-full rounded-lg mb-10 max-h-80 overflow-y-auto mt-10">
				<span className="text-clr-simple-bg text-xl font-semibold">
					Public Address:
				</span>
				<p className="flex flex-row items-center justify-between border border-clr-stroke bg-clr-stroke rounded-lg s_700:w-10/12 w-full py-3 px-2">
					<span
						id="key"
						className="break-all text-clr-primary-gray font-medium text-lg"
					>
						{user.user.walletAddress}
					</span>
					<FaRegCopy
						onClick={handleCopy}
						className="w-5 h-5 text-clr-primary-green cursor-pointer"
					/>
				</p>
			</div>
		</Modal>
	);
}

export default WalletDepositModal;
