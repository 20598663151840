import React from "react";

// lib
import { IoIosArrowForward } from "react-icons/io";

// custom
import Arrow from "../../assets/imgs/about_arrow.svg";
import img_up from "../../assets/imgs/about_breif_up.svg";
import aboutGif from "../../assets/imgs/about_breif_gif.gif";

function Breif() {
	return (
		<div className="s_900:py-20 py-5 relative">
			<div className="max-w-custom-full w-smallWidth mx-auto flex s_900:flex-row flex-col">
				{/* description */}
				<div className="flex flex-col space-y-8 items-start justify-start s_900:w-1/2  mt-16">
					<h1 className="capitalize text-clr-simple-bg text-5xl font-bold">
						Travel on your own terms.
					</h1>
					<p className="text-base text-clr-primary-gray font-normal">
						Seamlessly book multiple steps to your travel plans—from commercial
						or charter flights, to accommodation and car rentals across multiple
						destinations.
					</p>
					<ul className="flex flex-col space-y-3">
						<li className="flex flex-row space-x-2 items-start">
							<img src={Arrow} alt="arrow" className="mt-2" />
							<span className="text-base text-clr-primary-gray font-medium">
								Our own digital payment gateway for attractive cash back rewards
								on every booking, redeemable on any future bookings.
							</span>
						</li>
						<li className="flex flex-row space-x-2 items-start">
							<img src={Arrow} alt="arrow" className="mt-2" />
							<span className="text-base text-clr-primary-gray font-medium">
								No foreign currency transaction hassles- what you see is what
								you pay.
							</span>
						</li>
						<li className="flex flex-row space-x-2 items-start">
							<img src={Arrow} alt="arrow" className="mt-2" />
							<span className="text-base text-clr-primary-gray font-medium">
								Exclusive deals with selected travel partners for even greater
								rewards and discounts on flights and hotels.
							</span>
						</li>
						<li className="flex flex-row space-x-2 items-start">
							<img src={Arrow} alt="arrow" className="mt-2" />
							<span className="text-base text-clr-primary-gray font-medium">
								Connecting you on every step of the way of your travel plans,
								with instant updates on your booking modifications.
							</span>
						</li>
					</ul>
					<button className="flex flex-row items-center justify-center space-x-2 w-[200px]  bg-clr-primary-green border border-clr-primary-green py-3 rounded-md hover:shadow-btnShadow">
						<span className="text-base font-semibold text-white">
							Start Booking
						</span>
						<span className="bg-white p-1 rounded-full">
							<IoIosArrowForward className="text-clr-primary-green" />
						</span>
					</button>
				</div>
				{/* imgs */}
				<div className="flex flex-col items-center justify-center relative px-5">
					<img src={aboutGif} alt="gif" />
					<img
						src={img_up}
						alt="up"
						className="absolute bottom-0 s_900:right-0 right-10 s_900:w-[60%] w-1/2"
					/>
				</div>
			</div>
		</div>
	);
}

export default Breif;
