import React, { useEffect, useState } from "react";

// custom
import { useMainContext } from "../../contexts/main_context";
import { Subscribe } from "../../components/Home";

function PolicyPage() {
	const { changeTheme } = useMainContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "transparent",
			footerShow: true,
		});
	}, []);

	return (
		<main className="">
			<div className="page max-w-custom-full w-smallWidth mx-auto">
				<div className="flex flex-col items-center justify-center">
					<h1 className="text-clr-simple-bg text-4xl font-bold text-center py-12">
						Privacy Policy
					</h1>
					<div className="py-10 rounded-md shadow-boxShadow w-full mb-10 flex flex-col space-y-8">
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<p className="text-clr-primary-gray text-base font-medium">
								Last updated: 11th September 2023 Phydias SA, a company
								incorporated in Switzerland (hereinafter referred to as
								“Ticks’nTravel”, “we” or “us”) recognises the importance of
								privacy. In this privacy policy, we describe how we collect,
								use, and disclose information that we obtain about visitors to
								our website, www.ticksntravel.com, and the services available
								through our website.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								1. Information we collect.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We collect personal information directly from you, from third
								parties, and automatically through your use of our website. We
								may combine information automatically collected with other
								information that we have collected about you. Where applicable,
								we may also collect “Anonymous Data” from you which means data,
								including aggregated and de-identified data, that is not
								associated with or linked to your personal information.
								Anonymous Data does not, by itself, permit the identification of
								individual persons.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Personal information means any information that can be used to
								identify you.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								We are the Data Controller for data collected through our
								website.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								If you are in the EU or the UK, the lawful basis when we process
								your personal information for the provision of our services is
								pursuant to a contract.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								If you are in the EU or the UK, the lawful basis when we process
								your personal information for marketing is consent.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								You may browse and use certain portions of our website without
								directly providing us with any personal information.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Certain features, however, may only be used by users that are
								registered or where you provide us with personal information.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Information we collect from you (or someone acting for you):
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>
									contact information, including your name, address, email
									address, telephone number and other contact details;
								</li>
								<li>
									billing or payment information, such as your credit card
									number, cardholder name, expiration date, authentication code
									and billing address;
								</li>
								<li>
									details of the products and services we have provided to you
									or that you have enquired about, including any additional
									information necessary to deliver those products and services
									and respond to your enquiries;
								</li>
								<li>
									information you provide to us through customer surveys or
									feedback;
								</li>
								<li>
									passport information, including your passport number, passport
									expiration date, and date of birth
								</li>
								<li>
									loyalty program details (such as frequent flyer details);
								</li>
								<li>information about your health (if disclosed);</li>
								<li>
									your device ID, device type, geo-location information,
									computer and connection information, statistics on page views,
									traffic to and from the Website, ad data, IP address and
									standard web log information;
								</li>
								<li>
									any additional information relating to you that you provide to
									us through your use of our website, in person or through other
									websites or accounts from which you permit us to collect
									information;
								</li>
								<li>
									any other personal information that may be required in order
									to facilitate your dealings with us.
								</li>
							</ul>
							<p className="text-clr-primary-gray text-base font-medium">
								Information about you we collect from third parties.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								We may receive personal information and/or Anonymous Data about
								you from companies that offer their products and/or services for
								use in conjunction with the use of our website or whose products
								and/or services may be linked to the services on our website.
								For example, including and not limited to, information in
								relation to you from flight carriers, hotel/property operators,
								travel membership/loyalty program providers, property management
								service providers and/or activity service providers for the
								performance of any contract or verification/confirmation
								purposes, third-party wallet providers that may provide us with
								your blockchain address, or payment service providers or payment
								wallet providers who may provide us with your debit/credit card
								details for payment verification/confirmation and other
								information you choose to share with those wallets providers. We
								may add this to the data we have already collected from you to
								provide the services on our website.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								If you apply to work with us, we collect information from you
								from:
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>recruitment consultants;</li>
								<li>your previous employers; and</li>
								<li>
									other organizations that are authorized to give us information
									about you
								</li>
							</ul>
							<p className="text-clr-primary-gray text-base font-medium">
								to assist us in deciding the outcome of your application.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Information we collect automatically. We automatically collect
								information through your use of our website using cookies and
								other technologies. This information includes your
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>
									domain name, operating system, settings and system
									configurations,
								</li>
								<li>IP address,</li>
								<li>the webpages you access within our website,</li>
								<li>the website that led you to our website,</li>
								<li>the website to which you go after leaving our website;</li>
								<li>the dates and times you access our website, and</li>
								<li>web log personal information.</li>
							</ul>
							<p className="text-clr-primary-gray text-base font-medium">
								Public information we may record. We may collect data from
								activity that is publicly visible and/or accessible on
								blockchains. This may include blockchain addresses and
								information regarding purchases, sales, or transfers of NFTs,
								which may then be associated with other data you have provided
								to us.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								2. How we use your information.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We use your information for the following purposes:
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>
									Providing our services. Provide accommodation, flights, and
									experiences booking services on the website, to manage your
									account, to communicate with you, including via email, about
									your use of our services, to respond to your inquires, and for
									similar customer service purposes.
								</li>
								<li>
									Marketing. Where you consent, we will provide you with news,
									special offers, promotions, and information about products we
									think may interest you; and for other marketing, advertising,
									and promotional purposes provided that you have not opted-out
									of receiving such communications.
								</li>
								<li>
									Analysing Use of Our Services. To better understand how users
									access and use our website and services, on an aggregated
									basis; to respond to user desires and preferences; to improve
									our website navigation and its service; and for other research
									and analytical purposes. In addition, we may create Anonymous
									Data records derived from personal information provided for
									the aforesaid purposes. We reserve the right to use Anonymous
									Data for any lawful purpose and to disclose Anonymous Data to
									third parties lawfully.
								</li>
								<li>
									To Protect Rights and Interests. To protect our rights and
									interests, as well as the rights and interests of our
									customers, users of our website or services, and any other
									person.
								</li>
							</ul>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								3. Marketing.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								Ticks'nTravel would like to send you information about our
								products and services that we think you might like. If you have
								agreed to receive marketing communications, you may always opt
								out at a later date. You have the right at any time to stop us
								from contacting you for marketing purposes.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								If you no longer wish to be contacted for marketing purposes,
								please click the ‘Unsubscribe’ button contained in the footer of
								each of our emails or contact us at{" "}
								<a href="mailto:contact@ticksntravel.com" className="font-bold">
									contact@ticksntravel.com
								</a>
								.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								4. How we store your personal information.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We undertake a number of physical, administrative, personnel,
								and technical measures to protect your personal information and
								prevent it from misuse, interference and loss, as well as
								unauthorized access, modification or disclosure. Our data
								security measures include, but are not limited to: Secure
								Sockets Layer (SSL) encryption technology, pseudonymisation,
								internal data access restrictions, and strict physical access
								controls to buildings & files.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Ticks'nTravel will keep your personal information for the
								duration that your account is active, and for the requisite
								period in accordance with applicable law following the
								deactivation of your account. Once this time period has expired,
								we will delete your personal information.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								5. Security
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We take reasonable steps to protect your personal information
								from misuse, loss, unauthorized access, modification or
								disclosure. For example, we take steps to destroy or permanently
								de-identify personal information if we no longer need it for any
								purpose. Please be aware that despite our efforts, no personal
								information security measures can guarantee 100% security.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								If you have an account with us, you should take steps to protect
								against unauthorized access to your account by, among other
								things, choosing a robust password that nobody else knows or can
								easily guess and keeping your log-in and password private. We
								are not responsible for any lost, stolen, or compromised
								passwords or for any activity on your account via unauthorized
								password activity.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								6. How we disclose your information.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We may disclose your information, including personal
								information, with the following entities:
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>
									Our Group of Companies and Employees. We disclose your
									required information to our employees, subsidiary, related
									and/or affiliated companies in order to perform the necessary
									duties to provide you with our services.
								</li>
								<li>
									Service Providers. We disclose your information to our
									vendors, service providers, or others who perform functions on
									our behalf. All service providers are required to keep your
									personal data safe, and process pursuant to a data processing
									agreement. If the service provider is located outside of the
									UK or the EU, we put in place measures to ensure that your
									information has the same level of protection.
								</li>
							</ul>
							<p className="text-clr-primary-gray text-base font-medium">
								We may also disclose your information, including personal
								information, in the following ways:
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>
									Business Transfers. We may disclose your information to
									another entity if we are acquired by or merged with another
									company, if we sell or transfer a business unit or assets to
									another company, as part of a bankruptcy proceeding, or as
									part of a similar business transfer.
								</li>
								<li>
									Protecting Rights and Interests. We may disclose your
									information where we believe it is necessary to investigate,
									prevent, or take action regarding illegal activities,
									suspected fraud, situations involving potential threats to the
									safety of any person, violations of our Terms and Conditions
									or this privacy policy, or as evidence in litigation in which
									we are involved.
								</li>
								<li>
									Cross Border Disclosure of Information. We may disclose your
									personal information to international third parties, including
									countries outside the European Economic Area (EEA)
									(collectively “Cross-border Disclosure”), generally to arrange
									travel with a travel service provider on your behalf. Whenever
									we perform Cross-border Disclosures, we will do so in
									accordance with applicable law and ensure that a similar
									degree of protection is afforded to it by implementing
									appropriate safeguards. Cross-border Disclosures outside the
									EEA will only be made:
									<ul className="text-clr-light-gray  ml-12">
										<li>
											i. to a country recognised by the European Commission as
											providing an adequate level of protection; or
										</li>
										<li>
											ii. to a country which does not offer adequate protection,
											but whose transfer has been governed by the standard
											contractual clauses of the European Commission, or by
											implementing other appropriate cross-border transfer
											solutions to provide adequate protection.
										</li>
									</ul>
								</li>
							</ul>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								7. Cookies and other tracking mechanisms.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We and our service providers use cookies and other tracking
								mechanisms to track your use of our website or services. We use
								these in a range of ways including:
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>Keeping you signed in;</li>
								<li>Understanding how you use our website;</li>
								<li>
									Functionality - Ticks'nTravel uses tracking mechanisms so that
									we recognise you on our website and remember your previously
									selected preferences. These could include what language you
									prefer, the currency in which prices are displayed, and the
									location you are in. A mix of first-party and third-party
									tracking mechanisms are used; and
								</li>
								<li>
									Advertising - Ticks'nTravel uses these tracking mechanisms to
									collect information about your visit to our website, the
									content you viewed, the links you followed and information
									about your browser, device, and your IP address. Ticks'nTravel
									shares some limited aspects of this personal information with
									third parties for advertising purposes. We also share personal
									information collected through tracking mechanisms with our
									advertising partners. This means that when you visit another
									website, you may be shown advertising based on your browsing
									patterns on our website.
								</li>
							</ul>
							<p className="text-clr-primary-gray text-base font-medium">
								Types of tracking mechanism we use:
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>
									Cookies. We or our service providers use cookies to track
									visitor activity on our website. A cookie is a text file that
									a website transfers to your computer's hard drive for
									record-keeping purposes. We or our service providers may use
									cookies to track user activities on our website, such as the
									pages visited and time spent on our website. Most browsers
									allow users to refuse cookies. The ‘Help’ portion of the
									toolbar on most browsers will tell you how to prevent your
									computer from accepting new cookies, how to have the browser
									notify you when you receive a new cookie, or how to disable
									cookies altogether. Users who disable cookies may not be able
									to browse certain areas of the website. Please refer to our
									<span className="font-bold">Cookie Policy</span> for further
									information in regards to Cookies used on our website; and
								</li>
								<li>
									Clear GIFs, pixel tags and other technologies. Clear GIFs are
									tiny graphics with a unique identifier, similar in function to
									cookies, which are embedded invisibly on web pages. We or our
									service providers may use clear GIFs (also known as web
									beacons, web bugs or pixel tags), in connection with our
									website to track the activities of visitors to our website,
									help us manage content, and compile statistics about usage of
									our website. We or our service providers also use clear GIFs
									in HTML emails to our users, to help us track email response
									rates, identify when our emails are viewed, and track whether
									our emails are forwarded.
								</li>
							</ul>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								8. Third-party analytics.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We use service providers, such as Google Analytics demographics
								and interests reports as well as advertising reporting features,
								to evaluate the use of our website and our services. We or our
								service providers use automated devices and applications to
								evaluate the use of our website and services. We or our service
								providers use these tools to help us improve our website,
								services, performance, and user experiences. These entities may
								use cookies and other tracking technologies, such as web beacons
								or Flash LSO, to perform their services. To opt out of Google
								Analytics, go here. To opt out of Google Analytics for display
								advertising or customise Google display network ads, you can
								visit the Google Ads Settings page.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								9. Interest-based advertising.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We use third parties such as network advertisers to serve
								advertisements on our website and on third-party websites or
								other media (e.g., social networking platforms). This enables us
								and these third parties to target advertisements to you for
								products and services in which you might be interested.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								10. User-generated content.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								Note that if you post information in a publicly accessible
								portion of our website or service, it may be viewed by other
								users and potentially be further disclosed by those users.
								Please exercise caution when deciding to disclose such
								information.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								11. Your choices.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We take steps to ensure the personal information that
								Ticks'nTravel collects, uses or discloses is accurate, complete
								and up to date.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								You may modify your personal information by updating your
								account on our website or by contacting us at
								<a href="mailto:contact@ticksntravel.com" className="font-bold">
									contact@ticksntravel.com
								</a>
								. On request, we will give you access to the personal
								information we hold about you. If any personal information we
								hold about you is out of date or inaccurate, we encourage you to
								let us know by contacting us using one of the methods listed
								above and ask us to correct it.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								If you are a registered user, we may send periodic informational
								emails to you. You may opt out of such communications at any
								time by following the opt-out instructions contained in the
								email. Please note that it may take up to five business days for
								us to process opt-out requests.
							</p>
							<p>
								If you opt out of receiving emails about recommendations or
								other information we think may interest you, we will still send
								you emails about your account or any services you have requested
								or received from us provided that you have not opted-out of
								receiving such emails.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								12. Your Rights.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								As the operator of the Website, we take the protection of your
								user data very seriously. We collect, process, and use your
								personal data in accordance with this privacy policy and in
								compliance with the Swiss Federal Act on Data Protection
								(“FADP”), the Swiss Ordinance to the Federal Act on Data
								Protection (“OFADP”), and the General European Data Protection
								Regulation (“GDPR”), which are{" "}
								<span className="font-bold">
									currently being coordinated and under review as of 1st
									September 2023.
								</span>
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>
									The right to access – You have the right to request copies of
									your personal information from us. We may charge you a small
									fee to cover the cost of this request.
								</li>
								<li>
									The right to rectification – You have the right to request
									that we correct any information you believe is inaccurate. You
									also have the right to request us to complete the information
									you believe is incomplete.
								</li>
								<li>
									The right to erasure – You have the right to request that we
									erase your personal information, under certain conditions.
								</li>
								<li>
									The right to restrict processing – You have the right to
									request that we restrict the processing of your personal
									information, under certain conditions.
								</li>
								<li>
									The right to object to processing – You have the right to
									object to our processing of your personal information, under
									certain conditions.
								</li>
							</ul>
							<p className="text-clr-primary-gray text-base font-medium">
								If you would like to exercise any of these rights, please
								contact us at{" "}
								<a href="mailto:contact@ticksntravel.com">
									contact@ticksntravel.com
								</a>
								. If you make a request, we have one month to respond to you.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Notwithstanding the above, please be aware that we cannot edit
								or delete any personal information that is stored on a
								blockchain, as we do not have custody or control over any
								blockchains. The information stored on the blockchain may
								include (but is not limited to) purchases, sales, and transfers
								related to your blockchain address and/or any metadata that may
								be attributable to you that can be viewed on the blockchain,
								such as when you complete or accept a booking on our website, or
								when you interact with any of our blockchain-based products,
								including the Open Passport NFT (including NFT projects).{" "}
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								13. Age Restrictions.
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								Our website is not targeted to users under 18 years of age and
								we do not knowingly collect personal information from users
								under 18 without parental consent.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								If we discover that the personal information of a user under 18
								of age is in the system without parental consent, we will
								promptly delete such personal information from our systems.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								14. External links
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								This website contains links to other websites. We are not
								responsible for the information handling practices or content of
								these external websites. Please read the privacy policies of
								these third parties before using such websites.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								15. Third-party payment processors and wallets
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								In order to use the services offered on our website, you may opt
								to use payment options which utilize a third party payment
								processor or a third-party wallet which allows you to engage in
								transactions on public blockchains. Your interactions with any
								third-party payment processor or wallet provider are governed by
								the applicable terms of service and privacy policy of that third
								party.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								16. Contact us
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								If you have any questions or concerns about the privacy aspects
								of our website or services or want to make a complaint about an
								interference with your privacy by Ticks'nTravel, please email us
								at{" "}
								<a href="mailto:contact@ticksntravel.com">
									contact@ticksntravel.com
								</a>
								. We will do our best to resolve your complaint as quickly as
								possible.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								You can also contact your local data protection authority to
								lodge a complaint.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								If you are in the EU you can find your Data Protection Authority{" "}
								<a
									href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
									className="font-bold"
								>
									here.
								</a>
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3 ">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								17. Changes to this privacy policy
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								We may change this privacy policy from time to time, and new
								versions will be posted on this website, so please check back
								periodically for updates.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								If you are registered with us, we will let you know when we
								update this privacy policy.
							</p>
						</div>
					</div>
				</div>
			</div>
			<Subscribe />
		</main>
	);
}

export default PolicyPage;
