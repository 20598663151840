import React, { useEffect, useState } from "react";

// custom
import { useMainContext } from "../../contexts/main_context";
import { Subscribe, Testimonials } from "../../components/Home";
import {
	Hero,
	Breif,
	Visions,
	Features,
	Statiscs,
	Partners,
} from "../../components/About";

function AboutPage() {
	const { changeTheme } = useMainContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "transparent",
			footerShow: true,
		});
	}, []);

	return (
		<div className="relative">
			<div className="flex flex-col">
				<Hero />
				<Breif />
				<Visions />
				<Features />
				<Statiscs />
				<Partners />
				<Testimonials />
				<Subscribe />
			</div>
		</div>
	);
}

export default AboutPage;
