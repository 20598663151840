import React from "react";

// custom
import { aboutPartners } from "../../utils/constants";

function Partners() {
	return (
		<div className="relative  py-20 flex flex-col items-center justify-center">
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col items-center justify-center space-y-8">
				<h1 className="text-clr-simple-bg text-5xl font-bold">
					Our Valuable Partners
				</h1>
				<div className="grid md:grid-cols-5 s_500:grid-cols-3 grid-cols-1 gap-8">
					{aboutPartners.map((item: any) => {
						return (
							<div
								key={item.id}
								className="flex flex-col items-center justify-center space-y-5 grayscale hover:grayscale-0 cursor-pointer"
							>
								<img src={item.imgSrc} alt={item.id} className="" />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default Partners;
