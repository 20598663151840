// main navbar
export const SIDEBAR_OPEN = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'


// theme
export const CHANGE_THEME = 'CHANGE_THEME'

// private key
export const PRIVATE_KEY_MODAL_OPEN = 'PRIVATE_KEY_MODAL_OPEN'
export const PRIVATE_KEY_MODAL_CLOSE = 'PRIVATE_KEY_MODAL_CLOSE'

// credit note
export const CREDIT_NOTE_MODAL_OPEN = 'CREDIT_NOTE_MODAL_OPEN'
export const CREDIT_NOTE_MODAL_CLOSE = 'CREDIT_NOTE_MODAL_CLOSE'
export const SET_CREDIT_NOTE = 'SET_CREDIT_NOTE'

// cashback
export const CASHBACK_MODAL_OPEN = 'CASHBACK_MODAL_OPEN'
export const CASHBACK_MODAL_CLOSE = 'CASHBACK_MODAL_CLOSE'
export const SET_CASHBACK = 'SET_CASHBACK'

// previous page
export const SET_PREV_PAGE = 'SET_PREV_PAGE'


// flights
export const SET_USER_TOKEN = 'SET_USER_TOKEN'
export const GET_FLIGHTS_BEGIN = 'GET_FLIGHTS_BEGIN'
export const GET_FLIGHTS_SUCCESS = 'GET_FLIGHTS_SUCCESS'
export const GET_FLIGHTS_ERROR = 'GET_FLIGHTS_ERROR'
// flight session
export const SET_FLIGHTS_SESSION = 'SET_FLIGHTS_SESSION'
//single fight
export const GET_SINGLE_FLIGHTS_BEGIN = 'GET_SINGLE_FLIGHTS_BEGIN'
export const GET_SINGLE_FLIGHTS_SUCCESS = 'GET_SINGLE_FLIGHTS_SUCCESS'
export const GET_SINGLE_FLIGHTS_ERROR = 'GET_SINGLE_FLIGHTS_ERROR'
// filter flight
export const LOAD_FLIGHTS = 'LOAD_FLIGHTS'
export const UPDATE_SORT = 'UPDATE_SORT'
export const SORT_FLIGHTS = 'SORT_FLIGHTS'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const FILTER_FLIGHTS = 'FILTER_FLIGHTS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const FILTER_MODAL_OPEN = 'FILTER_MODAL_OPEN'
export const FILTER_MODAL_CLOSE = 'FILTER_MODAL_CLOSE'

// passengers
export const SET_PASSENGERS_DATA = 'SET_PASSENGERS_DATA'
// booking
export const SET_BOOKING_BODY = 'SET_BOOKING_BODY'
export const FLIGHT_BOOKING_BEGIN = 'FLIGHT_BOOKING_BEGIN'
export const FLIGHT_BOOKING_SUCCESS = 'FLIGHT_BOOKING_SUCCESS'
export const FLIGHT_BOOKING_ERROR = 'FLIGHT_BOOKING_ERROR'



