import React, { useEffect } from "react";

//custom
import { useMainContext } from "../../contexts/main_context";
import ForgetForm from "../../components/Password/ForgetForm";

function ForgetPasswordPage() {
	const { changeTheme } = useMainContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "white",
			footerShow: false,
		});
	}, []);

	return (
		<div className="bg-base_bg_1 bg-cover bg-no-repeat bg-center w-full relative">
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col relative">
				<ForgetForm />
			</div>
		</div>
	);
}

export default ForgetPasswordPage;
