import React from "react";

// custom
import TestimonialsSlider from "./TestimonialsSlider";

function Testimonials() {
	return (
		<div className="relative bg-home_bg_testimonials bg-cover bg-no-repeat bg-clr-stroke-gray bg-center s_600:h-screen h-[700px] flex flex-col items-center justify-center">
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col items-center justify-center space-y-10 mt-10">
				<h1 className="text-clr-simple-bg md:text-4xl text-3xl font-bold text-center">
					Here is what our Clients are saying About us
				</h1>
				<TestimonialsSlider />
			</div>
		</div>
	);
}

export default Testimonials;
