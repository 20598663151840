// import axios from 'axios'
import React, { useContext, useEffect, useState, useReducer } from "react";

// libs
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

// custom
import reducer from "../reducers/main_reducer";
import {
	SET_USER_TOKEN,
	SIDEBAR_OPEN,
	SIDEBAR_CLOSE,
	CHANGE_THEME,
	PRIVATE_KEY_MODAL_OPEN,
	PRIVATE_KEY_MODAL_CLOSE,
	CREDIT_NOTE_MODAL_OPEN,
	CREDIT_NOTE_MODAL_CLOSE,
	SET_CREDIT_NOTE,
	CASHBACK_MODAL_OPEN,
	CASHBACK_MODAL_CLOSE,
	SET_CASHBACK,
	SET_PREV_PAGE,
} from "../utils/actions";
import { addOrRemoveItem } from "../utils/helpers";
import { axios } from "../utils/axios";

type THEME = {
	navShow: boolean;
	navbg: string;
	footerShow: boolean;
};

type ContextType = {
	user_token: string;
	fetchTempToken: (funObj: any) => void;
	isSidebarOpen: boolean;
	openSidebar: () => void;
	closeSidebar: () => void;
	theme: THEME;
	changeTheme: (themevar: THEME) => void;
	isPrivateKeyModalOpen: boolean;
	openPrivateKeyModal: () => void;
	closePrivateKeyModal: () => void;
	creditNote: [];
	setCreditNote: (credit: any) => void;
	isCreditNoteModalOpen: boolean;
	openCreditNoteModal: () => void;
	closeCreditNoteModal: () => void;
	cashback: [];
	setCashback: (cash: any) => void;
	isCashbackModalOpen: boolean;
	openCashbackModal: () => void;
	closeCashbackModal: () => void;
	prevPage: string;
	setPrevPage: (prev: string) => void;
};

const initialContext: ContextType = {
	/* ---------- user token --------- */
	user_token: "",
	fetchTempToken: (() => {}) as any,
	isSidebarOpen: false,
	openSidebar: (() => {}) as any,
	closeSidebar: (() => {}) as any,
	theme: {} as THEME,
	changeTheme: (() => {}) as any,
	isPrivateKeyModalOpen: false,
	openPrivateKeyModal: () => {},
	closePrivateKeyModal: () => {},
	/* ---------- credit note -------------- */
	creditNote: [],
	setCreditNote: () => {},
	isCreditNoteModalOpen: false,
	openCreditNoteModal: () => {},
	closeCreditNoteModal: () => {},
	/* ---------- cashback -------------- */
	cashback: [],
	setCashback: () => {},
	isCashbackModalOpen: false,
	openCashbackModal: () => {},
	closeCashbackModal: () => {},
	prevPage: "/",
	setPrevPage: () => {},
};

const MainContext = React.createContext(initialContext);

export const MainProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(reducer, initialContext);

	// get temp user token
	const fetchTempToken = async ({
		setTempTokenUserLoading,
		setTempTokenUserStatus,
	}: any) => {
		try {
			setTempTokenUserLoading(true);
			axios
				.get("/user/v1/temp-token")
				.then((res) => {
					if (res.data.status === true) {
						console.log("temp - token", res.data.data.temp_token);
						dispatch({
							type: SET_USER_TOKEN,
							payload: res.data.data.temp_token,
						});
						Cookies.remove("user_temp_token");
						Cookies.set("user_temp_token", res.data.data.temp_token, {
							expires: 7,
							path: "/",
						});
						setTempTokenUserStatus(true);
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
					setTempTokenUserLoading(false);
				})
				.catch((error) => {
					toast.error(error.response?.data.message, {
						position: "top-left",
					});
					setTempTokenUserLoading(false);
				});
		} catch (error: any) {
			toast.error(error.response?.data.message, {
				position: "top-left",
			});
			setTempTokenUserLoading(false);
		}
	};

	const openSidebar = () => {
		dispatch({ type: SIDEBAR_OPEN });
	};

	const closeSidebar = () => {
		dispatch({ type: SIDEBAR_CLOSE });
	};

	const changeTheme = (currentTheme: THEME) => {
		dispatch({ type: CHANGE_THEME, payload: currentTheme });
	};

	const openPrivateKeyModal = () => {
		dispatch({ type: PRIVATE_KEY_MODAL_OPEN });
	};

	const closePrivateKeyModal = () => {
		dispatch({ type: PRIVATE_KEY_MODAL_CLOSE });
	};

	const openCreditNoteModal = () => {
		dispatch({ type: CREDIT_NOTE_MODAL_OPEN });
	};

	const closeCreditNoteModal = () => {
		dispatch({ type: CREDIT_NOTE_MODAL_CLOSE });
	};

	const setCreditNote = (currentCredit: any) => {
		const tempArr = addOrRemoveItem(state.creditNote, currentCredit);
		dispatch({ type: SET_CREDIT_NOTE, payload: tempArr });
	};

	const openCashbackModal = () => {
		dispatch({ type: CASHBACK_MODAL_OPEN });
	};

	const closeCashbackModal = () => {
		dispatch({ type: CASHBACK_MODAL_CLOSE });
	};

	const setCashback = (currentCashback: any) => {
		const tempArr = addOrRemoveItem(state.cashback, currentCashback);
		dispatch({ type: SET_CASHBACK, payload: tempArr });
	};

	const setPrevPage = (prevPage: string) => {
		dispatch({ type: SET_PREV_PAGE, payload: prevPage });
	};

	// useEffect(() => {
	// 	fetchTempToken();
	// }, []);

	return (
		<MainContext.Provider
			value={{
				...state,
				openSidebar,
				closeSidebar,
				changeTheme,
				openPrivateKeyModal,
				closePrivateKeyModal,
				openCreditNoteModal,
				closeCreditNoteModal,
				setCreditNote,
				openCashbackModal,
				closeCashbackModal,
				setCashback,
				fetchTempToken,
				setPrevPage,
			}}
		>
			{children}
		</MainContext.Provider>
	);
};
// make sure use
export const useMainContext = () => {
	return useContext(MainContext);
};
