import React from "react";

// lib
import { IoIosArrowForward } from "react-icons/io";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Link } from "react-router-dom";

// custom
import { popDestinations } from "../../utils/constants";
import spot_d from "../../assets/imgs/g_spot_d.svg";
import spot_u from "../../assets/imgs/g_spot_u.svg";
import Airplane from "../../assets/imgs/airplane_icon.svg";

function PopularDestinations() {
	return (
		<div className="relative pt-20 pb-28 flex flex-col items-center justify-center">
			<img src={spot_u} alt="spot" className="absolute top-32 right-20 -z-10" />
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col space-y-10 mt-10">
				<div className="flex flex-wrap justify-between">
					<h1 className="text-clr-simple-bg font-bold text-4xl flex flex-row space-x-2">
						<img src={Airplane} alt="airline" />
						<span>Popular destinations</span>
					</h1>
					<Link
						to={"/"}
						className="flex flex-row items-center justify-center space-x-1 text-clr-primary-green text-base font-semibold capitalize"
					>
						<span>view more</span>
						<IoIosArrowForward />
					</Link>
				</div>
				<div className="grid s_1000:grid-cols-4 s_500:grid-cols-2 grid-cols-1 gap-10 w-[98%] mx-auto">
					{popDestinations.map((item: any) => {
						return (
							<Link
								to={"/"}
								className="relative w-full  rounded-md shadow-picShadow hover:shadow-boxShadow transition-all ease-in-out duration-200"
								key={item.id}
							>
								<img
									className="rounded-lg w-full"
									src={item.icon}
									alt={item.title}
								/>
								<div className="absolute top-6 flex flex-row items-center justify-between px-5 w-full">
									<span className="bg-clr-primary-gray px-4 py-1 rounded-full text-white opacity-70 uppercase text-sm ">
										{item.title}
									</span>
									<span className="group cursor-pointer">
										<AiOutlineHeart className="group-hover:hidden block text-white font-bold w-7 h-7" />
										<AiFillHeart className="group-hover:block hidden text-white font-bold w-7 h-7" />
									</span>
								</div>
							</Link>
						);
					})}
				</div>
			</div>
			<img
				src={spot_d}
				alt="spot"
				className="absolute bottom-10 left-20 -z-10"
			/>
		</div>
	);
}

export default PopularDestinations;
