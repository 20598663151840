import React, { useState, useRef } from "react";

// libs
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaUserAlt } from "react-icons/fa";

// custom
import Dark_logo from "../../assets/imgs/dark_logo.svg";
import Light_logo from "../../assets/imgs/light_logo.svg";
import { useMainContext } from "../../contexts/main_context";
import { useAuthContext } from "../../contexts/auth_context";
import UseRemoveInputFocus from "../../hooks/useRemoveInputFocus";
import { navbarList } from "../../utils/constants";
import { classNames } from "../../utils/helpers";

function Navbar() {
	const { user, logout } = useAuthContext();
	const { openSidebar, theme } = useMainContext();
	const [openUserList, setOpenUserList] = useState(false);

	let location = useLocation();
	const navigate = useNavigate();

	const profileListRef: any = useRef();
	UseRemoveInputFocus(profileListRef, () => setOpenUserList(false));

	return (
		<div
			className={classNames(
				"flex flex-col relative w-full",
				theme.navShow ? "bg-white z-50 shadow-lg" : "bg-transparent z-0"
			)}
		>
			<div className="max-w-custom-full w-smallWidth mx-auto flex items-center justify-between h-20">
				<div className="">
					<Link to="/">
						<img alt="logo" src={theme.navShow ? Dark_logo : Light_logo} />
					</Link>
				</div>
				<div className=" flex-col items-center justify-center s_900:flex hidden">
					<ul
						className={classNames(
							"flex flex-row space-x-8 font-Manrope text-lg font-bold items-center justify-center ",
							theme.navShow ? "text-clr-simple-bg" : "text-white"
						)}
					>
						{navbarList.map((item: any) => {
							return (
								<li
									className={classNames(
										"relative hover:text-clr-primary-green",
										location.pathname === item.href
											? "text-clr-primary-green"
											: ""
									)}
									key={item.id}
								>
									<Link to={item.href}>{item.text}</Link>
								</li>
							);
						})}
					</ul>
				</div>
				<div className="s_900:block hidden">
					{user.isLoggedin ? (
						<ul className="flex flex-row items-center space-x-2 text-lg font-bold">
							<li className="relative">
								<span
									onClick={() => setOpenUserList(!openUserList)}
									className="bg-clr-stroke-gray rounded-3xl px-4 py-2 text-clr-simple-bg flex flex-row items-center justify-center space-x-2 cursor-pointer"
								>
									<span className="capitalize">{`${user.user.firstName} ${user.user.lastName}`}</span>
									<span className="bg-white p-2 rounded-full">
										<FaUserAlt />
									</span>
								</span>
								{openUserList && (
									<ul
										ref={profileListRef}
										className="absolute top-14 z-20 bg-clr-simple-bg text-white rounded shadow-btnShadow w-[170px] flex flex-col items-center justify-center py-2 ml-5"
									>
										<li className="font-semibold text-lg py-1 border-b border-clr-light-gray w-[90%] mx-auto text-center hover:text-clr-primary-gray hover:bg-clr-stroke-gray rounded-t">
											<Link
												to="/user/profile"
												className=""
												onClick={() => setOpenUserList(false)}
											>
												Profile
											</Link>
										</li>
										<li className="font-semibold text-lg py-1 border-b border-clr-light-gray w-[90%] mx-auto text-center hover:text-clr-primary-gray hover:bg-clr-stroke-gray rounded-t">
											<Link
												to="/user/delete"
												className=""
												onClick={() => setOpenUserList(false)}
											>
												Delete Account
											</Link>
										</li>
										<li className="font-semibold text-lg py-1 last:border-none border-clr-light-gray w-[90%] mx-auto text-center hover:text-clr-primary-gray hover:bg-clr-stroke-gray last:rounded-b">
											<button onClick={logout} className="">
												Sign Out
											</button>
										</li>
									</ul>
								)}
							</li>
						</ul>
					) : (
						<ul className="flex flex-row items-center space-x-2 text-lg font-bold">
							<li>
								<Link
									to="/register"
									className={classNames(
										"border-2 border-transparent px-4 py-2 rounded-md ",
										theme.navShow
											? "text-clr-simple-bg hover:border-clr-simple-bg"
											: "text-white hover:border-white"
									)}
								>
									Register
								</Link>
							</li>
							<li>
								<Link
									to="/login"
									className={classNames(
										"flex flex-row items-center justify-center space-x-1 bg-clr-dark-purple border-2 px-4 py-2 rounded-md transition-all ease-in-out",
										theme.navShow
											? "border-clr-simple-bg text-clr-simple-bg hover:bg-clr-simple-bg hover:text-white"
											: "border-white text-white hover:bg-clr-primary-green hover:border-clr-primary-green"
									)}
								>
									<FaUserAlt />
									<span>Login</span>
								</Link>
							</li>
						</ul>
					)}
				</div>
				<p
					className={classNames(
						"  drop-shadow-2xl cursor-pointer font-bold text-2xl s_900:hidden",
						theme.navShow ? "text-clr-simple-bg" : "text-white"
					)}
				>
					<Link to="/">
						Ticks'<span className="text-clr-primary-green">n</span>Travel
					</Link>
				</p>
				<button
					type="button"
					onClick={openSidebar}
					id="nav-toggle"
					className="bg-transparent border-transparent text-clr-simple-bg cursor-pointer s_900:hidden"
				>
					<FaBars className="text-3xl" />
				</button>
			</div>
		</div>
	);
}

export default Navbar;
