import React from "react";

function SkeletonFilter() {
	return (
		<section className="s_900:sticky s_900:top-10 w-full">
			<div className=" bg-white shadow-listShadow rounded-lg py-2 animate-pulse w-full">
				<div className="flex flex-wrap items-center justify-between border-b-2 py-5 px-4 w-full ">
					<h2 className="text-lg font-semibold text-clr-primary-green w-full bg-gray-200"></h2>
					<div className="text-sm text-clr-primary-green font-medium w-full bg-gray-200"></div>
				</div>
				<form onSubmit={(e) => e.preventDefault()} className="px-8 py-4">
					{/* stops */}
					<div className="mb-5 flex flex-col space-y-2 pb-5 w-full">
						<h3 className="text-base text-clr-simple-bg font-semibold bg-gray-200 w-full"></h3>
						<div className={" bg-gray-200 w-full rounded-lg"}>
							<input className="appearance-none bg-gray-200 rounded-lg" />
							<label className={"text-clr-primary-gray "}></label>
						</div>
						<div className={" bg-gray-200 w-full rounded-lg"}>
							<input className="appearance-none bg-gray-200 rounded-lg" />
							<label className={"text-clr-primary-gray "}></label>
						</div>
						<div className={" bg-gray-200 w-full rounded-lg"}>
							<input className="appearance-none bg-gray-200 rounded-lg" />
							<label className={"text-clr-primary-gray "}></label>
						</div>
						<div className={" bg-gray-200 w-full rounded-lg"}>
							<input className="appearance-none bg-gray-200 rounded-lg" />
							<label className={"text-clr-primary-gray "}></label>
						</div>
					</div>
					{/* end stops */}
				</form>
			</div>
		</section>
	);
}

export default SkeletonFilter;
