import React, { useState, useEffect } from "react";

// lib
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

//custom
import { useAuthContext } from "../../contexts/auth_context";
import { classNames } from "../../utils/helpers";

const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

function ResetForm() {
	const { resetPassword } = useAuthContext();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(false);
	const [errors, setErrors] = useState(null);
	const [isValid, setIsValid] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [formData, setFormData] = useState({
		email: "",
		code: "",
		password: "",
		password_confirmation: "",
	});

	const [formValidate, setFormValidate] = useState({
		email: false,
		code: false,
		password: false,
		password_confirmation: false,
	});

	const [formFocus, setFormFocus] = useState({
		email: false,
		code: false,
		password: false,
		password_confirmation: false,
	});

	const handleInput = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		setFormData({ ...formData, [name]: value });
		setErrors(null);
	};

	useEffect(() => {
		setFormValidate({
			...formValidate,
			email: EMAIL_REGEX.test(formData.email),
			password: PASSWORD_REGEX.test(formData.password),
			password_confirmation:
				formData.password === formData.password_confirmation,
			code: formData.code.length > 0 && formData.code.length < 7,
		});

		setIsValid(
			formValidate.email &&
				formValidate.code &&
				formValidate.password &&
				formValidate.password_confirmation
		);
	}, [formData, formValidate.password_confirmation]);

	useEffect(() => {
		if (errors) {
			toast.error(errors, {
				position: "top-left",
			});
			//console.log("errors", errors);
		}
	}, [errors]);

	useEffect(() => {
		if (status) {
			navigate("/login");
		}
	}, [status]);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setStatus(false);
		setErrors(null);
		setLoading(true);

		if (!formValidate.email) {
			toast.error(`there was an error. please fill in email.`, {
				position: "top-left",
			});
			setLoading(false);
		} else if (!formValidate.code) {
			toast.error(`there was an error. please fill in code.`, {
				position: "top-left",
			});
			setLoading(false);
		} else if (!formValidate.password) {
			toast.error(
				`there was an error. Your Password weak must have @-#-$-% /Capital letter/numbers`,
				{
					position: "top-left",
				}
			);
			setLoading(false);
		} else if (!formValidate.password_confirmation) {
			toast.error(
				`there was an error. password Must match the first password input field.`,
				{
					position: "top-left",
				}
			);
			setLoading(false);
		} else {
			//console.log("form - valid", formData);
			resetPassword({
				...formData,
				setStatus,
				setErrors,
				setLoading,
			});
		}
	};

	return (
		<div className="flex flex-col items-center justify-between">
			<div className="py-7 my-36 rounded-3xl bg-white shadow-boxShadow flex flex-col space-y-3 items-center justify-center s_900:w-1/2 w-10/12">
				<h1 className="capitalize sm:text-4xl text-2xl text-center font-semibold text-clr-primary-green py-5">
					Reset New Password
				</h1>
				{/* <p className="text-clr-gray text-base">Log in or create an account.</p> */}
				<div className="w-9/12 flex flex-col space-y-3">
					{/* input - Email */}
					<div className="w-full relative">
						<label
							htmlFor="email"
							className="text-clr-simple-bg text-base font-medium"
						>
							Email *
						</label>
						<input
							type="text"
							id="email"
							name="email"
							value={formData.email}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={formValidate.email ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setFormFocus({ ...formFocus, email: true })}
							onBlur={() => setFormFocus({ ...formFocus, email: false })}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
								formFocus.email && !formValidate.email
									? "border-red-600"
									: formFocus.email && formValidate.email
									? "border-green-600"
									: "border-clr-stroke"
							)}
							placeholder="Email"
						/>
						<div
							id="uidnote"
							className={
								formFocus.email && formData.email && !formValidate.email
									? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
									: "hidden"
							}
						>
							Email not valid
						</div>
					</div>
					{/* input - code */}
					<div className="w-full relative">
						<label
							htmlFor="code"
							className="text-clr-simple-bg text-base font-medium"
						>
							Code *
						</label>
						<input
							type="text"
							id="code"
							name="code"
							value={formData.code}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={formValidate.code ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setFormFocus({ ...formFocus, code: true })}
							onBlur={() => setFormFocus({ ...formFocus, code: false })}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
								formFocus.code && !formValidate.code
									? "border-red-600"
									: formFocus.code && formValidate.code
									? "border-green-600"
									: "border-clr-stroke"
							)}
							placeholder="Code"
						/>
						<div
							id="uidnote"
							className={
								formFocus.code && formData.code && !formValidate.code
									? "font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1"
									: "hidden"
							}
						>
							code is not valid
						</div>
					</div>
					{/* input - Password */}
					<div className="w-full relative">
						<label
							htmlFor="password"
							className="text-clr-simple-bg text-base font-medium"
						>
							Password *
						</label>
						<input
							type={showPassword ? "text" : "password"}
							id="password"
							name="password"
							value={formData.password}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={formValidate.password ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setFormFocus({ ...formFocus, password: true })}
							onBlur={() => setFormFocus({ ...formFocus, password: false })}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
								formFocus.password && !formValidate.password
									? "border-red-600"
									: formFocus.password && formValidate.password
									? "border-green-600"
									: "border-clr-stroke"
							)}
							placeholder="Password"
						/>
						<button
							className="absolute top-10 right-5 text-clr-gray hover:underline text-sm"
							type="button"
							onClick={() => setShowPassword(!showPassword)}
						>
							{showPassword ? "hide" : "show"}
						</button>
						<div
							id="uidnote"
							className={
								formFocus.password &&
								formData.password &&
								!formValidate.password
									? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
									: "hidden"
							}
						>
							Your Password weak must have @-#-$-% /Capital letter/numbers
						</div>
					</div>
					{/* input - Confirm */}
					<div className="w-full relative">
						<label
							htmlFor="password_confirmation"
							className="text-clr-simple-bg text-base font-medium"
						>
							Confirm Passowrd *
						</label>
						<input
							type={showConfirmPassword ? "text" : "password"}
							id="password_confirmation"
							name="password_confirmation"
							value={formData.password_confirmation}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={
								formValidate.password_confirmation ? "false" : "true"
							}
							aria-describedby="uidnote"
							onFocus={() =>
								setFormFocus({ ...formFocus, password_confirmation: true })
							}
							onBlur={() =>
								setFormFocus({ ...formFocus, password_confirmation: false })
							}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
								formFocus.password_confirmation &&
									!formValidate.password_confirmation
									? "border-red-600"
									: formFocus.password_confirmation &&
									  formValidate.password_confirmation
									? "border-green-600"
									: "border-clr-stroke"
							)}
							placeholder="Conform Password"
						/>
						<button
							className="absolute top-10 right-5 text-clr-gray text-sm hover:underline"
							type="button"
							onClick={() => setShowConfirmPassword(!showConfirmPassword)}
						>
							{showConfirmPassword ? "hide" : "show"}
						</button>
						<div
							id="uidnote"
							className={
								formFocus.password_confirmation &&
								formData.password_confirmation &&
								!formValidate.password_confirmation
									? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
									: "hidden"
							}
						>
							Must match the first password input field.
						</div>
					</div>

					{/* btn */}
					<div className="flex flex-row items-center justify-center space-x-4 pb-3">
						<button
							onClick={handleSubmit}
							className="relative bg-clr-primary-green w-1/3 rounded-lg py-3 text-2xl font-bold text-white border-2 border-clr-primary-green hover:bg-transparent hover:text-clr-primary-green"
						>
							<span className="flex flex-row items-center justify-center space-x-2  text-clr-main-dark tracking-wider font-medium lg:text-lg md:text-base text-sm">
								<span>Reset</span>
								{loading && (
									<svg
										aria-hidden="true"
										role="status"
										className="inline w-4 h-4 mr-3 text-clr-main-dark font-semibold animate-spin"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="#E5E7EB"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentColor"
										/>
									</svg>
								)}
							</span>
						</button>
					</div>
				</div>
				<p className="text-base font-medium text-clr-light-gray">
					Already have an account?{" "}
					<Link className="text-clr-primary-green font-bold" to={"/login"}>
						Log In
					</Link>
				</p>
			</div>
		</div>
	);
}

export default ResetForm;
