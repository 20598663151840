import React, { useEffect } from "react";

// lib
import { Link } from "react-router-dom";

// custom
import { useMainContext } from "../../contexts/main_context";
import bookingSuccess from "../../assets/imgs/bookingSuccess.gif";

function BookingSuccess() {
	const { changeTheme } = useMainContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: false,
			navbg: "white",
			footerShow: false,
		});
	}, []);

	return (
		<div className="bg-clr-simple-bg bg-center S-900:h-screen h-auto w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative items-center justify-center h-screen">
				<div className="bg-white flex flex-col items-center justify-center py-14 sm:px-14 px-4 rounded-lg shadow-boxShadow space-y-8 mx-auto">
					<img
						src={bookingSuccess}
						alt="booking success"
						className="w-56 h-56"
					/>
					<h1 className="text-5xl capitalize font-bold text-clr-primary-green font-jihoBold mb-5">
						booking successful
					</h1>
					<p className="text-2xl font-semibold text-clr-primary-gray text-center">
						We will send an Email with the Booking information.
					</p>
					<Link
						to="/"
						className=" capitalize transition-all ease-in-out duration-200 px-6 py-3 bg-clr-primary-green text-white font-semibold  rounded-lg hover:shadow-btnShadow"
					>
						Done
					</Link>
				</div>
			</div>
		</div>
	);
}

export default BookingSuccess;
