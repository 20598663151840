import React from "react";

// libs
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

// custom
import Dark_logo from "../../assets/imgs/dark_logo.svg";
import Light_logo from "../../assets/imgs/light_logo.svg";
import { classNames } from "../../utils/helpers";
import { useMainContext } from "../../contexts/main_context";
import { useAuthContext } from "../../contexts/auth_context";
import { navbarList } from "../../utils/constants";

function Sidebar() {
	const { user, logout } = useAuthContext();
	const { isSidebarOpen, closeSidebar } = useMainContext();

	let location = useLocation();
	const navigate = useNavigate();

	return (
		<div className="">
			<aside
				className={classNames(
					isSidebarOpen ? "z-50 translate-x-0" : "-z-10 -translate-x-full",
					"fixed top-0 left-0 w-full h-auto bg-clr-light-gray  transition duration-300 ease-linear s_900:hidden rounded-br-xl rounded-bl-xl"
				)}
			>
				<div className="flex items-center justify-between mt-5 max-w-custom-full w-smallWidth mx-auto">
					<Link to="/">
						<img alt="logo" src={Dark_logo} />
					</Link>
					<button
						type="button"
						onClick={closeSidebar}
						className="text-3xl bg-transparent border-transparent cursor-pointer mt-1 text-clr-simple-bg transition duration-300 ease-linear"
					>
						<FaTimes />
					</button>
				</div>
				<div className="mt-5 max-w-custom-full w-smallWidth mx-auto">
					<ul className="flex flex-col space-y-2 text-lg font-bold text-clr-simple-bg">
						{navbarList.map((item: any) => {
							return (
								<li
									className={classNames(
										location.pathname === item.href
											? "ml-2 text-clr-primary-green drop-shadow-2xl shadow-clr-simple-bg"
											: "ml-0 text-clr-simple-bg",
										"relative hover:ml-2 hover:bg-clr-light-blue hover:text-clr-dark-purple py-1 px-2 rounded-md"
									)}
									key={item.id}
								>
									<Link to={item.href} onClick={closeSidebar}>
										{item.text}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
				<div className="m-10 pt-5 max-w-max-custom w-smallWidth mx-auto border-t border-clr-gray">
					{user.isLoggedin ? (
						<ul className="flex flex-col space-y-2 text-lg font-bold">
							<li
								className={classNames(
									location.pathname === "/user/profile"
										? "ml-2 text-clr-primary-green"
										: "ml-0 text-clr-simple-bg",
									"relative hover:ml-2 hover:bg-clr-light-blue hover:text-clr-dark-purple  py-1 px-2 rounded-md"
								)}
							>
								<Link to="/user/profile" className="" onClick={closeSidebar}>
									Profile
								</Link>
							</li>

							<li
								className={classNames(
									location.pathname === "/user/delete"
										? "ml-2 text-clr-primary-green"
										: "ml-0 text-clr-simple-bg",
									"relative hover:ml-2 hover:bg-clr-light-blue hover:text-clr-dark-purple  py-1 px-2 rounded-md"
								)}
							>
								<Link to="/user/delete" className="" onClick={closeSidebar}>
									Delete Account
								</Link>
							</li>

							<li className="relative text-clr-simple-bg hover:ml-2 hover:bg-clr-light-blue hover:text-clr-dark-purple py-1 px-2 rounded-md">
								<button onClick={logout} className="">
									Sign Out
								</button>
							</li>
						</ul>
					) : (
						<ul className="flex flex-col space-y-2 text-lg font-bold">
							<li
								className={classNames(
									location.pathname === "/register"
										? "ml-2 text-clr-primary-green drop-shadow-2xl shadow-clr-simple-bg"
										: "ml-0 text-clr-simple-bg",
									"relative hover:ml-2 hover:bg-clr-light-blue hover:text-clr-dark-purple py-1 px-2 rounded-md"
								)}
							>
								<Link to="/register" className="" onClick={closeSidebar}>
									Register
								</Link>
							</li>
							<li
								className={classNames(
									location.pathname === "/login"
										? "ml-2 text-clr-primary-green drop-shadow-2xl shadow-clr-simple-bg"
										: "ml-0 text-clr-simple-bg",
									"relative hover:ml-2 hover:bg-clr-light-blue hover:text-clr-dark-purple py-1 px-2 rounded-md"
								)}
							>
								<Link to="/login" className="" onClick={closeSidebar}>
									Login
								</Link>
							</li>
						</ul>
					)}
				</div>
			</aside>
		</div>
	);
}

export default Sidebar;
