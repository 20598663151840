import React from "react";

// custom
import { goals } from "../../utils/constants";

function Visions() {
	return (
		<div className="relative bg-about_vision_bg bg-cover bg-no-repeat  bg-center py-20 flex flex-col items-center justify-center">
			<div className="max-w-custom-full w-smallWidth mx-auto">
				<div className="grid md:grid-cols-3 s_500:grid-cols-2 grid-cols-1 gap-5">
					{goals.map((item: any) => {
						return (
							<div
								key={item.id}
								className="flex flex-col items-center justify-center space-y-5"
							>
								<img src={item.imgSrc} alt={item.title} />
								<h3 className="font-bold text-2xl text-clr-simple-bg">
									{item.title}
								</h3>
								<p className="w-[90%] mx-auto font-normal text-base text-clr-primary-gray">
									{item.desc}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default Visions;
