import React, { useState, useEffect } from "react";

// lib
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Pagination } from "antd";

// custom
import { axios_auth } from "../../utils/axios";
import { classNames } from "../../utils/helpers";
import { EmailForm, PasswordForm, PersonalForm } from "./SettingsForms";

function Settings() {
	const [currentTab, setCurrentTab] = useState(1);
	const [currentBookings, setCurrentBookings] = useState([]);
	const [usersTotal, setUsersTotal] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const handleChangePage = (page: any, pageSize: any) => {
		setCurrentPage(page);
		setPerPage(pageSize);
	};

	const getBookingHistory = async () => {
		axios_auth(Cookies.get("token"))
			.get("/v1/flight/web/bookings?page=1&perPage=" + perPage)
			.then((res) => {
				if (res.data.status === true) {
					//console.log("flight - booking", res.data.data[0]);
					if (res.data.data) {
						setCurrentBookings(res.data.data[0].bookings);
						setUsersTotal(res.data.data[0].total);
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				} else {
					toast.error(res.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.response?.data.message, {
					position: "top-left",
				});
			});
	};

	useEffect(() => {
		getBookingHistory();
	}, []);

	// for changing the page
	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get(
				"/v1/flight/web/bookings?page=" + currentPage + "&perPage=" + perPage
			)
			.then((res) => {
				if (res.data.status === true) {
					//console.log("flight - booking", res.data.data[0]);
					if (res.data.data) {
						setCurrentBookings(res.data.data[0].bookings);
						setUsersTotal(res.data.data[0].total);
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				} else {
					toast.error(res.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.response?.data.message, {
					position: "top-left",
				});
			});
	}, [currentPage, perPage]);

	return (
		<div className="flex flex-col items-center justify-between w-full shadow-listShadow rounded-lg">
			<div className="flex flex-col space-y-5 w-[90%] mx-auto py-10">
				{/* head */}
				<div className="flex flex-col">
					<h1 className="px-1 text-xl font-bold text-clr-simple-bg">
						Settings
					</h1>
				</div>
				<div className="flex flex-col">
					<ul className="flex s_700:flex-row flex-col">
						<li
							className={classNames(
								"text-lg  py-2 px-4 border-b cursor-pointer",
								currentTab == 1
									? "border-clr-primary-green font-semibold text-clr-primary-green"
									: "text-clr-light-gray font-medium"
							)}
							onClick={() => setCurrentTab(1)}
						>
							Personal Information
						</li>
						<li
							className={classNames(
								"text-lg  py-2 px-4 border-b cursor-pointer",
								currentTab == 2
									? "border-clr-primary-green font-semibold text-clr-primary-green"
									: "text-clr-light-gray font-medium"
							)}
							onClick={() => setCurrentTab(2)}
						>
							Change Email
						</li>
						<li
							className={classNames(
								"text-lg  py-2 px-4 border-b cursor-pointer",
								currentTab == 3
									? "border-clr-primary-green font-semibold text-clr-primary-green"
									: "text-clr-light-gray font-medium"
							)}
							onClick={() => setCurrentTab(3)}
						>
							Change Password
						</li>
					</ul>
				</div>
				{/* table */}
				<div className="flex flex-col">
					{currentTab == 1 && <PersonalForm />}

					{currentTab == 2 && <EmailForm />}

					{currentTab == 3 && <PasswordForm />}
				</div>
			</div>
		</div>
	);
}

export default Settings;
