import React, { useState } from "react";

// lib
import { BiMenuAltRight } from "react-icons/bi";

// custom
import Airplane from "../../../assets/imgs/airplane_icon.svg";
import { classNames, getLocalStorage } from "../../../utils/helpers";
import { useFlightContext } from "../../../contexts/flight_context";

function FlightSort() {
	const { flights, filters, updateFilters, openFilterModal } =
		useFlightContext();
	const [currentSort, setCurrentSort] = useState(filters.sort);

	const handleSort = (type: string) => {
		setCurrentSort(type);
		updateFilters({ ...filters, sort: type });
	};

	// get current search data
	const currentSearchData = getLocalStorage("flight_form");

	return (
		<div className="flex flex-col space-y-6">
			{/* flight numbers */}
			<div className="flex flex-row justify-between">
				<div className="flex flex-row space-x-4">
					<h1 className="text-clr-simple-bg flex flex-row items-center space-x-2">
						<img src={Airplane} alt="airline" className="w-6" />
						<span className="text-2xl text-clr-simple-bg font-bold">
							{flights.length} Flights
						</span>
					</h1>
					<p className="text-clr-simple-bg font-medium text-lg">
						{`from ${currentSearchData.Segments[0].Origin} to ${
							currentSearchData.Segments[currentSearchData.Segments.length - 1]
								.Destination
						}`}
					</p>
				</div>
				<button
					className="text-clr-primary-green s_1000:hidden flex rounded-lg"
					onClick={openFilterModal}
				>
					<BiMenuAltRight className="w-10 h-10" />
				</button>
			</div>
			{/* sort options */}
			<div className="flex flex-wrap">
				{/* price */}
				<span
					onClick={() => handleSort("price")}
					className={classNames(
						"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2 font-semibold",
						currentSort == "price"
							? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
							: "border-clr-light-gray text-clr-light-gray cursor-pointer"
					)}
				>
					<span className="text-sm font-medium">Best Price</span>
				</span>
				{/* duration */}
				<span
					onClick={() => handleSort("duration")}
					className={classNames(
						"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2 font-semibold",
						currentSort == "duration"
							? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
							: "border-clr-light-gray text-clr-light-gray cursor-pointer"
					)}
				>
					<span className="text-sm font-medium">Short Duration</span>
				</span>
				{/* departure_time */}
				<span
					onClick={() => handleSort("departure_time")}
					className={classNames(
						"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2 font-semibold",
						currentSort == "departure_time"
							? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
							: "border-clr-light-gray text-clr-light-gray cursor-pointer"
					)}
				>
					<span className="text-sm font-medium">Departure Time</span>
				</span>
				{/* arrival_time */}
				<span
					onClick={() => handleSort("arrival_time")}
					className={classNames(
						"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2 font-semibold",
						currentSort == "arrival_time"
							? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
							: "border-clr-light-gray text-clr-light-gray cursor-pointer"
					)}
				>
					<span className="text-sm font-medium">Arrival Time</span>
				</span>
			</div>
		</div>
	);
}

export default FlightSort;
