import React from "react";

// custom
import FlightPaymentCard from "./FlightPaymentCard";
import FlightPaymentGatewayCard from "./FlightPaymentGatewayCard";
import { getLocalStorage } from "../../../utils/helpers";

function FlightPayment() {
	// get current search data
	const currentSearchData = getLocalStorage("flight_form");

	// adultcards
	let adultCards: any = [];
	Array.from({ length: currentSearchData.AdultCount }).forEach(
		(inpElement, index) => {
			adultCards.push(<FlightPaymentCard type={"Adult"} level={index} />);
		}
	);
	// child cards
	let childCards: any = [];
	Array.from({ length: currentSearchData.ChildCount }).forEach(
		(inpElement, index) => {
			childCards.push(<FlightPaymentCard type={"Child"} level={index} />);
		}
	);
	// infant cards
	let infantCards: any = [];
	Array.from({ length: currentSearchData.InfantCount }).forEach(
		(inpElement, index) => {
			infantCards.push(<FlightPaymentCard type={"Infant"} level={index} />);
		}
	);

	return (
		<div className="w-full flex flex-col space-y-5">
			{adultCards}

			{childCards}

			{infantCards}

			<FlightPaymentGatewayCard />
		</div>
	);
}

export default FlightPayment;
