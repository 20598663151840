import React, { useEffect, useState } from "react";

// lib
import { FaRegUser, FaUserAlt } from "react-icons/fa";
import { toast } from "react-toastify";

// custom
import { useAuthContext } from "../../contexts/auth_context";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "../../utils/helpers";

//regex
const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

function LoginForm() {
	const { login } = useAuthContext();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [status, setstatus] = useState(false);
	const [errors, setErrors] = useState(null);
	const [showPassword, setShowPassword] = useState(false);

	const [formData, setFormData] = useState({
		email: "",
		password: "",
		remember: false,
	});

	const [formValidate, setFormValidate] = useState({
		email: false,
		password: false,
	});

	const [formFocus, setFormFocus] = useState({
		email: false,
		password: false,
	});

	const handleInput = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		if (name === "remember") {
			setFormData({ ...formData, remember: e.target.checked });
		} else {
			setFormData({ ...formData, [name]: value });
		}
		setErrors(null);
	};

	useEffect(() => {
		setFormValidate({
			...formValidate,
			email: EMAIL_REGEX.test(formData.email),
			password: PASSWORD_REGEX.test(formData.password),
		});
	}, [formData]);

	useEffect(() => {
		if (errors) {
			toast.error(errors, {
				position: "top-left",
			});
			//console.log("errors", errors);
		}
	}, [errors]);

	useEffect(() => {
		if (status) {
			navigate("/verify");
		}
	}, [status]);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setErrors(null);
		setLoading(true);
		setstatus(false);

		//console.log("form", formData);
		if (!formValidate.email || !formValidate.password) {
			toast.error(`there was an error. please fill all fields in right way.`, {
				position: "top-left",
			});
			setLoading(false);
		} else {
			//console.log("form - valid", formData);
			login({
				...formData,
				setErrors,
				setLoading,
				setstatus,
			});
		}
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className="flex flex-col items-center justify-between">
			<div className="py-20 my-28 rounded-3xl bg-white shadow-boxShadow flex flex-col space-y-3 items-center justify-center s_900:w-1/2 w-10/12 ">
				<span className="border-2 border-clr-stroke bg-clr-stroke rounded-full p-4">
					<FaUserAlt className="text-white w-6 h-6" />
				</span>
				<h1 className="capitalize s_600:text-3xl text-2xl text-center font-semibold text-clr-primary-green">
					Login to Existing User
				</h1>
				<div className="w-9/12 flex flex-col space-y-6 pt-4">
					{/* input - Email */}
					<div className="w-full relative">
						<label
							htmlFor="email"
							className="text-clr-simple-bg text-base font-medium"
						>
							Email *
						</label>
						<input
							type="text"
							id="email"
							name="email"
							value={formData.email}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={formValidate.email ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setFormFocus({ ...formFocus, email: true })}
							onBlur={() => setFormFocus({ ...formFocus, email: false })}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border border-clr-stroke placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
								formFocus.email && !formValidate.email
									? "border-red-600"
									: formFocus.email && formValidate.email
									? "border-green-600"
									: "border-clr-stroke"
							)}
							placeholder="Email"
						/>
						<div
							id="uidnote"
							className={
								formFocus.email && formData.email && !formValidate.email
									? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
									: "hidden"
							}
						>
							Email not valid
						</div>
					</div>
					{/* input - Password */}
					<div className="w-full relative">
						<label
							htmlFor="password"
							className="text-clr-simple-bg text-base font-medium"
						>
							Passowrd *
						</label>
						<input
							type={showPassword ? "text" : "password"}
							id="password"
							name="password"
							value={formData.password}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={formValidate.password ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setFormFocus({ ...formFocus, password: true })}
							onBlur={() => setFormFocus({ ...formFocus, password: false })}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
								formFocus.password && !formValidate.password
									? "border-red-600"
									: formFocus.password && formValidate.password
									? "border-green-600"
									: "border-clr-stroke"
							)}
							placeholder="Password"
						/>
						<button
							className="absolute top-10 right-5 text-clr-gray hover:underline text-sm"
							type="button"
							onClick={() => setShowPassword(!showPassword)}
						>
							{showPassword ? "hide" : "show"}
						</button>
						<div
							id="uidnote"
							className={
								formFocus.password &&
								formData.password &&
								!formValidate.password
									? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
									: "hidden"
							}
						>
							Your Password weak must have @-#-$-% /Capital letter/numbers
						</div>
					</div>
					{/* remmber & forget */}
					<div className="flex s_600:flex-row flex-col items-center justify-between">
						{/* input - agree */}
						<div className="flex items-center ">
							<input
								id="remember"
								name="remember"
								onChange={handleInput}
								aria-describedby="checkbox-1"
								type="checkbox"
								className="rounded w-4 h-4 bg-clr-stroke border-clr-stroke accent-clr-primary-green focus:ring-0 focus:ring-clr-stroke focus:bg-clr-primary-green hover:bg-clr-primary-green  checked:bg-clr-primary-green checked:border-clr-primary-green"
							/>
							<label
								htmlFor="remember"
								className="text-base ml-1 font-medium text-clr-light-gray"
							>
								Remember Me
							</label>
						</div>
						<div>
							<Link
								to={"/forget-password"}
								className="capitalize text-lg font-medium text-clr-primary-green"
							>
								forget password ?
							</Link>
						</div>
					</div>
					{/* btn */}
					<div className="flex flex-row items-center justify-center space-x-4 pb-3">
						<button
							onClick={handleSubmit}
							className="relative bg-clr-primary-green w-1/3 rounded-lg py-3 text-3xl font-bold text-white capitalize border-2 border-clr-primary-green hover:bg-transparent hover:text-clr-primary-green"
						>
							<span className="flex flex-row items-center justify-center space-x-2  text-clr-main-dark tracking-wider font-medium lg:text-lg md:text-base text-sm">
								<span>Login</span>
								{loading && (
									<svg
										aria-hidden="true"
										role="status"
										className="inline w-4 h-4 mr-3 text-clr-main-dark font-semibold animate-spin"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="#E5E7EB"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentColor"
										/>
									</svg>
								)}
							</span>
						</button>
					</div>
				</div>
				<p className="text-base font-medium text-clr-light-gray text-center">
					Don't have an account?{" "}
					<Link className="text-clr-primary-green font-bold" to={"/register"}>
						Create account
					</Link>
				</p>
			</div>
		</div>
	);
}

export default LoginForm;
