import React, { useState } from "react";

// lib
import { IoIosArrowDown } from "react-icons/io";
import { MdLuggage } from "react-icons/md";
import { BiTimeFive } from "react-icons/bi";
import { format, addDays } from "date-fns";
import { useNavigate } from "react-router-dom";

//custom
import h_plan from "../../../assets/imgs/h_plane.svg";
import v_plan from "../../../assets/imgs/v_plane.svg";
import point from "../../../assets/imgs/point.svg";
import dateTime from "../../../assets/imgs/dateTime.svg";
import {
	classNames,
	getDuration,
	getLocalStorage,
	getTextStopByCountforShowing,
} from "../../../utils/helpers";
import { useFlightContext } from "../../../contexts/flight_context";
import { useMainContext } from "../../../contexts/main_context";

function FlightCard({ data }: any) {
	const navigate = useNavigate();
	const [tripTab, setTripTab] = useState(0);
	const [showDetail, setShowDetails] = useState(false);
	const { fetchsingleFlight } = useFlightContext();
	const { setPrevPage } = useMainContext();

	// set selected flight
	const handleSelectedFlight = () => {
		setPrevPage("/flights-booking");
		fetchsingleFlight(data.ResultToken);
		navigate("/flights-booking");
	};

	// get current search data
	const currentSearchData = getLocalStorage("flight_form");

	//collect date
	const flightTripsNames = data.FlightDetails.Details.map((item: any) => {
		const firstStop = item[0];
		const lastStop = item[item.length - 1];
		return (
			firstStop.Origin.CityName +
			" (" +
			firstStop.Origin.AirportCode +
			") - " +
			lastStop.Destination.CityName +
			" (" +
			lastStop.Destination.AirportCode +
			")"
		);
	});

	const firstOrigin = data.FlightDetails.Details[0][0].Origin.AirportCode;
	const firstOriginDate = data.FlightDetails.Details[0][0].Origin.DateTime;
	const FirstTrip = data.FlightDetails.Details[0];
	const firstDistination =
		FirstTrip[FirstTrip.length - 1].Destination.AirportCode;
	const firstDistinationDate =
		FirstTrip[FirstTrip.length - 1].Destination.DateTime;
	let total = 0;
	const fullDuration = data.FlightDetails.Details[0].reduce(
		(next: any, item: any) => {
			const { Duration, LayOverTime } = item;
			return (total +=
				parseInt(Duration) + parseInt(LayOverTime ? LayOverTime : 0));
		},
		{ total: 0 }
	);

	return (
		<div className="flex flex-col space-y-5 s_600:p-8 p-3 bg-white s_600:shadow-cardShadow shadow-boxShadow rounded-lg">
			{/* hint */}
			<div className="flex flex-col space-y-3 border-b pb-5">
				{/* airline & price */}
				<div className="flex flex-row items-center justify-between">
					<h3 className="text-lg text-clr-simple-bg font-semibold">
						{data.FlightDetails?.Details[0][0]?.OperatorName}
					</h3>
					<p className="text-2xl font-bold text-clr-simple-bg">
						{`$${data.Price.TotalDisplayFare}`}
					</p>
				</div>
				{/* from & to */}
				<div className="flex s_700:flex-row flex-col items-center justify-between">
					<div className="flex flex-col space-y-2 w-[75%]">
						{/* cities */}
						<div className="flex flex-row items-center justify-between">
							<span className="text-clr-simple-bg text-lg font-bold">
								{firstOrigin}
							</span>
							<p className="relative flex flex-row justify-between w-[70%]">
								<span className="absolute top-2  border-2 border-dashed text-clr-primary-gray  w-full"></span>
								<img src={point} alt="point" className="z-10" />
								<img src={h_plan} alt="point" className="z-10" />
								<img src={point} alt="point" className="z-10" />
							</p>
							<span className="text-clr-simple-bg text-lg font-bold">
								{firstDistination}
							</span>
						</div>
						{/* date */}
						<div className="flex flex-row items-start justify-between">
							<span className="text-clr-primary-gray text-lg font-medium">
								{format(new Date(firstOriginDate), "dd MMM")}
							</span>
							<p className="relative flex flex-col items-center justify-start">
								<span className="">{getDuration(fullDuration, true)}</span>
								<span className="text-clr-primary-green">{`(${getTextStopByCountforShowing(
									FirstTrip.length
								)})`}</span>
							</p>
							<span className="text-clr-primary-gray text-lg font-medium">
								{format(new Date(firstDistinationDate), "dd MMM")}
							</span>
						</div>
						{/* time */}
						<div className="flex flex-row items-start justify-between">
							<span className="text-clr-primary-gray text-lg font-medium">
								{format(new Date(firstOriginDate), "HH:mm")}
							</span>
							<span className="text-clr-primary-gray text-lg font-medium">
								{format(new Date(firstDistinationDate), "HH:mm")}
							</span>
						</div>
					</div>
					<button
						className="bg-clr-primary-green text-white text-base font-medium py-2 px-4 rounded-lg shadow-btnShadow"
						onClick={handleSelectedFlight}
					>
						Select Flight
					</button>
				</div>
			</div>
			{/* details */}
			<div
				className={classNames(
					" flex-col space-y-6 transition-all ease-in-out duration-700",
					showDetail ? "flex" : "hidden"
				)}
			>
				<ul className="flex s_600:flex-row flex-col items-center justify-start border-b w-[80%]">
					{flightTripsNames.map((item: any, index: number) => {
						return (
							<li
								key={index}
								onClick={() => setTripTab(index)}
								className={classNames(
									"py-2 px-4 text-sm cursor-pointer",
									tripTab == index
										? "text-clr-primary-green font-semibold border-b border-clr-primary-green"
										: "text-clr-light-gray font-medium"
								)}
							>
								{item}
							</li>
						);
					})}
				</ul>
				<div className="flex flex-col space-y-14">
					{data.FlightDetails.Details[tripTab].map((item: any) => {
						// stop detail
						return (
							<div className="flex flex-col space-y-3" key={item.FlightNumber}>
								{/* date */}
								<div className="flex flex-row space-x-s">
									<img src={dateTime} alt="dateTime" className="w-10 h-10" />
									<p className="flex flex-col space-y-0">
										<span className="text-base text-clr-primary-gray font-medium">
											{format(new Date(item.Origin.DateTime), "dd MMM yyyy")}
										</span>
										<span className="text-sm text-clr-light-gray font-normal">
											{format(new Date(item.Origin.DateTime), "EEEE")}
										</span>
									</p>
								</div>
								{/* stop info */}
								<div className="flex s_600:flex-row flex-col items-center justify-between">
									{/* from & to */}
									<div className="flex flex-row space-x-4">
										{/* texts */}
										<div className="flex flex-col space-y-10 relative">
											<span className="absolute left-2 border-2 border-dashed text-clr-primary-gray h-[68%] top-12"></span>
											<div className="flex flex-row space-x-2">
												<img src={point} alt="point" className="z-10 w-5 h-5" />
												<p className="flex flex-row space-x-1">
													<span className="text-base text-clr-simple-bg font-semibold">
														{`${format(
															new Date(item.Origin.DateTime),
															"HH:mm"
														)} ${item.Origin.CityName} `}
													</span>
													<span className="text-base text-clr-simple-bg font-medium">
														{`${item.Origin.AirportName} (${item.Origin.AirportCode})`}
													</span>
												</p>
											</div>
											<div className="flex flex-row space-x-2">
												<img
													src={v_plan}
													alt="point"
													className="z-10 w-8 h-8 -ml-[6px]"
												/>
												<p className="flex flex-col space-y-0">
													<span className="text-base font-semibold text-clr-simple-bg">
														{item.OperatorName}
													</span>
													<span className="flex flex-row space-x-1 text-base text-clr-simple-bg font-medium">
														<span>Travel time: </span>
														<span className="text-clr-primary-green">
															{getDuration(item.Duration, true)}
														</span>
													</span>
												</p>
											</div>
											<div className="flex flex-row space-x-2">
												<img src={point} alt="point" className="z-10 w-5 h-5" />
												<p className="flex flex-row space-x-1">
													<span className="text-base text-clr-simple-bg font-semibold">
														{`${format(
															new Date(item.Destination.DateTime),
															"HH:mm"
														)} ${item.Destination.CityName} `}
													</span>
													<span className="text-base text-clr-simple-bg font-medium">
														{`${item.Destination.AirportName} (${item.Destination.AirportCode})`}
													</span>
												</p>
											</div>
										</div>
									</div>
									{/* flight info */}
									<div className="flex flex-col space-y-4">
										<h2 className="text-clr-simple-bg font-semibold text-lg">
											Flight Details
										</h2>
										<p className="flex flex-row items-center justify-start space-x-1 ml-1">
											<span className="text-clr-primary-gray text-sm font-medium">
												Operated by:{" "}
											</span>
											<span className="text-clr-simple-bg text-sm font-semibold">
												{item.OperatorName}
											</span>
										</p>

										<p className="flex flex-row items-center justify-start space-x-1 ml-1">
											<span className="text-clr-primary-gray text-sm font-medium">
												Flight no:{" "}
											</span>
											<span className="text-clr-simple-bg text-sm font-semibold">
												{item.FlightNumber}
											</span>
										</p>

										<p className="flex flex-row items-center justify-start space-x-1 ml-1">
											<span className="text-clr-primary-gray text-sm font-medium">
												Cabin:{" "}
											</span>
											<span className="text-clr-simple-bg text-sm font-semibold">
												{currentSearchData.CabinClass}
											</span>
										</p>

										<p className="flex flex-row items-center justify-start space-x-1 ml-1">
											<span className="text-clr-primary-gray text-sm font-medium">
												Fare type:{" "}
											</span>
											<span className="text-clr-simple-bg text-sm font-semibold">
												{currentSearchData.JourneyType}
											</span>
										</p>
									</div>
								</div>
								{/* transit */}
								{item.LayOverTime && (
									<div className="flex flex-row items-center space-x-2 py-2 px-8 rounded-md bg-clr-stroke">
										<BiTimeFive className="text-clr-primary-green w-5 h-5" />
										<span className="text-base text-clr-simple-bg font-medium">
											{`${getDuration(item.LayOverTime, true)} layover - ${
												item.Destination.AirportName
											} (${item.Destination.AirportCode})`}
										</span>
									</div>
								)}

								{!item.LayOverTime && (
									<div className="flex flex-col space-y-0">
										<span className="text-base text-clr-simple-bg font-medium">
											Arrived at Destination
										</span>
										<span className="text-lg text-clr-simple-bg font-semibold">
											{item.Destination.AirportName}
										</span>
									</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
			{/* footer */}
			<div className="flex s_600:flex-row flex-col items-center justify-between">
				{/* class & baggage */}
				<div className="flex flex-row items-center justify-center space-x-2">
					<p className="text-sm text-clr-simple-bg font-semibold bg-clr-stroke rounded-md py-2 px-4">
						{currentSearchData.CabinClass}
					</p>
					<p className="text-sm text-clr-simple-bg font-semibold bg-clr-stroke rounded-md py-2 px-4 flex flex-row space-x-1 items-center justify-center">
						<MdLuggage className="text-clr-primary-green w-4 h-4" />
						<span className="">{`${data.FlightDetails.Details[0][0].Attr.CabinBaggage} bag included`}</span>
					</p>
				</div>
				{/* btn */}
				<button
					className="flex flex-row items-center justify-center space-x-1"
					onClick={() => setShowDetails(!showDetail)}
				>
					<span className="text-clr-primary-green text-sm font-medium">
						Show details
					</span>
					<IoIosArrowDown className="text-clr-primary-green" />
				</button>
			</div>
		</div>
	);
}

export default FlightCard;
