import React, { useEffect, useState } from "react";

// custom
import { useMainContext } from "../../contexts/main_context";
import { Subscribe } from "../../components/Home";

function FAQsPage() {
	const { changeTheme } = useMainContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "transparent",
			footerShow: true,
		});
	}, []);

	return (
		<main className="">
			<div className="page max-w-custom-full w-smallWidth mx-auto">
				<div className="flex flex-col items-center justify-center">
					<h1 className="text-clr-simple-bg text-4xl font-bold text-center py-12">
						FAQs
					</h1>
					<div className="py-10 rounded-md shadow-boxShadow w-full mb-10 flex flex-col space-y-8">
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-[95%] mx-auto">
							<h2 className="text-clr-simple-bg font-semibold text-xl">
								1 - What flights will I be able to book on Ticks’nTravel?
							</h2>
							<p className="mt-2 ml-5 w-[90%]  text-clr-primary-gray">
								You can book any international flights to over 150+ destinations
								worldwide via all international airline providers and budget
								airline providers, domestic flights and also book private or
								charter flights for your urgent and top priority personal or
								business travel needs.
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-[95%] mx-auto">
							<h2 className="text-clr-simple-bg font-semibold text-xl">
								2 - What benefits will I get from booking with Ticks’nTravel?
							</h2>
							<p className="mt-2 ml-5 w-[90%]  text-clr-primary-gray">
								You will be able to plan and book your whole travel itinerary
								via Ticks’nTravel, from your personal holiday or business travel
								or both combined. Select our curated packages destination wise,
								or select your own accommodation for multiple destinations,
								private flights for select destinations and car rentals
								worldwide. We have also onboarded exclusive travel partners
								across select airlines, hotels and rentals where you will be
								eligible for exclusive discounts.
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-[95%] mx-auto">
							<h2 className="text-clr-simple-bg font-semibold text-xl">
								3 - What payment methods are available?
							</h2>
							<p className="mt-2 ml-5 w-[90%]  text-clr-primary-gray">
								You can pay exclusively via ToLcoin at the payment gateway.
								Payments via debit/credit cards, bank transfers and even cash
								payments will be introduced soon.
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-[95%] mx-auto">
							<h2 className="text-clr-simple-bg font-semibold text-xl">
								4 - How do I get cash back rewards and how do I redeem them?
							</h2>
							<p className="mt-2 ml-5 w-[90%]  text-clr-primary-gray">
								When you pay via ToLcoin, you will be eligible for exclusive
								cash back rewards via ToL. These rewards can be viewed through
								your profile and the wallet, shown as ‘My Cashback’. You can
								redeem these limited time rewards on any future bookings made
								via the platform.
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-[95%] mx-auto">
							<h2 className="text-clr-simple-bg font-semibold text-xl">
								5 - How do I cancel or modify my booking?
							</h2>
							<p className="mt-2 ml-5 w-[90%]  text-clr-primary-gray">
								Cancellations and modifications are only allowed up to 48 hours
								prior to your flight or check-in time. Select your booking and
								click cancel. You can also cancel certain parts of your booking,
								if you have multiple flights, hotel bookings or car rentals in
								your booking. You can also email us via{" "}
								<a href="mailto:bookings@ticksntravel.com">
									bookings@ticksntravel.com
								</a>{" "}
								and our team will assist you further.
							</p>
						</div>
						{/* sub section */}
						<div className="flex flex-col space-y-2 w-[95%] mx-auto">
							<h2 className="text-clr-simple-bg font-semibold text-xl">
								6 - How do I know if my booking has been processed and the
								payment has gone through?
							</h2>
							<p className="mt-2 ml-5 w-[90%]  text-clr-primary-gray">
								After you confirm your booking and make the payment through
								ToLcoin, you will get a confirmation on the app or website and a
								confirmation to your email that your booking has been
								successful.
							</p>
						</div>
					</div>
				</div>
			</div>
			<Subscribe />
		</main>
	);
}

export default FAQsPage;
