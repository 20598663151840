import React, { useEffect } from "react";

//custom
import VerifyForm from "../../components/Verify/VerifyForm";
import { useMainContext } from "../../contexts/main_context";

function VerifyPage() {
	const { changeTheme } = useMainContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: false,
			navbg: "white",
			footerShow: false,
		});
	}, []);

	return (
		<div className="bg-clr-simple-bg bg-center s_900:h-screen h-auto w-full">
			<div className="max-w-max-custom w-smallWidth mx-auto flex flex-col relative items-center justify-center h-screen">
				<VerifyForm />
			</div>
		</div>
	);
}

export default VerifyPage;
