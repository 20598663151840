import React from "react";

// lib
import { AiFillSetting, AiOutlineUserDelete } from "react-icons/ai";
import { RiLogoutBoxFill } from "react-icons/ri";
import { BsFillFileTextFill } from "react-icons/bs";
import { IoWalletSharp } from "react-icons/io5";

// custom
import { classNames } from "../../utils/helpers";
import { useAuthContext } from "../../contexts/auth_context";
import { Link } from "react-router-dom";

function ProfileAside({ isActive, setActive }: any) {
	const { logout } = useAuthContext();
	return (
		<section className=" w-full">
			<div className=" bg-white shadow-listShadow rounded-lg py-2 w-full max-w-full overflow-x-auto">
				<div className="flex flex-wrap items-center justify-between border-b-2 border-clr-primary-green py-2 px-4">
					<h2 className="text-lg font-semibold text-clr-primary-green">
						Dashboard
					</h2>
				</div>
				<div className="flex flex-col w-full">
					<ul className="flex s_900:flex-col flex-row">
						{/* booking */}
						<li
							className={classNames(
								"flex flex-row space-x-1 py-2 px-2 s_900:border-b border-none hover:bg-clr-stroke text-clr-primary-gray font-semibold text-lg cursor-pointer",
								isActive == 1 ? "bg-clr-stroke" : ""
							)}
							onClick={() => setActive(1)}
						>
							<BsFillFileTextFill className="text-clr-primary-green w-6 h-6" />
							<span>Booking</span>
						</li>
						{/* wallet */}
						<li
							className={classNames(
								"flex flex-row space-x-1 py-2 px-2 s_900:border-b border-none hover:bg-clr-stroke text-clr-primary-gray font-semibold text-lg cursor-pointer",
								isActive == 2 ? "bg-clr-stroke" : ""
							)}
							onClick={() => setActive(2)}
						>
							<IoWalletSharp className="text-clr-primary-green w-6 h-6" />
							<span>wallet</span>
						</li>
						{/* settings */}
						<li
							className={classNames(
								"flex flex-row space-x-1 py-2 px-2 bs_900:border-b border-none hover:bg-clr-stroke text-clr-primary-gray font-semibold text-lg cursor-pointer",
								isActive == 3 ? "bg-clr-stroke" : ""
							)}
							onClick={() => setActive(3)}
						>
							<AiFillSetting className="text-clr-primary-green w-6 h-6" />
							<span>Settings</span>
						</li>

						{/* logout */}
						<li
							className={classNames(
								"flex flex-row space-x-1 py-2 px-2  hover:bg-clr-stroke text-clr-primary-gray font-semibold text-lg cursor-pointer",
								isActive == 5 ? "bg-clr-stroke" : ""
							)}
							onClick={logout}
						>
							<RiLogoutBoxFill className="text-clr-primary-green w-6 h-6" />
							<span>Logout</span>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
}

export default ProfileAside;
