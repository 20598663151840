import React from "react";

// custom
import Airplane from "../../assets/imgs/airplane_icon.svg";
import spot_d from "../../assets/imgs/g_spot_d.svg";
import spot_u from "../../assets/imgs/g_spot_u.svg";
import { experiences } from "../../utils/constants";

function Experiences() {
	return (
		<div className="relative bg-home_bg_experiences bg-cover bg-no-repeat  bg-center pt-10 pb-20 flex flex-col items-center justify-center">
			<img src={spot_u} alt="spot" className="absolute top-20 right-20 -z-10" />
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col space-y-10 mt-10">
				{/* desc */}
				<div className="flex flex-col items-center justify-center space-y-6">
					<h1 className="text-clr-simple-bg font-bold md:text-6xl text-4xl flex flex-row items-start space-x-0 w-[60%] md:leading-[4.2rem] text-center">
						<img src={Airplane} alt="airline" className="mr-[2px] w-20" />
						Reimagined for the next-gen traveler.
					</h1>
				</div>
				{/* columns */}
				<div className="grid s_900:grid-cols-5 s_700:grid-cols-4 s_500:grid-cols-3 s_430:grid-cols-2 grid-cols-1 gap-x-5 gap-y-14">
					{experiences.map((item: any) => {
						return (
							<div
								key={item.id}
								className="flex flex-col items-center justify-center space-y-8 h-[170px]"
							>
								<span className="p-4 bg-clr-stroke rounded-full">
									<img src={item.icon} alt={item.title} />
								</span>
								<div className="flex flex-col items-center justify-center h-[100px]">
									<h2 className="font-bold text-clr-simple-bg text-xl text-center">
										{item.title}
									</h2>
									<p className="text-clr-primary-gray text-base font-normal text-center">
										{item.text}
									</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<img
				src={spot_d}
				alt="spot"
				className="absolute bottom-10 left-20 -z-10"
			/>
		</div>
	);
}

export default Experiences;
