import React, { useEffect, useState } from "react";

// custom
import { useMainContext } from "../../contexts/main_context";
import { Subscribe } from "../../components/Home";

function TermsPage() {
	const { changeTheme } = useMainContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "transparent",
			footerShow: true,
		});
	}, []);

	return (
		<main className="">
			<div className="page max-w-custom-full w-smallWidth mx-auto">
				<div className="flex flex-col items-center justify-center">
					<h1 className="text-clr-simple-bg text-4xl font-bold text-center py-12">
						Terms and Conditions
					</h1>
					<div className="py-10 rounded-md shadow-boxShadow w-full mb-10 flex flex-col space-y-8">
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<p className="text-clr-primary-gray text-base font-medium">
								These Terms and Conditions (the “Terms”) govern the legally
								binding relationship between us when you use our Website and the
								Services that we provide. By using the Website or Services, you
								acknowledge that you understand these Terms and agree to be
								legally bound by them.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								In these Terms, references to “us”, “we” or “our” shall mean
								Phydias SA, a company registered in Switzerland with its
								registered address at rue des Pâquis 8, 1202 Genève (henceforth
								“ticksntravel.com”). When we refer to "you", we mean any person
								that accesses or uses the Services.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Bookings made via our Website, or with our employees over the
								phone, via email, or via any other method of communication, are
								subject to these Terms. One person making the Booking on behalf
								of other travelers is taken to have the authority to agree to
								these Terms on behalf of any other travelers.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								1. Definitions
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								In these Terms:
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								1.1 Any headings are for reference purposes only and shall not
								aid in the interpretation of the clauses to which they relate.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								1.2 Unless the context clearly indicates a contrary intention,
								use of any gender includes the other genders, the singular
								includes the plural and vice versa, and natural persons include
								legal entities and vice versa.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								1.3 The following terms shall have the meanings assigned to them
								hereunder, namely:
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Accommodation Provider: means the provider of a hotel, home,
								accommodation, or other place of lodging;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Activity Provider: means the provider of any tours, activities,
								or experiences;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Additional Services: means a service related to your Booking
								provided by a Service Provider, including (without limitation)
								upgrades, incidental charges, additional luggage, priority
								seating, meals, mini-bar, dry cleaning and laundry, passenger
								information change, rebooking, or cancellation. The fees
								associated with the provision of Additional Services are set by
								the Service Provider and/or other third party and are not
								included in the Total Price, except where the requested
								Additional Services are added by you to your Booking with us
								during the Booking process, which may incur an additional
								handling fee charged by us that will be displayed to you at the
								time of your Booking. Where any Additional Services are sought
								by you following your completed purchase of a Booking, you
								acknowledge that these arrangements are your sole responsibility
								and that you will be required to directly contact the Service
								Provider or any other third party as required to fulfill your
								request for the Additional Services;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Booking: means the process of purchasing a selected hotel,
								flight, or activity (including any multiple of these) and the
								resulting outcome of this process. A Booking is completed when
								we confirm that we have received and accepted your payment for
								the Total Price;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Carrier: means the airline providing the services of carriage by
								air. When placing a Booking, the identity of the Carrier
								(including the identity of the Carriers of any connecting
								flights) for your Booking will be made known to you. Please be
								aware that, due to circumstances beyond our control, the
								operating Carrier of your flight may be different from the one
								displayed at the time of your Booking. As such, it is important
								that you verify the identity of your operating Carrier prior to
								your departure;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Service Provider: means the Carrier, Accommodation Provider, or
								Activity Provider responsible for delivering the service(s)
								purchased as part of a Booking. For the avoidance of doubt,
								where a Booking involves multiple Service Providers, each
								Service Provider is to be taken as a separate entity responsible
								only for the provision of their respective part of the services
								under the Booking;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Services: means the Booking services provided by us at the
								Website in accordance with these Terms that enables you to
								purchase Travel Products. The Services we provide are further
								detailed in Section 2 of these Terms;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Total Price: means the final price displayed immediately prior
								to the purchase of your Booking, including any Additional
								Services selected by you (e.g. additional luggage, upgrades,
								meals, etc.) during the Booking process;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Travel Document: means any physical or digital document that is
								intended to confirm a contract that we have arranged on your
								behalf with a Service Provider. These documents may be, but are
								not limited to, hotel vouchers, airline itineraries/e-tickets,
								and activity tickets;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Travel Product: means the products, provided by Service
								Providers that we offer on behalf of the Service Providers on
								our Website, namely accommodation, flights, activities, and any
								Additional Services purchased during your Booking;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Ticks’nTravel Account: means the registered account used to log
								in to the Website;
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Ticks’nTravel Wallet: means the wallet associated with your
								Account on the Website used to store your deposited funds or
								funds otherwise acquired by you; and
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Website: means the website located at ticksntravel.com and all
								associated subdomains at this location.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								2. Services
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								2.1 This section sets forth the contractual relationship between
								you and us on the basis of which we will provide you with the
								Services related to the Travel Products that we sell to you in
								exchange for the Total Price. The following are the Services
								that we provide to you:
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>
									Displaying information and pricing of Travel Products on the
									Website;
								</li>
								<li>
									Provision of Website functions that enable you to make a
									Booking and purchase Travel Products for the Total Price;
								</li>
								<li>
									Facilitation of the agreement between the relevant Service
									Provider and you; and
								</li>
								<li>
									Delivery of your Travel Documents resulting from your Booking.
								</li>
							</ul>
							<p className="text-clr-primary-gray text-base font-medium">
								2.2 You agree to be bound by these Terms when you complete and
								send us your online order on the Website in relation to your
								Booking and when we subsequently confirm the acceptance of your
								payment. Where your Booking is completed via any other method
								other than through the Website (e.g. where one of our employees
								places the order on your behalf following email correspondence),
								these Terms will be entered into on the basis of your explicit
								and binding offer to purchase a Booking following the provision
								of all relevant information in relation to your Booking by us to
								you.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								2.3 By purchasing the Booking, you express and warrant that you
								have the legal capacity to enter into and be bound by these
								Terms, and that you have read and understood the terms and
								conditions and any other relevant materials of the relevant
								Service Provider(s).
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								2.4 You acknowledge that the information you provide when
								placing a Booking is accurate and up to date, and you agree that
								we shall bear no liability for any inaccurate information
								provided (except where the inaccuracy or error has resulted due
								to a fault on our part). It is your responsibility to ensure
								that any information provided by you to us during the Booking
								process, including, without limitation, passenger details,
								travel dates, departure point and destination, is correct and
								that any documents you receive from us in relation to your
								Booking are in order.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								2.5 If you wish to derive the benefits associated with a
								particular frequent flyer program when purchasing a Booking for
								a flight with a Carrier, you acknowledge that it is your
								responsibility to provide us with your frequent flyer number,
								either during the Booking process in the relevant section of the
								Booking form on the Website or via correspondence with us at any
								time before your departure. Please note that we will not be
								liable for any foregone frequent flyer benefits under any
								circumstances
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								2.6 Please note that these Terms are separate from any terms,
								conditions, or agreements that you enter into with the Service
								Provider(s) or any other third party responsible for the
								fulfillment of your Travel Product. By proceeding with your
								Booking, you acknowledge that these are separate, mutually
								independent legal relationships conducted with different
								parties.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								2.7 We reserve the right to change or remove any Travel Products
								advertised on the Website without notice, including, but not
								limited to, where the Service Provider’s offer is changed or
								removed, or where a computer malfunction or human error has
								occurred. This includes prices quoted to you via email or any
								other method of correspondence. Our Website will subsequently be
								updated accordingly to reflect the accurate availability,
								information, and price of the Travel Product(s). In some cases,
								the price of a Travel Product may change between the time that
								you select the Travel Product and the time when you attempt to
								complete the purchase of your Booking. If a change of this
								nature occurs, we will take reasonable measures to ensure that
								you are aware of this change before you purchase the Booking.
								Please note that we are under no obligation to provide you with
								a reimbursement or refund of any kind if the price of a Travel
								Product becomes lower following the successful completion of
								your Booking.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								3. Ticks'nTravel Account
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								3.1 In order to use some aspects of the Services, including to
								successfully purchase a Booking, you will be required to create
								a Ticks'nTravel Account. As part of the registration process,
								you will need to provide certain information that enables us to
								create your Ticks'nTravel Account. You acknowledge that it is
								your responsibility to ensure the confidentiality of your
								Ticks'nTravel Account login credentials, including your
								password, and that you will be solely responsible for any
								actions conducted on your Ticks'nTravel Account (including any
								Bookings). You agree to notify us immediately of any
								unauthorized use of your Ticks'nTravel Account.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								3.2 We may terminate or suspend access to your Ticks'nTravel
								Account or your ability to use the Services, in whole or in
								part, for any reason at our sole discretion and without notice
								or liability of any kind. Any such termination or suspension
								could prevent you from accessing your Ticks'nTravel Account
								temporarily or permanently.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								3.3 You may opt to terminate your Ticks'nTravel Account at any
								time. By terminating your Ticks'nTravel Account, you agree to
								discontinue your use of any and all parts of the Services. To
								terminate your Ticks'nTravel Account, please contact us at{" "}
								<a href="mailto:support@ticksntravel.com" className="font-bold">
									support@ticksntravel.com
								</a>
								.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								4. Travel Requirements
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								4.1 Our staff may be able to assist you with information
								pertaining to passports, visas, and other travel documents you
								may require for your trip. Please be aware that, while we can
								assist in providing this information to you, the information
								that we provide is general in nature and it is ultimately your
								responsibility to ensure that you have the relevant documents
								required for your trip. Any penalties, fines or additional
								expenses incurred as a result of the failure to obtain these
								documents will be incurred solely by you (subject to our
								liability).
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								4.2 Any customer who successfully purchases a Booking for a
								flight with a Carrier must have a valid passport. We assume that
								a valid passport is held by every person included in the
								Booking. If any of the travelers do not have a valid passport,
								you must contact us via email on{" "}
								<a href="mailto:support@ticksntravel.com">
									support@ticksntravel.com
								</a>{" "}
								immediately. Please be aware that, in addition to holding a
								valid passport, your passport is required by many countries to
								be valid for at least six (6) months from the date of return,
								and some countries may require passports to be machine-readable.
								In addition to ensuring that all parties in the Booking have a
								valid passport, all parties must also obtain visas and re-entry
								permits that satisfy immigration requirements and any other
								relevant laws, including for countries that you may only be
								transiting through.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								4.3 If you have any special requirements, such as dietary
								requirements, seating arrangements, disabled access, or any
								other special requests, please make these requests during the
								Booking process (if available) or contact us via email on{" "}
								<a href="mailto:support@ticksntravel.com">
									support@ticksntravel.com
								</a>{" "}
								immediately following the purchase of your Booking to allow as
								much time as possible for your requirements to be met. Please
								note that, while we will endeavor to accommodate your
								request(s), we cannot guarantee that your request(s) will be
								honored by the Service Provider.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								5. Travel Documents
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								5.1 Your Travel Document(s) will be delivered to you
								electronically to the email address provided by you at the time
								of your Booking. You acknowledge that it is your responsibility
								to ensure that the email address you provide is entered
								correctly and that you will immediately notify us where an error
								has been made on your part. We shall not be liable for any delay
								or failure to deliver your Travel Document(s) where the delay or
								failure was made on your part. The Service Provider or other
								relevant authorities may require you to present any of the
								following documents in either electronic or printed format: your
								itinerary/e-ticket(s), boarding pass(es), visa(s), passport, or
								any other relevant document.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								5.2 Certain conditions or restrictions may be imposed on Travel
								Documents, such as being non-refundable or subject to amendment
								or cancellation fees. Please ensure that you carefully read each
								Travel Document upon its receipt to ensure that it is in order.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								5.3 Travel Documents are non-transferable. Where a Booking for a
								flight with a Carrier is purchased, the Travel Document must be
								issued in the name of the passport/photo identity holder. Please
								ensure that the details on your Booking are correct and confirm
								that the name, date of birth, and document number of all
								travelers is correct. If there is a mistake on your Travel
								Document, such as an incorrect name or date of birth, please
								contact us via email on{" "}
								<a href="mailto:support@ticksntravel.com">
									support@ticksntravel.com
								</a>{" "}
								immediately.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								5.4 Any health requirements or precautions associated with your
								trip are your responsibility. Please ensure that you have any
								relevant vaccination documentation if you are traveling to a
								country requiring proof of vaccination. It is also generally
								good practice to visit your general practitioner or vaccination
								clinic before your trip to see if any vaccinations are required
								for your trip.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								6. Travel Insurance and Travel Advice
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								6.1 Unforeseen circumstances may arise when traveling. For this
								reason, it is recommended that you consider whether you require
								travel insurance, especially for international travel. It is
								recommended that your travel insurance cover medical expenses,
								personal injury, death, loss of luggage, loss of money,
								cancellation, and personal liability insurance. Please note that
								we do not sell travel insurance, though we may be able to
								provide you with general information regarding travel insurance.
								We shall bear no liability for the general information that we
								provide or for your purchase or non-purchase of travel
								insurance.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								6.2 General travel advice and country-specific travel advice is
								provided by most countries. While we may be able to provide
								general information to you in relation to country-specific
								travel advice, we make no representations as to the accuracy of
								this information and shall bear no liability for any resulting
								damages. By purchasing a Booking, we assume that your country of
								departure permits you to visit the destination of your Booking
								or any transits in between. We shall bear no liability for any
								cancellation fees or other fees that you may incur as a result
								of your inability to use your Booking.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								7. Payments
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								7.1 Your price will only be guaranteed once the Total Price for
								your Booking has been paid in full. Please note that taxes are
								subject to change and the tax is finalized when your Travel
								Document is issued. You also acknowledge that ticksntravel.com
								is not subject to VAT, and therefore no VAT invoice will be
								issued to you following the successful completion of your
								Booking.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.2 We accept payment via ToLcoin, other selected
								cryptocurrencies, selected credit and debit cards, bank wire
								transfers, and other methods (collectively “Payment Method”).
								Please see our Payment Methods for an up-to-date list of current
								options.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.3 Cryptocurrency Payments. A Booking purchased with
								cryptocurrency will only be ticketed once the transaction has
								received a minimum of three (3) confirmations on the relevant
								blockchain. Bookings purchased with cryptocurrency are generally
								subject to network fees, which are received by network operators
								(or nodes) that confirm your transaction on the blockchain, not
								ticksntravel.com. The network fees applicable to your Booking
								will depend on network conditions and the network fee selected
								by you at the time of the cryptocurrency transaction. Please
								further take note of our cryptocurrency refund policy in clause
								8.6 herein.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.4 Credit and Debit Cards. By agreeing to these Terms, you
								authorize us to charge your designated credit or debit card for
								all fees incurred by you in relation to the Services provided by
								us. Please note that a surcharge may be incurred on credit and
								debit card payments. The applicable surcharge will be displayed
								to you prior to the completion of your Booking. By purchasing a
								Booking using a credit or debit card, you acknowledge that we
								will not be liable for any additional costs you incur associated
								with exchange rates, bank fees, credit card fees or debit card
								fees. For clarity, ticksntravel.com will not refund any such
								charge to your credit card, debit card, or Ticks'nTravel Wallet.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.5 We reserve the right to delegate the management of all or
								part of our electronic payment collection (Visa, Mastercard,
								American Express, cryptocurrencies) to our subsidiaries or
								third-party payment providers. Any fees incurred by you as a
								result of purchasing a Travel Product from us are
								non-refundable, subject to your rights under applicable law.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.6 You agree that our payment providers can securely store your
								payment details, which can subsequently be used by us for later
								payment or refunds of your Booking. Upon submitting your Booking
								form, you authorize us to facilitate reservations on your
								behalf, including making payment arrangements with Service
								Providers and any third parties related to the provision of your
								Booking.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.7 For payments made online via the Website, you will not pay
								the Service Provider for the Travel Product mentioned on the
								Travel Document. Only Bookings made using direct payment with a
								Service Provider (e.g. in person at a hotel) will allow you to
								pay for your reservation once you arrive at the establishment.
								These two distinct payment options are clearly displayed during
								the Booking process.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.8 For Bookings made via a Ticks’nTravel employee, a deposit
								(or deposits) may be required. The amount of the deposit and
								number of deposits (for multiple Bookings) will be communicated
								to you via a Ticks’nTravel employee. Should an amendment or
								cancellation be made by you, your deposit(s) may be forfeited
								(subject to your rights under applicable law). Since final
								payments for Bookings with Service Providers are variable, we
								will advise you when final payment for your Booking is due.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.9 Exceptionally, ticksntravel.com may, at sole discretion,
								offer you the option to pay a low deposit for certain Bookings
								with Accommodation Providers (“Early Booking”). This will be
								indicated next to the specific Travel Product in your search
								results. An Early Booking requires a partial payment at the time
								of reservation and confirmation and a secondary payment
								consisting of the total balance due at a future point in time,
								as communicated on ticksntravel.com and in your confirmation
								email. The later payment will be automatically collected on the
								due date. You must make sure that the Payment Method is valid
								and has enough funds at the time of the balance payment. In case
								the Payment Method cannot be charged for whatever reason, you
								can pay the remaining balance using a valid Payment Method. If
								the payment is not authorized in due time, the Booking will be
								canceled.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.10 If your chosen Travel Product is not available and we
								provide you with an alternative option, your Payment Method will
								only be charged if you accept the new Travel Product and the new
								rate offered. If you do not accept the given alternative, no
								charge will be made to your Payment Method by ticksntravel.com.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								7.11 Any personal information that you submit to us in relation
								to the purchase of your Booking will be protected by our secure
								SSL payment gateway server, which uses 256-bit security
								technology to protect your online order information. This
								technology encrypts all information and all personal information
								passed from you through our Website.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								8. Changes, Cancellations and Refunds
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								8.1 The applicable cancellation policy of the Travel Product
								(except in the instance of flights notwithstanding the
								definition of a Travel Product defined herein) is displayed
								prior to your completion of the online Booking form. Please
								ensure that you read the cancellation policy applicable to your
								selected Travel Product carefully as the cancellation policy
								varies between Service Providers and the respective Travel
								Products that are offered. Any cancellation of a flight Booking
								shall be subject to the Service Provider/Carrier’s cancellation
								terms/policy.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								8.2 Voluntary changes for flight bookings can only be requested
								up to 72 hours before departure. If you voluntarily cancel or
								make changes to your Booking, the Service Provider may charge a
								cancellation or change fee. Voluntary cancellations or changes
								to Bookings cannot be reversed once requested by you. Where a
								change or cancellation to a Booking is made, processing fees may
								be applied by us in addition to the charges imposed by the
								Service Provider. If your flight booking contains multiple
								passengers, any changes you make will apply to the itinerary of
								all passengers relevant to that Booking; if you wish to change
								the Booking for some (not all) passengers, you acknowledge that
								it is your sole responsibility to make these arrangements
								directly with the Carrier. Please be aware that some Bookings
								may be non-refundable, and in this instance we cannot provide
								you with a refund. Where you request a voluntary cancellation on
								a Booking that may be refundable, please be aware that we cannot
								calculate the refund amount that you will receive at the time of
								your cancellation request. If you arrive late on your scheduled
								Booking date (as stated on your Travel Document), decide to
								leave before the conclusion of the Travel Product (as stated on
								your Travel Document), or fail to arrive (no-show),
								ticksntravel.com will not issue any refunds. Please note that,
								in some cases, you may be charged for the cancellation fee in
								accordance with the Service Provider’s cancellation policy if
								the amendment is made after a certain date or falls under an
								exception. When you cancel a flight Booking, the Booking will be
								canceled for all passengers booked under the same Booking.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								8.3 If your Booking is canceled or changed by the Service
								Provider or a related third party, we will endeavor to notify
								you of the corresponding cancellation or change as soon as
								possible. Please note that we are not liable for the
								consequences of any cancellations or changes made by these third
								parties.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								8.4 If you wish to modify the dates of your Booking with an
								Accommodation Provider, please log in to your Ticks'nTravel
								Account and use the "Change my dates" form subject to the
								Booking term allowing for such changes. For other types of
								modifications to Bookings with Accommodation Providers, or to
								modify Bookings with Carriers or Activity Providers, please
								contact us via email on{" "}
								<a href="mailto:bookings@ticksntravel.com">
									bookings@ticksntravel.com
								</a>{" "}
								subject to a Booking that allows modifications. Reservations not
								amended through ticksntravel.com will become subject to a 100%
								charge to be applied.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								8.5 In the event of a confirmed Booking with an Accommodation
								Provider becoming unavailable before the check-in date,
								ticksntravel.com will offer a full refund. Where we have prior
								notice, we will contact you by email. However, upon check-in, if
								the original Travel Product specified in your Travel Document is
								closed, overbooked, or has maintenance problems and cannot
								provide the room(s) booked, you accept that the Accommodation
								Provider is responsible for finding you alternative
								accommodation of a similar standard and/or for providing you
								with a refund. You acknowledge that ticksntravel.com will not be
								liable to you and will not provide you with a refund or
								alternative accommodation.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								8.6 If you are entitled to a refund, either in part or in full,
								the refund amount that you are entitled to will be processed
								upon our receipt of cleared funds from the Service Provider.
								Where a refund is properly due to you and we have received your
								cancellation or amendment email, we will refund your credit
								card, debit card or the Ticks'nTravel Wallet within five (5)
								days after we have received the applicable refund sum from the
								respective Service Provider. In the event that your initial
								payment for Booking/s were made through cryptocurrency, due to
								cryptocurrency price/value volatility and regulatory
								requirements, we are only able to refund cryptocurrency payments
								in part or in full with ticksntravel.com Travel Credits.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								9. Hotel Room Types
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								9.1 The following room and item descriptions on the
								ticksntravel.com website shall have the following meaning:
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Single room: one single bed. Suitable for one person.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Twin room: two single beds in the same room. Suitable for two
								people.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Double room: one double bed. Suitable for two people.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Triple room: twin or double bed/s plus extra bed, or two double
								beds. Most hotels do not have one full size bed for each guest
								in their triple rooms.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Twin/double room for sole/single use: two single beds or one
								double bed in the same room, to be occupied by one person.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Quadruple room: twin or double bed/s plus extra beds, or two
								double beds. Most hotels do not have one full size bed for each
								guest in their quadruple rooms.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Twin/Double + child: twin or double room with an extra bed
								suitable for a child aged between two (2) and twelve (12) years.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								Cot/cradle or crib: suitable for a child under the age of two
								(2) years.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								10. Cash Back Rewards, Promotions, Discounts
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								10.1 We may offer cash back rewards via ToLcoin, promotions,
								discounts, sign-up bonuses or other forms of incentives
								(“Promotions”) from time to time that allow you to receive a
								benefit. Promotions are subject to these Terms unless otherwise
								specified. In the event that there is a discrepancy between
								these Terms and the terms applicable to a specific Promotion,
								the terms of the Promotion shall prevail.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								10.2 As part of a Promotion, the benefit you receive may be in
								the form of Promo Credits. Promo Credits are denominated in
								United States Dollars (USD) and are available to use for travel
								bookings of any type on the Website. Promo Credits you receive
								or claim may only be redeemed once. Promo Credits have a set
								expiry date, which is viewable in the Travel Credits section of
								your Ticks'nTravel Account.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								10.3 Promotions do not have a cash value, are not transferable,
								and cannot be redeemed for cash.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								10.4 A Promotion may not be used in conjunction with any other
								Promotions or the Best Price Guarantee, whether offered by us or
								by a third party.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								10.5 If local laws prohibit or restrict the offer of any
								Promotion, that Promotion is void
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								10.6 If we become aware of or reasonably suspect that, in
								participating in a Promotion, you have:
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>breached these Terms;</li>
								<li>
									made multiple bookings with the view of operating a business
									or profiting from a Promotion; or
								</li>
								<li>
									otherwise engaged in activities that undermine the integrity
									or fairness of a Promotion,
								</li>
							</ul>
							<p className="text-clr-primary-gray text-base font-medium">
								we reserve the right to cancel any bookings you have made as a
								result of a Promotion.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								10.7 If a Promotion was applied to a booking and that Promotion
								and/or booking is canceled for any reason, the value of the
								Promotion is forfeited and you will not be entitled to any
								refund or other compensation.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								10.8 We reserve the right to cancel or vary the conditions of a
								Promotion at any time at our sole discretion.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								11. Acceptable Use
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								11.1 In using the Website, you agree and warrant that you:
							</p>
							<ul className="text-clr-light-gray list-disc ml-8">
								<li>
									will use the Website strictly for personal and non-commercial
									purposes only and you acknowledge that use of the Website for
									any other purpose is prohibited;
								</li>
								<li>
									will not gather, extract, reproduce and/or display on any
									other website or other online service, any material on or from
									the Website, specifically including information relating to
									Travel Product pricing and availability, whether using robots,
									spiders, or other "screen scraping" software or system used to
									extract data;
								</li>
								<li>
									will not use the Website to provide ticksntravel.com’s
									Bookings or details of Travel Product pricing and availability
									or other information to any other persons or organizations,
									other than for your personal and non-commercial use;
								</li>
								<li>
									will not use the Website for any purpose that is either
									unlawful or not allowed under these Terms.
								</li>
								<li>
									will not commit any act that would constitute a breach of
									either the privacy (including uploading private or personal
									information without an individual’s consent) or any other of
									the legal rights of individuals;
								</li>
								<li>
									will not use the Website while impersonating another person;
								</li>
								<li>
									will not use the Website to defame or libel us, our employees
									or other individuals;
								</li>
								<li>
									will not transmit any viruses, Trojan horses, worms, or other
									items that may cause damage to our property or the property of
									other individuals;
								</li>
								<li>
									will not post or transmit to this Website any non-authorised
									material including, but not limited to, material that is, in
									our opinion, likely to cause annoyance, or which is
									defamatory, racist, obscene, threatening, pornographic or
									otherwise, or which is detrimental to or in violation of our
									systems or a third party’s systems or network security;
								</li>
								<li>
									will not tamper with, hinder the operation of, or make
									unauthorized modifications to the Website (including deleting
									data from the Website without our permission);
								</li>
								<li>
									will not breach any third party’s rights (including
									intellectual property rights and obligations of
									confidentiality owed to third parties) or infringe any laws in
									any jurisdiction in using the Website; and
								</li>
								<li>
									will not attempt to gain unauthorized access to the Website,
									user accounts, or computer networks or systems connected to
									the Website through hacking, password mining, or any other
									means.
								</li>
							</ul>
							<p className="text-clr-primary-gray text-base font-medium">
								11.2 To make a Booking on the Website, you must be at least 18
								years of age (or the legal minimum age in countries where the
								legal minimum age is greater than 18). By entering into this
								agreement, you confirm that you possess the legal authority and
								necessary minimum age to use the Website in accordance with
								these Terms. ticksntravel.com will not compensate you or anyone
								else for any expenses incurred as a result of minors using the
								Services, including any Bookings placed by minors.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								11.3 Unless otherwise indicated, we own, or license from third
								parties, all rights, title, and interest (including, without
								limitation, copyright, designs, patents, trademarks and other
								intellectual property rights) contained on the Website and in
								all of the material (including, without limitation, all text,
								graphics, logos, audio and software) made available on the
								Website (“Content”). Your use of the Website and use of and
								access to any Content does not grant or transfer any rights,
								title, or interest to you in relation to the Website or the
								Content. However, we do grant you a license to access the
								Website and view the Content on the terms and conditions set out
								in these Terms and, where applicable, as expressly authorized by
								us and/or our third party licensors. Any reproduction or
								redistribution of the Website or Content is prohibited and may
								result in penalties. In addition, you must not copy the Content
								to any other server, location or support for publication,
								reproduction, or distribution is expressly prohibited. All other
								use, copying, or reproduction of the Website, the Content, or
								any part of the Website or Content is prohibited, except to the
								extent permitted by law.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								11.4 We will determine whether there has been a breach of these
								Terms through your use of the Website. If a breach of this
								Policy has occurred, we may take such action as we deem
								appropriate, including denying you access to the Website,
								bringing legal proceedings against you, or disclosing such
								information to law enforcement authorities as we deem
								appropriate.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								11.5 Our Website may contain links to other websites operated by
								third parties. Those links are provided for convenience and may
								not remain current or be maintained. We are not liable to you if
								interference with or damage to your computer systems occurs in
								connection with the use of the Website or any linked website.
								You must take your own precautions to ensure that whatever you
								select for your use from our Website is free of viruses or any
								other malware that may interfere with or damage the operations
								of your computer systems.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								11.6 If we allow you to post any information to the Website, we
								have the right to take down this information at our sole
								discretion and without notice.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								11.7 By using the Website, or by entering into correspondence
								with us through email, telephone, or other communication tools,
								you agree that we may communicate with you through methods
								including, but not limited to, email, telephone, messaging
								applications, and in-app notifications. If you wish to
								unsubscribe from marketing emails, please click ‘Unsubscribe’ at
								the footer of any of our marketing emails. Please note that, if
								you unsubscribe from our marketing emails, you will still
								receive non-marketing emails from us (including emails relating
								to any Bookings that you place).
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								11.8 If you do not agree with these Terms, you must not use the
								Website.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								12. Agency
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								12.1 The Services that we provide to you are in the capacity of
								an agent with permission to sell products on behalf of various
								Service Providers. We have an obligation to you to finalize the
								Booking on your behalf and arrange contracts between you and
								Service Providers. Any Travel Products that you purchase in
								connection with your Booking under these Terms is provided to
								you by third parties, not ticksntravel.com
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								12.2 Though we endeavor to select reputable third parties to
								provide your Travel Product(s) to you, the provision of Travel
								Products is outside of our control and scope of liability. Any
								arrangement made by us on your behalf is subject to the terms
								and conditions of the Service Provider in charge of delivering
								the purchased Travel Product(s) to you. The terms and conditions
								of your relevant Service Provider can be provided to you on
								request. If there are issues with the provision of your Travel
								Product(s), or the Service Provider is unable to provide the
								contracted services, unless there has been a fault on our part,
								your legal rights are against the specific Service Provider, not
								against ticksntravel.com.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								13. Representation
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								13.1 We represent and warrant to you that our Services: (a) will
								be provided with due care and skill; (b) will be reasonably fit
								for the specified purpose; (c) can be reasonably expected to
								achieve the desired result; and (d) will be provided within a
								reasonable time.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								13.2 EXCEPT AS DESCRIBED IN THE PRECEDING ITEM ABOVE, ALL
								EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES,
								INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
								PARTICULAR PURPOSE OR NON-INFRINGEMENT ARE DISCLAIMED, EXCEPT TO
								THE EXTENT THAT THESE DISCLAIMERS ARE HELD TO BE LEGALLY
								INVALID. WE MAKE NO REPRESENTATIONS, WARRANTIES OR GUARANTEES
								ABOUT THE SERVICES THAT WE SELL AND THEIR AVAILABILITY, SAFETY,
								OR RELIABILITY (EXCEPT IN RELATION TO NON-EXCLUDABLE
								OBLIGATIONS).
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								14. Limitation of Liability and Indemnification
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								14.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT DOES
								TICKSNTRAVEL.COM NOR ANY OF ITS DIRECTORS, EMPLOYEES, AGENTS, OR
								RELATED BODIES CORPORATE ACCEPT LIABILITY FOR ANY DIRECT,
								INDIRECT, CONSEQUENTIAL, PUNITIVE, EXEMPLARY, SPECIAL, OR
								INCIDENTAL DAMAGES, INCLUDING, WITHOUT LIMITATION, DELAY,
								INCONVENIENCE OR ADDITIONAL EXPENSE WHICH MAY BE SUFFERED DUE TO
								(WITHOUT LIMITATION) YOUR USE OF, OR THE INABILITY TO USE, OUR
								SERVICES, THIRD PARTY PROVIDERS, FORCE MAJEURE, OR EVENTS THAT
								WE CANNOT CONTROL OR WHICH COULD NOT HAVE BEEN PREVENTED BY
								REASONABLE DILIGENCE ON OUR PART.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								14.2 IF IT IS DETERMINED THAT TICKSNTRAVEL.COM IS LIABLE FOR ANY
								LOSS OR DAMAGE THAT ARISES OUT OF, OR IS CONNECTED TO, YOUR USE
								OF OUR SERVICES, OUR MAXIMUM LIABILITY UNDER THIS AGREEMENT WILL
								BE LIMITED TO THE REISSUE OF YOUR BOOKING (OR CORRECTING THE
								DEFECTIVE PART OF YOUR BOOKING), OR THE PROVISION OF CREDIT OR
								REFUND FOR THE TOTAL PRICE OF YOUR BOOKING AT THE PRO RATA
								CONTRACT RATE.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								14.3 IN THE EVENT THAT THIS PROVISION IS UNENFORCEABLE IN YOUR
								JURISDICTION, THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS WILL
								APPLY TO THE MAXIMUM EXTENT POSSIBLE, AS PERMITTED BY APPLICABLE
								LAW.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								14.4 You agree to fully indemnify us and our directors,
								officers, employees, agents, successors, assigns and/or
								affiliates (including our affiliated, subsidiary and/or parent
								companies) for any and all (without limitation) damages, costs,
								expenses, liabilities, fines and or penalties, including
								attorney fees, which arise as a result of your breach of this
								Terms or any other terms supplemental or related to
								ticksntravel.com, your violation of any applicable law or the
								rights of any third parties and/or any other act or omission by
								you which is in relation to the subject matter of the Terms
								herein.
							</p>
						</div>
						{/* section */}
						<div className="flex flex-col items-start justify-start w-[85%] mx-auto space-y-3">
							<h2 className="text-2xl font-semibold mb-4 text-clr-simple-bg">
								15. Miscellaneous
							</h2>
							<p className="text-clr-primary-gray text-base font-medium">
								15.1 Changes to the Website. We may make improvements to, and/or
								change, the Website at any time. ticksntravel.com, its
								suppliers, and any of its related third parties make no
								representation about the suitability of the information,
								software, products and services contained on the Website for any
								purpose, and the inclusion or offering for sale of any products
								or services on this Website does not constitute any endorsement
								or recommendation of such products or services by
								ticksntravel.com, suppliers, Service Providers, or any of
								related third parties. All such information, software, products
								and services are provided "as is" and without warranty of any
								kind.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.2 Our Connection to You: You agree that, by using our
								Services, no joint venture, agency, partnership, or employment
								relationship exists between you and us.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.3 Supplier Relations. ticksntravel.com’s suppliers and
								Service Providers are independent contractors and not agents or
								employees of ticksntravel.com. ticksntravel.com is not liable
								for a supplier’s acts, errors, omissions, representations,
								warranties, breaches or negligence or for any losses, personal
								injuries, death, property damage, or other damages or expenses
								arising out of any act or omission of a supplier.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.4 Destination Risk. By offering Travel Products in
								international destinations on the Website, ticksntravel.com does
								not warrant that travel to such destinations is without risk.
								Accordingly, ticksntravel.com will not be liable for any damages
								or losses that may be incurred by you as a result of you
								traveling to such destinations. You are strongly advised to
								check the relevant travel advisories issued by your country for
								any destination you are visiting and to take the necessary
								precautions. ticksntravel.com cannot be held liable for any loss
								incurred in the event of your failure to hold the necessary
								passports, visas and documentation for your journey. You are
								advised to check with the relevant embassies, consulates and/or
								visa departments from which you originate. It is your
								responsibility to obtain the necessary documents.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.5 Taxes. The tax charge on any Travel Product purchase(s) via
								ticksntravel.com is a recovery of all applicable transaction
								taxes that ticksntravel.com pays its suppliers in connection
								with your travel arrangements. Taxability and the appropriate
								tax rate can vary greatly by location. ticksntravel.com's actual
								tax cost paid to the supplier may vary from the tax recovery
								charge, depending upon the rates and taxability in effect at the
								time. You acknowledge that ticksntravel.com is under no
								obligation to account to you or refund you in this regard.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.6 Currency Rates. The currency rates displayed on the Website
								are not verified or guaranteed by ticksntravel.com as being
								accurate and should be used as guidelines only. Rates are not
								guaranteed to be updated every day and actual rates may vary.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.7 Accuracy of Information. The information, software,
								products, and services published on the Website may include
								inaccuracies or errors, including pricing errors.
								ticksntravel.com and its affiliates do not guarantee the
								accuracy of any part of the Website and disclaim all liability
								for any errors or other inaccuracies relating to the
								information, pricing and description of the Travel Products on
								the Website. In addition, ticksntravel.com expressly reserves
								the right to correct any pricing errors on our Website on
								pending and/or confirmed reservations made under an incorrect
								price. In such an event, if available, we will offer you the
								opportunity to keep your pending Booking at the correct price or
								we will cancel your Booking without penalty.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.8 The information, software, Travel Products, and Services
								that are published, displayed or used on this website may
								include inaccuracies or typographical errors and
								ticksntravel.com and its suppliers will not, therefore, be
								liable for any inaccuracies displayed on the website relating
								to, without limitation: descriptions of Travel Products;
								photographs of Travel Products; property of Accommodation
								Providers, or; lists of amenities or facilities and their
								respective availability.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.9 Any ratings of Travel Products displayed on the Website are
								intended only as general guidelines. ticksntravel.com does not,
								therefore, guarantee the accuracy of the ratings, which may
								change from time to time. Any reliance you place on such ratings
								is at your own entire risk.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.10 Our Rights. We reserve the right not to accept customers
								or Bookings (or, in exceptional cases, to cancel confirmed ones)
								at our discretion and for whatever (legal) reason without the
								need to justify such refusal. Typical reasons for rejecting a
								customer or Booking include, but are not limited to: Service
								Provider request, breach of the Terms of Use, trade or economic
								sanctions, embargoes, legal restrictions, (suspicions of) fraud
								or theft, suspected criminal activity, suspicious Bookings,
								submission by customer of misleading or erroneous information,
								credit card problems, inappropriate behavior, threats, insults,
								refusal to supply information, practical obstacles,
								communication problems, obvious errors (see below), history, or
								blacklisting by governments or international organizations.
								Where a Booking is rejected or canceled by ticksntravel.com and
								a payment has already been made, you will receive a refund of
								the Total Price. We also reserve the right to bar (“blacklist”)
								users from the Website, on a permanent or temporary basis, at
								our discretion. Any such blacklisted user must not attempt to
								use the Website under any other name or through any other user.
								You must also not create more than one Ticks'nTravel Account. In
								rare cases, we may also have to cancel or reject a Booking due
								to “obvious errors”, independent of the origin of such errors.
								For clarity, an obvious error is a mistake on the Website (e.g.
								in terms of price) which a reasonable person would not consider
								to be normal. The amount charged will be reimbursed without
								charge in such a case. Whether to cancel or reject a Booking for
								this reason is in ticksntravel.com’s sole discretion.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.11 Compliance with Law Enforcement. These Terms are subject
								to existing laws and legal processes that ticksntravel.com is
								subject to in delivering the Services and all other aspects of
								these Terms. Nothing contained in these Terms limits our right
								to comply with law enforcement, governmental or legal requests,
								or requirements relating to your use of the Services. To the
								extent permitted by applicable law, you agree to bring any claim
								or cause of action arising from or relating to your access or
								use of the Services within two (2) years from the date on which
								such claim or action arose or accrued, or such claim or cause of
								action will be irrevocably waived.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.12 Entire Agreement. These Terms, including any other
								relevant terms and conditions or policies referenced herein,
								constitute the entire agreement between you and ticksntravel.com
								with respect to the Services. These Terms supersede all prior or
								contemporaneous communications (whether electronic, oral, or
								written) between you and ticksntravel.com with respect to the
								Services.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.13 Severability. If any provision of these Terms is deemed
								invalid or unenforceable, the provision will be enforceable to
								the maximum extent permissible and the remaining provisions will
								remain in full force and effect. Any invalid or ineffective
								provision shall be replaced with a new, valid and effective one
								which corresponds to the intent and purpose of the provision
								that is being replaced.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.14 Amendments to these Terms. These Terms may be amended at
								any stage without notice at our sole discretion and will be
								effective immediately upon their publication on the Website. By
								continuing to use the Services contained in these Terms, you
								agree to the changes set out in these Terms. If you do not agree
								to any modification to these Terms, you should cease using our
								Services immediately. We encourage you to review these Terms
								often to stay up to date with the current terms and conditions
								that apply to your use of the Services.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.15 Data Processing. The information and personal data that
								you provide when purchasing a Booking is processed in accordance
								with our Privacy Policy, which forms an inseparable part of
								these Terms. By accepting these Terms, you confirm that you have
								read and understood our Privacy Policy.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.16 Force majeure. Our contractual obligations to you under
								these Terms will be hindered, delayed, or prevented due to
								reasons of force majeure, which include, but are not limited to,
								factual, technical, political, economic, meteorological
								circumstances, including but not limited to acts of God, natural
								disasters, outbreak of disease, epidemics, pandemics, civil
								conflicts, protests, riots, blackouts, strikes, wars, any
								governmental or regulatory action, outage or restrictions of
								supplies of goods or services, restrictive travel orders and
								recommendations and other circumstances that ticksntravel.com
								cannot foresee or reasonably resolve. In instances of force
								majeure, we are exempt from any derived legal responsibilities
								arising from such deficiencies or non-compliances.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.17 Governing Law. These Terms, the legal relations
								established under these Terms, and all disputes arising out of
								or in connection with these Terms, are governed by the laws of
								the Cayman Islands. You submit to the non-exclusive jurisdiction
								of the courts exercising jurisdiction of the Cayman Islands.
							</p>
							<p className="text-clr-primary-gray text-base font-medium">
								15.18 Agreement. By completing a Booking, or by using any other
								part of our Services listed in these Terms, you acknowledge that
								you are at least 18 years of age (or the legal minimum age in
								countries where the legal minimum age is greater than 18), have
								the legal capacity to enter into a legally binding agreement,
								and understand and agree to the above Terms and Conditions.
							</p>
						</div>
					</div>
				</div>
			</div>
			<Subscribe />
		</main>
	);
}

export default TermsPage;
