import React, { useState, useRef, useEffect } from "react";

// lib
import { IoIosArrowDown } from "react-icons/io";
import { MdFamilyRestroom } from "react-icons/md";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

// custom
import UseRemoveInputFocus from "../../../../hooks/useRemoveInputFocus";
import {
	addOrUpdateItem,
	classNames,
	deleteItem,
	reorderList,
} from "../../../../utils/helpers";
import FlightTour from "./FlightTour";
import { useFlightContext } from "../../../../contexts/flight_context";
import { useMainContext } from "../../../../contexts/main_context";

function FlightForm() {
	const navigate = useNavigate();
	const { fetchTempToken } = useMainContext();
	const { fetchFlights } = useFlightContext();
	const [isClassListOpen, setClassList] = useState(false);
	const [tempTokenUserLoading, setTempTokenUserLoading] = useState(false);
	const [tempTokenUserStatus, setTempTokenUserStatus] = useState(false);

	const classListRef: any = useRef();
	UseRemoveInputFocus(classListRef, () => setClassList(false));

	const [isPassengersListOpen, setPassengersList] = useState(false);
	const passengersListRef: any = useRef();
	UseRemoveInputFocus(passengersListRef, () => setPassengersList(false));

	// handleClass
	const hnadleFlightClass = (e: any) => {
		setFormData({ ...formData, CabinClass: e });
		setClassList(false);
	};
	//handleType
	const handleFlightType = (e: any) => {
		setFormData({ ...formData, JourneyType: e });
	};

	// handle passengers decrease
	const handleDecreaseBtn = (source: string) => {
		if (source === "adult") {
			setFormData({
				...formData,
				AdultCount: formData.AdultCount === 1 ? 1 : formData.AdultCount - 1,
			});
		}

		if (source === "child") {
			setFormData({
				...formData,
				ChildCount: formData.ChildCount === 0 ? 0 : formData.ChildCount - 1,
			});
		}

		if (source === "infant") {
			setFormData({
				...formData,
				InfantCount: formData.InfantCount === 0 ? 0 : formData.InfantCount - 1,
			});
		}
	};
	// handle passengers increase
	const handleIncreaseBtn = (source: string) => {
		if (source === "adult") {
			setFormData({
				...formData,
				AdultCount: formData.AdultCount + 1,
			});
		}

		if (source === "child") {
			setFormData({
				...formData,
				ChildCount: formData.ChildCount + 1,
			});
		}

		if (source === "infant") {
			setFormData({
				...formData,
				InfantCount: formData.InfantCount + 1,
			});
		}
	};

	// flight form object
	const [formData, setFormData] = useState({
		CabinClass: "Economy",
		JourneyType: "OneWay",
		AdultCount: 1,
		ChildCount: 0,
		InfantCount: 0,
		// passengers: {
		// 	adults: 1,
		// 	children: 0,
		// 	infants: 0,
		// },
		Segments: [],
	});

	// form validation
	const [formDataValidate, setFormDataValidate] = useState({
		CabinClass: true,
		JourneyType: true,
		AdultCount: true,
		ChildCount: true,
		InfantCount: true,
		// passengers: true,
		Segments: [],
	});
	// form error
	const [formDataError, setFormDataError] = useState({
		CabinClass: "",
		JourneyType: "",
		AdultCount: "",
		ChildCount: "",
		InfantCount: "",
		// passengers: "",
		Segments: [],
	});

	/* start tours forms */
	// create tour forms
	const [toursFormsCount, setToursFormscount] = useState(0);
	let toursForms: any = [];
	// remove tour form
	const removeTourForm = (id: string) => {
		setToursFormscount(toursFormsCount - 1);
		toursForms = toursForms.filter((tourForm: any) => tourForm.props.id !== id);
		toursForms = reorderList(toursForms);
		// remove it from base object
		setFormData({
			...formData,
			Segments: reorderList(deleteItem(formData.Segments, id)),
		});

		// remove it from base validation object
		setFormDataValidate({
			...formDataValidate,
			Segments: reorderList(deleteItem(formDataValidate.Segments, id)),
		});

		// remove it from base error object
		setFormDataError({
			...formDataError,
			Segments: reorderList(deleteItem(formDataError.Segments, id)),
		});
		sessionStorage.setItem(
			"searchData",
			JSON.stringify({
				...formData,
				tours: reorderList(deleteItem(formData.Segments, id)),
			})
		);
	};

	// handle form data
	const handleTours = (newTour: any) => {
		setFormData({
			...formData,
			Segments: addOrUpdateItem(formData.Segments, newTour),
		});
		// console.log(formData)
	};

	// initial tour form array
	const fillToursFormArray = () => {
		Array.from({ length: toursFormsCount }).forEach((inpElement, index) => {
			toursForms.push(
				<>
					<FlightTour
						id={`form_${index + 2}`}
						remove={removeTourForm}
						// removeValidate={removeTourFormValidate}
						// removeErrors={removeTourFormError}
						add={handleTours}
						// addValidate={handleToursValidation}
						// addErrors={handleToursError}
						key={index}
						type={formData.JourneyType}
						segmentsCount={toursFormsCount}
						// segments={getToursSegments}
						// formSubmited={isSubmited}
					/>

					{/* <hr className="my-4 md:hidden block text-clr-gray-dark border-clr-gray-dark" /> */}
				</>
			);
		});
	};
	// call func
	fillToursFormArray();
	// add new tour form
	const addTourForm = () => {
		setToursFormscount(toursFormsCount + 1);
		//fillToursFormArray();
	};

	const handleSubmit = () => {
		localStorage.setItem("flight_form", JSON.stringify(formData));
		fetchTempToken({
			setTempTokenUserLoading,
			setTempTokenUserStatus,
		});
		// fetchFlights(formData);
		// navigate("/flights");
		//console.log("form", formData, reshapSegmentsArr(formData.Segments));
	};

	useEffect(() => {
		if (tempTokenUserStatus) {
			fetchFlights(formData);
			navigate("/flights");
		}
	}, [tempTokenUserStatus]);

	return (
		<div className="flex flex-col space-y-4 px-5 py-6 relative">
			<div className="grid grid-cols-12 gap-2">
				{/* class & type */}
				<div className="s_900:col-span-7 col-span-12 flex flex-wrap s_1175:space-x-5 s_900:space-x-0">
					{/* flight class */}
					<div className="relative mr-4 mb-2">
						<span
							onClick={() => setClassList(!isClassListOpen)}
							className="flex flex-row space-x-2 items-center justify-center rounded-lg py-3 px-6 border border-clr-primary-green text-clr-primary-green cursor-pointer"
						>
							<span className="text-sm font-medium">{formData.CabinClass}</span>
							<IoIosArrowDown className="w-5 h-5" />
						</span>
						{isClassListOpen && (
							<ul
								ref={classListRef}
								className="absolute top-12 left-0 bg-white shadow-listShadow rounded-lg w-[150px] z-20"
							>
								<li
									className="py-3 px-2 border-b border-clr-primary-green w-full flex items-center cursor-pointer"
									onClick={() => hnadleFlightClass("Economy")}
								>
									<span className="text-clr-primary-green text-sm font-medium">
										Economy
									</span>
								</li>
								<li
									className="py-3 px-2 border-b border-clr-primary-green w-full flex items-center cursor-pointer"
									onClick={() => hnadleFlightClass("PremiumEconomy")}
								>
									<span className="text-clr-primary-green text-sm font-medium">
										PremiumEconomy
									</span>
								</li>
								<li
									className="py-3 px-2 border-b border-clr-primary-green w-full flex items-center cursor-pointer"
									onClick={() => hnadleFlightClass("Business")}
								>
									<span className="text-clr-primary-green text-sm font-medium">
										Business
									</span>
								</li>
								<li
									className="py-3 px-2 border-b border-clr-primary-green w-full flex items-center cursor-pointer"
									onClick={() => hnadleFlightClass("PremiumFusines")}
								>
									<span className="text-clr-primary-green text-sm font-medium">
										PremiumFusines
									</span>
								</li>
								<li
									className="py-3 px-2 last:border-none last:border-clr-primary-green w-full flex items-center cursor-pointer"
									onClick={() => hnadleFlightClass("First")}
								>
									<span className="text-clr-primary-green text-sm font-medium">
										First
									</span>
								</li>
							</ul>
						)}
					</div>
					{/* flight type */}
					<div className="flex flex-wrap">
						<span
							onClick={(e) => handleFlightType("OneWay")}
							className={classNames(
								"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2",
								formData.JourneyType == "OneWay"
									? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
									: "border-clr-light-gray text-clr-light-gray cursor-pointer"
							)}
						>
							<span className="text-sm font-medium">One Way</span>
						</span>
						<span
							onClick={(e) => handleFlightType("Return")}
							className={classNames(
								"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2",
								formData.JourneyType == "Return"
									? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
									: "border-clr-light-gray text-clr-light-gray cursor-pointer"
							)}
						>
							<span className="text-sm font-medium">Round Trip</span>
						</span>
						<span
							onClick={(e) => handleFlightType("Multicity")}
							className={classNames(
								"flex flex-row space-x-2 items-center justify-center rounded-lg py-[10px] px-6 border cursor-pointer mr-2 mb-2",
								formData.JourneyType == "Multicity"
									? "text-white bg-clr-primary-green border-clr-primary-green shadow-btnShadow"
									: "border-clr-light-gray text-clr-light-gray cursor-pointer"
							)}
						>
							<span className="text-sm font-medium">Multi City</span>
						</span>
					</div>
				</div>
				{/* passengers */}
				<div className="s_900:col-span-5 col-span-12 w-full relative">
					<label
						htmlFor="passengers"
						className="text-base text-clr-simple-bg font-medium"
					>
						Travelers
					</label>
					<div
						className="flex flex-row items-center space-x-1 w-full"
						onClick={() => setPassengersList(!isPassengersListOpen)}
					>
						<span className={""}>
							<MdFamilyRestroom className={"text-clr-primary-green w-6 h-6"} />
						</span>
						<span className="text-clr-primary-gray text-sm font-semibold cursor-pointer w-full">
							{formData.AdultCount} Adults, {formData.ChildCount} Child,{" "}
							{formData.InfantCount} Infants
						</span>
					</div>
					{isPassengersListOpen && (
						<div
							className="bg-white rounded-lg shadow-listShadow absolute z-10"
							ref={passengersListRef}
						>
							{/* passengers vars */}
							<div className="grid s_600:grid-cols-3 s_370:grid-cols-2 grid-cols-1 gap-x-2 gap-y-1 py-2 px-8">
								{/* adult vars */}
								<div className={"flex flex-col space-y-2 p-2"}>
									<h3 className={"text-clr-simple-bg text-sm font-medium"}>
										Adults
									</h3>
									<div
										className={
											"flex flex-row items-center justify-center space-x-5 py-2 px-5 border border-clr-stroke rounded-lg"
										}
									>
										<button
											className={"text-clr-primary-green text-lg font-semibold"}
											type="button"
											onClick={() => handleDecreaseBtn("adult")}
										>
											-
										</button>
										<span
											className={"text-clr-simple-bg text-lg font-semibold"}
										>
											{formData.AdultCount}
										</span>
										<button
											className={"text-clr-primary-green text-lg font-semibold"}
											type="button"
											onClick={() => handleIncreaseBtn("adult")}
										>
											+
										</button>
									</div>
								</div>
								{/* children vars */}
								<div className={"flex flex-col space-y-2 p-2"}>
									<h3 className={"text-clr-simple-bg text-sm font-medium"}>
										Children
									</h3>
									<div
										className={
											"flex flex-row items-center justify-center space-x-5 py-2 px-5 border border-clr-stroke rounded-lg"
										}
									>
										<button
											className={"text-clr-primary-green text-lg font-semibold"}
											type="button"
											onClick={() => handleDecreaseBtn("child")}
										>
											-
										</button>
										<span
											className={"text-clr-simple-bg text-lg font-semibold"}
										>
											{formData.ChildCount}
										</span>
										<button
											className={"text-clr-primary-green text-lg font-semibold"}
											type="button"
											onClick={() => handleIncreaseBtn("child")}
										>
											+
										</button>
									</div>
									{/* <span className={"text-clr-light-gray text-xs"}>
										between 2 & 11 years
									</span> */}
								</div>
								{/* infants vars */}
								<div className={"flex flex-col space-y-2 p-2"}>
									<h3 className={"text-clr-simple-bg text-sm font-medium"}>
										Infants
									</h3>
									<div
										className={
											"flex flex-row items-center justify-center space-x-5 py-2 px-5 border border-clr-stroke rounded-lg"
										}
									>
										<button
											className={"text-clr-primary-green text-lg font-semibold"}
											type="button"
											onClick={() => handleDecreaseBtn("infant")}
										>
											-
										</button>
										<span
											className={"text-clr-simple-bg text-lg font-semibold"}
										>
											{formData.InfantCount}
										</span>
										<button
											className={"text-clr-primary-green text-lg font-semibold"}
											type="button"
											onClick={() => handleIncreaseBtn("infant")}
										>
											+
										</button>
									</div>
									{/* <span className={"text-clr-light-gray text-xs"}>
											under 2 years
										</span> */}
								</div>
							</div>

							{/* <div className="w-full">
								<button
									className="bg-Primary hover:bg-clr-blue-hover text-white font-Montserrat font-medium w-full p-2 rounded-full mb-5"
									// onClick={handleCurrentPassengers}
									type="button"
								>
									Done
								</button>
							</div> */}
						</div>
					)}
				</div>
			</div>
			<FlightTour
				tourType={formData.JourneyType}
				id={"form_0"}
				remove={removeTourForm}
				add={handleTours}
				segmentsCount={toursFormsCount}
			/>
			{formData.JourneyType == "Multicity" && (
				<FlightTour
					tourType={formData.JourneyType}
					id={"form_1"}
					remove={removeTourForm}
					add={handleTours}
					segmentsCount={toursFormsCount}
				/>
			)}
			{formData.JourneyType == "Multicity" && toursForms}
			{formData.JourneyType == "Multicity" && toursFormsCount < 3 && (
				<button
					className="flex flex-row space-x-1 text-clr-primary-green items-center justify-center py-2 w-[130px]"
					onClick={addTourForm}
				>
					<FaPlus />
					<span className="text-base font-semibold">Add Flight</span>
				</button>
			)}
			<button
				onClick={handleSubmit}
				className="flex flex-row items-center justify-center space-x-1 bg-clr-primary-green py-2  text-white rounded-lg w-[130px] shadow-btnShadow absolute bottom-5 right-5"
			>
				{tempTokenUserLoading ? (
					<svg
						aria-hidden="true"
						role="status"
						className="inline w-4 h-4 mr-3 text-clr-main-dark font-semibold animate-spin"
						viewBox="0 0 100 101"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							fill="#E5E7EB"
						/>
						<path
							d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							fill="currentColor"
						/>
					</svg>
				) : (
					<FaSearch />
				)}

				<span className="text-base font-semibold">search</span>
			</button>
		</div>
	);
}

export default FlightForm;
