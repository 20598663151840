import React, { useState, useEffect } from "react";

// lib
import { toast } from "react-toastify";
import Cookies from "js-cookie";

// custom
import { classNames } from "../../../utils/helpers";
import { axios_auth, axios } from "../../../utils/axios";

//regex
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

function EmailForm() {
	const [currentStep, setCurrentStep] = useState(1);
	const [loading, setLoading] = useState(false);
	const [emailData, setEmailData] = useState({
		email: "",
		code: "",
	});

	const [emailDataValid, setEmailDataValid] = useState({
		email: false,
		code: false,
	});

	const [emailDataFocus, setEmailDataFocus] = useState({
		email: false,
		code: false,
	});

	const handleInput = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		//console.log("input", name, value);
		setEmailData({ ...emailData, [name]: value });
	};

	const handleFirstStep = () => {
		if (!emailDataValid.email) {
			toast.error(`there was an error. please fill all fields in right way.`, {
				position: "top-left",
			});
		} else {
			setLoading(true);
			axios_auth(Cookies.get("token"))
				.post("/user/profile/email/change", emailData)
				.then((res) => {
					if (res.data.status === true) {
						//console.log("flight - booking", res.data.data);
						if (res.data.data) {
							setCurrentStep(2);
							toast.success(res.data.message, {
								position: "top-left",
							});
						} else {
							toast.error(res.data.message, {
								position: "top-left",
							});
						}
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
					setLoading(false);
				})
				.catch((error) => {
					toast.error(error.response?.data.message, {
						position: "top-left",
					});
					setLoading(false);
				});
		}
	};

	const handleSecondStep = () => {
		if (!emailDataValid.email && !emailDataValid.code) {
			toast.error(`there was an error. please fill all fields in right way.`, {
				position: "top-left",
			});
		} else {
			setLoading(true);
			axios_auth(Cookies.get("token"))
				.post("/user/profile/email/change/otp/validate", emailData)
				.then((res) => {
					if (res.data.status === true) {
						//console.log("flight - booking", res.data.data);
						if (res.data.data) {
							setCurrentStep(1);
							setEmailData({
								email: "",
								code: "",
							});
							toast.success(res.data.message, {
								position: "top-left",
							});
						} else {
							toast.error(res.data.message, {
								position: "top-left",
							});
						}
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
					setLoading(false);
				})
				.catch((error) => {
					toast.error(error.response?.data.message, {
						position: "top-left",
					});
					setLoading(false);
				});
		}
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (currentStep == 1) {
			handleFirstStep();
		}
		if (currentStep == 2) {
			handleSecondStep();
		}
	};

	// validations
	useEffect(() => {
		setEmailDataValid({
			...emailDataValid,
			email: EMAIL_REGEX.test(emailData.email),
			code:
				emailData.code.length > 0 && emailData.code.length <= 6 ? true : false,
		});
	}, [emailData]);

	return (
		<form className="flex flex-col space-y-4">
			{currentStep != 2 && (
				<p>Please Add your new email to send Otp,then check it</p>
			)}
			{/* new Email */}
			<div className="grid grid-cols-1 gap-4">
				{/* new Email */}
				<div className="flex flex-col space-y-1 relative">
					<label
						htmlFor="email"
						className="text-lg font-semibold text-clr-simple-bg"
					>
						New Email
					</label>
					<input
						type={"email"}
						id="email"
						name="email"
						value={emailData.email}
						onChange={handleInput}
						autoComplete="off"
						aria-invalid={emailDataValid.email ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() =>
							setEmailDataFocus({ ...emailDataFocus, email: true })
						}
						onBlur={() =>
							setEmailDataFocus({
								...emailDataFocus,
								email: false,
							})
						}
						className={classNames(
							"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
							emailDataFocus.email && !emailDataValid.email
								? "border-red-600"
								: emailDataFocus.email && emailDataValid.email
								? "border-green-600"
								: "border-clr-stroke"
						)}
						placeholder="New Email"
					/>
					<div
						id="uidnote"
						className={
							emailDataFocus.email && emailData.email && !emailDataValid.email
								? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
								: "hidden"
						}
					>
						Your Email is not valid
					</div>
				</div>
			</div>
			{/* code */}
			{currentStep == 2 && (
				<div className="grid grid-cols-1 gap-4">
					{/* code */}
					<div className="flex flex-col space-y-1 relative">
						<label
							htmlFor="code"
							className="text-lg font-semibold text-clr-simple-bg"
						>
							Code
						</label>
						<input
							type={"text"}
							id="code"
							name="code"
							value={emailData.code}
							onChange={handleInput}
							autoComplete="off"
							aria-invalid={emailDataValid.code ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() =>
								setEmailDataFocus({ ...emailDataFocus, code: true })
							}
							onBlur={() =>
								setEmailDataFocus({
									...emailDataFocus,
									code: false,
								})
							}
							className={classNames(
								"rounded-lg py-3 px-6 outline-none w-full text-lg font-medium text-clr-simple-bg border-clr-stroke border  placeholder:text-clr-light-gray placeholder:text-base placeholder:font-medium",
								emailDataFocus.code && !emailDataValid.code
									? "border-red-600"
									: emailDataFocus.code && emailDataValid.code
									? "border-green-600"
									: "border-clr-stroke"
							)}
							placeholder="Code"
						/>
						<div
							id="uidnote"
							className={
								emailDataFocus.code && emailData.code && !emailDataValid.code
									? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
									: "hidden"
							}
						>
							Your Code is not valid
						</div>
					</div>
				</div>
			)}

			<button
				onClick={handleSubmit}
				className="flex flex-row items-center justify-center space-x-1 bg-clr-primary-green text-white py-2 px-4 rounded-lg shadow-2xl hover:shadow-btnShadow font-medium text-lg w-[200px]"
			>
				<span>{currentStep == 2 ? "Save Changes" : "Send Email"}</span>
				{loading && (
					<svg
						aria-hidden="true"
						role="status"
						className="inline w-4 h-4 mr-3 text-clr-main-dark font-semibold animate-spin"
						viewBox="0 0 100 101"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							fill="#E5E7EB"
						/>
						<path
							d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							fill="currentColor"
						/>
					</svg>
				)}
			</button>
		</form>
	);
}

export default EmailForm;
