export const classNames = (...classes : any) => {
    return classes.filter(Boolean).join(' ')
}

export const getLocalStorage = (variableName : string) =>{
  let requiredVar : any = localStorage.getItem(variableName);
  switch (variableName) {
      case 'auth':
        return requiredVar ? JSON.parse(requiredVar) : {user: {} , isLoggedin : false };
      case 'flight_form':
        return requiredVar ? JSON.parse(requiredVar) : { };  
      default:
        return {}
    }
}

// modify id to make order list
export const reorderList = (arr : any) => {
  const tempArr = arr.map((currentItem : any,index : number) => {
    const itemTemp = { ...currentItem, id: 'form_'+index }
    return itemTemp
  })
  return tempArr
}

// remove item from array if exists
export const deleteItem = (arr : any, itemID :any) => {
  const tempArr = arr.filter((currentItem : any) => currentItem.id != itemID)
  return tempArr
}

// add or update element to array per key
export const addOrUpdateItem = (arr : any, item : any) => {
  let tempArr = []
  const tempItem = arr.find((currentItem : any) => currentItem.id === item.id)
  if (tempItem) {
    tempArr = arr.map((currentItem : any) => {
      if (currentItem.id === item.id) currentItem = item
      return currentItem
    })
  } else {
    tempArr = [...arr, item]
  }
  return tempArr
}

// reshap flight segments
export const reshapSegmentsArr = (segmentArr : any) => {
  const tempArr = segmentArr.map( (item : any)=> {
    return {
      DepartureDate: item.DepartureDate,
      ReturnDate: item.ReturnDate,
      Origin: item.Origin.substring(
        item.Origin.indexOf('(') + 1,
        item.Origin.indexOf('(') + 4
      ),
      Destination: item.Destination.substring(
        item.Destination.indexOf('(') + 1,
        item.Destination.indexOf('(') + 4
      ),
    }
  })
  return tempArr;
}

// get text stop by stop count
export const getTextStopByCount = (stopsArr : any) => {
  const tempArr = stopsArr.map( (item : any)=> {
    let stopText = "Any";
    if(item == -1){
      stopText = "Any"
    }else if(item == 0){
      stopText = "Direct"
    }else{
      stopText = item+" stops"
    }
    return stopText;
  })
  return tempArr;
}

// get text stop by stop count
export const getTextStopByCountforShowing = (stopCount : any) => {
  let stopText = "Direct";
    if(stopCount == 1){
      stopText = "Direct"
    }else if(stopCount == 2){
      stopText = stopCount-1+" stops"
    }else{
      stopText = "+2 stops"
    }
  return stopText;
}

// get time per minutes
export const getTimePerMinutes = (currentDate: string) => {
  const dateObject = new Date(currentDate);

  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  return hours * 60 + minutes;
};

// calculate time from string value
export const getDuration = (strMins : any , withUnit : boolean) => {
  const mins = parseInt(strMins);
  const hours = Math.floor(mins / 60);
  const restMins = mins - (hours * 60);
  const res = withUnit ? hours+" h "+restMins+" m" : hours+":"+restMins
  return res;
}

// get background color depending on status
export const getColorPerStatus = (status : string) => {
  switch (status) {
    case "used":
      return "bg-gray-500"
    case "valid":
      return "bg-green-600"
    case "partially_used":
      return "bg-blue-500"  
    case "invalid":
        return "bg-red-500"  
    default:
      break;
  }
}

// add or remove item
export const addOrRemoveItem = (arr : any , item : any) => {
  var tempArr;
  const checkedItem = arr.find( (currentItem :any) => currentItem.id == item.id)
  if(checkedItem){
    tempArr = arr.filter((currentItem :any) => currentItem.id != item.id)
  }else{
    tempArr = [...arr , item]
  }
  return tempArr;     
}

export const findIndexByPaymentMethods = (paymentMethods :any, paymentMethodsToFind : any) => {
  for (const index in paymentMethods) {
    const foundMethods = paymentMethods[index];
    if (
      paymentMethodsToFind.every((method : any) => foundMethods.includes(method))
    ) {
      return parseInt(index);
    }
  }
  return -1; // Return -1 if the combination of payment methods is not found in any index
}

export const convertArrtoBase64String  = (arr : any)=>{
  const arrString = JSON.stringify(arr);
  return btoa(arrString)
}

