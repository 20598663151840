import React, { useState, useEffect } from "react";

// lib
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Pagination } from "antd";
import { IoWalletSharp } from "react-icons/io5";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { BsCreditCardFill } from "react-icons/bs";
import { LiaAwardSolid } from "react-icons/lia";

// custom
import { axios_auth } from "../../utils/axios";
import tol_img from "../../assets/imgs/profile_tol_img.svg";
import bnb_img from "../../assets/imgs/bnb-logo.svg";
import { useMainContext } from "../../contexts/main_context";
import WalletDepositModal from "./WalletDepositModal";
import WalletWithdrawalModal from "./WalletWithdrawalModal";

function Wallet() {
	const { openCreditNoteModal, openCashbackModal } = useMainContext();
	const [balance, setBalance]: any = useState({});
	const [sumCashback, setSumCashback] = useState(0);
	const [sumCreditNote, setSumCreditNote] = useState(0);
	const [currentBookings, setCurrentBookings] = useState([]);
	const [usersTotal, setUsersTotal] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	// deposit Modal
	const [isDepositModalOpen, setDepositModal] = useState(false);
	const openDepositModal = () => {
		setDepositModal(true);
	};
	const closeDepositModal = () => {
		setDepositModal(false);
	};

	// withdrawal Modal
	const [isWithdrawalModalOpen, setWithdeawalModal] = useState(false);
	const openWithDrawalModal = () => {
		setWithdeawalModal(true);
	};
	const closeWithdrawalModal = () => {
		setWithdeawalModal(false);
	};

	const handleChangePage = (page: any, pageSize: any) => {
		setCurrentPage(page);
		setPerPage(pageSize);
	};

	const getBookingHistory = async () => {
		axios_auth(Cookies.get("token"))
			.get("/v1/wallet/web/details?page=1&perPage=" + perPage)
			.then((res) => {
				if (res.data.status === true) {
					//console.log("flight - booking", res.data.data);
					if (res.data.data) {
						setBalance(res.data.data.wallet);
						setSumCashback(res.data.data.sum_cashbacks);
						setSumCreditNote(res.data.data.sum_creditnotes);
						setCurrentBookings(res.data.data.transactions.list);
						setUsersTotal(res.data.data.transactions.total);
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				} else {
					toast.error(res.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.response?.data.message, {
					position: "top-left",
				});
			});
	};

	useEffect(() => {
		getBookingHistory();
	}, []);

	// for changing the page
	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get("/v1/wallet/web/details?page=" + currentPage + "&perPage=" + perPage)
			.then((res) => {
				if (res.data.status === true) {
					//console.log("flight - booking", res.data.data[0]);
					if (res.data.data) {
						setBalance(res.data.data.wallet);
						setSumCashback(res.data.data.sum_cashbacks);
						setSumCreditNote(res.data.data.sum_creditnotes);
						setCurrentBookings(res.data.data.transactions.list);
						setUsersTotal(res.data.data.transactions.total);
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				} else {
					toast.error(res.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.response?.data.message, {
					position: "top-left",
				});
			});
	}, [currentPage, perPage]);

	return (
		<div className="flex flex-col items-center justify-between w-full shadow-listShadow rounded-lg">
			<div className="flex flex-col space-y-5 w-[90%] mx-auto py-10">
				{/* head */}
				<div className="flex flex-col space-y-2 shadow-2xl rounded-lg bg-profile_bg_wallet bg-center bg-cover py-10 px-10">
					<div className="flex s_600:flex-row flex-col s_600:items-center items-start justify-between">
						{/* ToL */}
						<div className="flex flex-row items-center space-x-1 mb-2">
							<img src={tol_img} alt="ToLcoin" />
							<span className="text-2xl text-clr-simple-bg font-semibold">
								{`${balance.tolCoinBalance ? balance.tolCoinBalance : 0} ToL`}
							</span>
						</div>
						{/* BNB */}
						<div className="flex flex-row items-center space-x-1 mb-2">
							<img src={bnb_img} alt="bnb" className="w-10 h-10" />
							<span className="text-2xl text-clr-simple-bg font-semibold">
								{`${balance.balance ? balance.balance : 0} BNB`}
							</span>
						</div>
					</div>
					<div className="text-clr-primary-green flex flex-col items-center justify-center">
						<IoWalletSharp className="w-14 h-14 -rotate-12" />
					</div>
					<div className="flex s_600:flex-row flex-col s_600:space-x-2 items-center justify-center">
						<button
							onClick={openDepositModal}
							className="mb-2 flex flex-row items-center justify-center space-x-1 py-2 px-4 rounded-lg bg-clr-primary-green text-white text-lg font-semibold border border-clr-primary-green shadow-2xl hover:shadow-btnShadow"
						>
							<span>Deposit</span>
							<FaArrowDown />
						</button>
						<button
							onClick={openWithDrawalModal}
							className="mb-2 flex flex-row items-center justify-center space-x-1 py-2 px-4 rounded-lg bg-transparent text-clr-primary-green text-lg font-semibold border border-clr-primary-green shadow-2xl hover:shadow-btnShadow"
						>
							<span>Withdrawal</span>
							<FaArrowUp />
						</button>
					</div>
				</div>
				<div className="flex s_600:flex-row flex-col s_600:space-x-2 items-center justify-start py-5">
					<button
						onClick={openCashbackModal}
						className="mb-2 flex flex-col items-center justify-center space-y-2 py-2 px-4 rounded-lg bg-transparent text-clr-primary-green text-lg font-semibold border border-clr-primary-green shadow-2xl hover:shadow-btnShadow"
					>
						<span className="flex flex-row items-center space-x-2">
							<span>My Cashback</span>
							<LiaAwardSolid className="w-6 h-6" />
						</span>
						<span className="text-clr-simple-bg">{`${
							sumCashback ? sumCashback : 0
						} `}</span>
					</button>
					<button
						onClick={openCreditNoteModal}
						className="mb-2 flex flex-col items-center justify-center space-y-2 py-2 px-4 rounded-lg bg-transparent text-clr-primary-green text-lg font-semibold border border-clr-primary-green shadow-2xl hover:shadow-btnShadow"
					>
						<span className="flex flex-row items-center space-x-2">
							<span>My CreditNote</span>
							<BsCreditCardFill />
						</span>
						<span className="text-clr-simple-bg">{`${
							sumCreditNote ? sumCreditNote : 0
						} `}</span>
					</button>
				</div>
				<h1 className="text-2xl text-clr-simple-bg font-semibold">
					Wallet Transaction History
				</h1>
				{/* table */}
				<div className="flex flex-col overflow-x-auto min-w-full w-full rounded-lg mb-10 max-h-[500px] overflow-y-auto">
					{currentBookings.length > 0 && (
						<table className="border-collapse w-full mx-auto rounded-t-xl overflow-x-auto min-w-[680px] py-2">
							<thead className="bg-clr-stroke text-clr-simple-bg font-semibold items-center text-center rounded-t-xl ">
								<tr className="rounded-t-xl ">
									<th className="py-2">hash</th>
									<th className="">Amount</th>
									<th className="">Date</th>
								</tr>
							</thead>
							<tbody>
								{currentBookings.map((item: any) => {
									return (
										<tr key={item.id}>
											<td className="py-3 font-medium border border-clr-stroke-gray flex flex-col items-center justify-center">
												{item.hash}
											</td>
											<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
												{item.amount}
											</td>
											<td className="py-2 px-1  text-center text-clr-primary-gray font-medium border border-clr-stroke-gray">
												{item.date}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</div>

				{currentBookings.length > 0 && (
					<div className="flex flex-col items-center">
						<Pagination
							className={"custom-pagination "}
							defaultCurrent={currentPage}
							total={usersTotal}
							pageSize={perPage}
							onChange={handleChangePage}
							pageSizeOptions={[10, 20, 50, 100]}
							hideOnSinglePage={false}
							showSizeChanger={true}
						/>
					</div>
				)}

				{currentBookings.length == 0 && (
					<div className="flex flex-col items-center justify-center py-14">
						<p className="text-center text-clr-simple-bg font-semibold text-3xl">
							There is no transaction history
						</p>
					</div>
				)}
			</div>
			<WalletDepositModal
				isActive={isDepositModalOpen}
				setDisActive={closeDepositModal}
			/>
			<WalletWithdrawalModal
				isActive={isWithdrawalModalOpen}
				setDisActive={closeWithdrawalModal}
			/>
		</div>
	);
}

export default Wallet;
