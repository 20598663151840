import React from "react";

function SkeletonSort() {
	return (
		<div className="flex flex-col space-y-3 animate-pulse w-full py-5 rounded-lg ">
			{/* flight numbers */}
			<div className="flex flex-row justify-between bg-gray-200 w-full mx-auto rounded-lg">
				<div className="flex flex-row space-x-4 bg-gray-200 w-[80%] mx-auto py-4">
					<h1 className="text-clr-simple-bg flex flex-row items-center space-x-2 bg-gray-200 w-full"></h1>
					<p className="text-clr-simple-bg font-medium text-lg "></p>
				</div>
			</div>
			<div className="flex flex-row justify-between bg-gray-200 w-full mx-auto rounded-lg">
				<div className="flex flex-row space-x-4 bg-gray-200 w-[80%] mx-auto py-4">
					<h1 className="text-clr-simple-bg flex flex-row items-center space-x-2 bg-gray-200 w-full"></h1>
					<p className="text-clr-simple-bg font-medium text-lg "></p>
				</div>
			</div>
		</div>
	);
}

export default SkeletonSort;
