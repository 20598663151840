import React, { useState } from "react";

// custom
import { experiences } from "../../../utils/constants";
import { classNames } from "../../../utils/helpers";
import FlightForm from "./Flight/FlightForm";

function FormList() {
	const [currentForm, setCurrentForm] = useState(1);

	return (
		<div className="max-w-custom-full w-smallWidth mx-auto bg-white rounded-3xl shadow-formsShadow z-30 absolute s_1000:-top-36 md:-top-52 s_600:-top-[340px] -top-[420px]">
			<ul className="flex flex-row items-center space-x-0 border-b max-w-custom-full overflow-x-auto">
				{experiences.map((item: any, index: number) => {
					return (
						<li
							key={item.id}
							className={classNames(
								"flex flex-row items-center py-3 px-6 space-x-1 cursor-pointer",
								index + 1 == currentForm
									? "md:border-b border-clr-primary-green"
									: "border-none"
							)}
							onClick={() => setCurrentForm(index + 1)}
						>
							<img
								src={index + 1 == currentForm ? item.icon : item.icon_sm}
								alt={item.title}
								className="w-5 h-5"
							/>
							<span
								className={classNames(
									"text-base ",
									index + 1 == currentForm
										? "text-clr-simple-bg font-semibold"
										: "text-clr-primary-gray font-medium"
								)}
							>
								{item.title}
							</span>
						</li>
					);
				})}
			</ul>
			<div className="">
				<FlightForm />
			</div>
		</div>
	);
}

export default FormList;
