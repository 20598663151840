import React, { useState, useRef, useEffect } from "react";

//lib
import { format } from "date-fns";
import { Calendar } from "react-date-range";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { BsFillPersonFill, BsImages } from "react-icons/bs";
import { FaCalendar } from "react-icons/fa";

//custom
import UseRemoveInputFocus from "../../../hooks/useRemoveInputFocus";
import { useAuthContext } from "../../../contexts/auth_context";
import { axios_auth, axios } from "../../../utils/axios";
import { getLocalStorage } from "../../../utils/helpers";

function PersonalForm() {
	const { user } = useAuthContext();
	const [personalData, setPersonalData] = useState({
		first_name: user.user.firstName,
		last_name: user.user.lastName,
		nationality: user.user.information.nationality,
		birthday: user.user.information.birthday,
	});

	const handleInput = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		//console.log("input", name, value);
		setPersonalData({ ...personalData, [name]: value });
	};

	// console.log(user);

	// for birthday
	const birthdayRef: any = useRef();
	const [isOpenbirthday, setOpenBirthday] = useState(false);
	const [birthdayDate, setBirthdayDate] = useState(
		format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
	);
	const handleSelectBirthday = (date: any) => {
		setBirthdayDate(format(date, "yyyy-MM-dd"));
		setPersonalData({
			...personalData,
			birthday: format(date, "yyyy-MM-dd"),
		});
		setOpenBirthday(false);
	};
	UseRemoveInputFocus(birthdayRef, () => setOpenBirthday(false));

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		axios_auth(Cookies.get("token"))
			.post("/user/profile/change-personal-info", personalData)
			.then((res) => {
				if (res.data.status === true) {
					//console.log("flight - booking", res.data.data);
					if (res.data.data) {
						const currentAuth = {
							user: res.data.data,
							isLoggedin: user.isLoggedin,
							keySaved: user.keySaved,
						};
						localStorage.setItem("auth", JSON.stringify(currentAuth));
						toast.success(res.data.message, {
							position: "top-left",
						});
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				} else {
					toast.error(res.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.response?.data.message, {
					position: "top-left",
				});
			});
	};

	// img
	const [preview, setPreview] = useState(
		getLocalStorage("auth").user.profile_photo_url
	);
	//fun for manage when fill data picture field
	const handleProfilePic = (e: any) => {
		const newFile = Object.values(e.target.files);
		//console.log("pic", newFile[0]);
		axios
			.post(
				`/user/v1/profile/photo/change`,
				{ image: newFile[0] },
				{
					headers: {
						"X-Requested-With": "XMLHttpRequest",
						"Content-type": "Application/json",
						Authorization: "Bearer " + Cookies.get("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.then((res) => {
				if (res.data.status === true) {
					//console.log("flight - booking", res.data.data);
					if (res.data.data) {
						const currentAuth = {
							user: res.data.data,
							isLoggedin: user.isLoggedin,
							keySaved: user.keySaved,
						};
						setPreview(res.data.data.profile_photo_url);
						localStorage.setItem("auth", JSON.stringify(currentAuth));
						toast.success(res.data.message, {
							position: "top-left",
						});
					} else {
						toast.error(res.data.message, {
							position: "top-left",
						});
					}
				} else {
					toast.error(res.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.response?.data.message, {
					position: "top-left",
				});
			});

		// setpersonalData({ ...personalData, image: newFile[0] });
		// setPreview(URL.createObjectURL(newFile[0]));
	};

	useEffect(() => {}, [preview]);

	return (
		<form className="flex flex-col space-y-4">
			{/* imgage */}
			<div className="flex flex-row space-x-4 w-full border-b pb-5">
				{/* shap */}
				<div className="w-[150px] h-[150px] border-2 border-clr-light-gray border-dashed rounded-md flex flex-col items-center justify-center">
					{preview.length > 0 ? (
						<img src={preview} alt="profile photo" />
					) : (
						<BsFillPersonFill className="text-clr-light-gray w-16 h-16" />
					)}
				</div>
				{/* input */}
				<div className="flex flex-col space-y-6 items-start justify-start">
					<div className="flex flex-col space-y-1">
						<h3 className="text-clr-simple-bg text-lg font-semibold">
							Your Profile
						</h3>
						<p className="text-sm text-clr-primary-gray font-medium">
							PNG or JPG no bigger than 800px wide and tall.
						</p>
					</div>

					<div className="flex relative w-full h-10 cursor-pointer">
						<label
							htmlFor="image"
							className="cursor-pointer flex flex-row items-center justify-center space-x-1 py-5 px-6 border border-clr-primary-green rounded-md"
						>
							<BsImages className="text-clr-primary-green " />
							<span className="text-clr-primary-green font-semibold text-lg">
								Browse
							</span>
						</label>
						<input
							type="file"
							name="image"
							id="image"
							onChange={handleProfilePic}
							className="absolute top-0 left-0 w-[130px] h-full cursor-pointer opacity-0"
						/>
					</div>
				</div>
			</div>
			{/* names */}
			<div className="grid s_900:grid-cols-2 s_600:grid-cols-2 grid-cols-1 gap-4">
				{/* first name */}
				<div className="flex flex-col space-y-1">
					<label
						htmlFor="firstName"
						className="text-lg font-semibold text-clr-simple-bg"
					>
						First Name
					</label>
					<input
						id="firstName"
						type="text"
						placeholder="First Name"
						name="first_name"
						value={personalData.first_name}
						onChange={handleInput}
						className="border border-clr-stroke py-2 px-2 rounded-md placeholder:text-sm placeholder:text-clr-light-gray"
					/>
				</div>

				{/* last name */}
				<div className="flex flex-col space-y-1">
					<label
						htmlFor="lastName"
						className="text-lg font-semibold text-clr-simple-bg"
					>
						Last Name
					</label>
					<input
						id="lastName"
						type="text"
						placeholder="Last Name"
						name="last_name"
						value={personalData.last_name}
						onChange={handleInput}
						className="border border-clr-stroke py-2 px-2 rounded-md placeholder:text-sm placeholder:text-clr-light-gray"
					/>
				</div>
			</div>
			{/* birthday - nationality */}
			<div className="grid s_900:grid-cols-2 s_600:grid-cols-2 grid-cols-1 gap-4">
				{/* birthday  */}
				<div className="flex flex-col space-y-1">
					<label
						htmlFor="birthday"
						className="text-lg font-semibold text-clr-simple-bg"
					>
						Birthday
					</label>
					<div className="flex flex-row items-center space-x-1 relative py-[10px] px-2 rounded-md  border border-clr-stroke">
						<span className={""}>
							<FaCalendar className={"text-clr-primary-green"} />
						</span>
						<input
							className={
								"placeholder:text-sm placeholder:text-clr-light-gray text-sm text-clr-simple-bg appearance-none outline-none w-full h-full"
							}
							id="birthday"
							placeholder="Birthday"
							type="text"
							name="birthday"
							autoComplete="off"
							value={personalData.birthday}
							onChange={() => {}}
							onClick={() => setOpenBirthday(!isOpenbirthday)}
							readOnly
						/>
						{isOpenbirthday && (
							<div
								ref={birthdayRef}
								className="bg-white rounded-lg z-20 shadow-listShadow absolute top-10 py-1 px-1"
							>
								<Calendar
									date={new Date()}
									onChange={handleSelectBirthday}
									// minDate={new Date()}
									className={"text-clr-primary-green "}
								/>
							</div>
						)}
					</div>
				</div>

				{/* nationality */}
				<div className="flex flex-col space-y-1">
					<label
						htmlFor="nationality"
						className="text-lg font-semibold text-clr-simple-bg"
					>
						Nationality
					</label>
					<input
						id="nationality"
						type="text"
						placeholder="Nationality"
						name="nationality"
						value={personalData.nationality}
						onChange={handleInput}
						className="border border-clr-stroke py-2 px-2 rounded-md placeholder:text-sm placeholder:text-clr-light-gray"
					/>
				</div>
			</div>
			<button
				onClick={handleSubmit}
				className="flex flex-row items-center justify-center space-x-1 bg-clr-primary-green text-white py-2 px-4 rounded-lg shadow-2xl hover:shadow-btnShadow font-medium text-lg w-[150px]"
			>
				<span>submit</span>
			</button>
		</form>
	);
}

export default PersonalForm;
