import React, { useState } from "react";

// lib
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
// import required modules
import {
	Navigation,
	Autoplay,
	Pagination,
	FreeMode,
	Thumbs,
} from "swiper/modules";
import { testimonials } from "../../utils/constants";

// swiper hook
SwiperCore.use([Autoplay, Navigation, Pagination, FreeMode, Thumbs]);

function TestimonialsSlider() {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	const swiperParams: any = {
		grabCursor: true,
		centeredSlides: true,
		autoplay: { delay: 3000 },
		slidesPerView: "auto",
		loop: true,
		navigation: true,
		pagination: {
			dynamicBullets: true,
		},
		thumbs: { swiper: thumbsSwiper },
		//onSwiper: (swiper: any) => console.log(swiper),
		//onLoad: (swiper: any) => console.log("load", swiper),
	};

	const swiperThumbParams: any = {
		grabCursor: true,
		centeredSlides: true,
		autoplay: { delay: 3000 },
		slidesPerView: "auto",
		loop: true,
		thumbs: { swiper: thumbsSwiper },
		onSwiper: (swiper: any) => setThumbsSwiper(swiper),
	};

	return (
		<div
			className="text-clr-simple-bg  w-full h-[360px]"
			id="testimonials_slider"
		>
			{/* base */}
			<Swiper {...swiperParams} className="h-full">
				{testimonials.map((item: any) => {
					return (
						<SwiperSlide
							className="flex flex-col items-center justify-center"
							key={item.id}
						>
							<div className="bg-home_bg_testimonials_text  bg-no-repeat bg-center s_600:h-full h-fit s_600:py-10  px-5 mx-auto text-center flex flex-col items-center space-y-3">
								<h3 className="text-xl text-clr-primary-gray font-bold mt-10 mb-4">
									{item.title}
								</h3>
								<p className="text-sm text-clr-light-gray font-normal lg:w-[40%] s_600:w-[60%] text-start">
									{item.text}
								</p>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
			{/* thumb */}
			{/* <Swiper {...swiperThumbParams} className="mySwiper">
				{testimonials.map((item: any, index) => {
					return (
						<SwiperSlide className="" key={`${item.id}_${index}`}>
							<img className="" src={item.icon} alt={`Thumb ${index}`} />
						</SwiperSlide>
					);
				})}
			</Swiper> */}
		</div>
	);
}

export default TestimonialsSlider;
