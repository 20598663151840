import React, { useState, useRef, useEffect } from "react";

// lib
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Calendar } from "react-date-range";
import { format } from "date-fns";
import { FaCalendar } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// custom
import { classNames, getLocalStorage } from "../../../utils/helpers";
import UseRemoveInputFocus from "../../../hooks/useRemoveInputFocus";
import { useFlightContext } from "../../../contexts/flight_context";

//regex
const STRING_REGEX = /^[A-z][A-z0-9-_ ]{2,23}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/;

function FlightPassengersCard({ type, level }: any) {
	const [showDetails, setShowDetails] = useState(false);
	const { setPassengers, passengers } = useFlightContext();

	const currentID = type + "_" + level;
	const currentPassenger = passengers.find((item: any) => item.id == currentID);
	//console.log("currentPassenger", currentPassenger);

	// passenger data
	const [passengerData, setPassengerData] = useState({
		id: type + "_" + level,
		title: "Mr",
		is_lead_pax: type == "Adult" && level == 0 ? "1" : "0",
		pax_type: type == "Adult" ? "1" : type == "Child" ? "2" : "3",
		first_name: currentPassenger?.first_name || "",
		last_name: currentPassenger?.last_name || "",
		email: currentPassenger?.email || "",
		passport_number: currentPassenger?.passport_number || "",
		nationality: currentPassenger?.nationality || "Pakistani",
		gender: currentPassenger?.gender || "male",
		phone: currentPassenger?.phone || "",
		country_code: "pk",
		country_name: "Pakistan",
		city: "Karachi",
		address_one: "123 Main Street",
		birthday: currentPassenger?.birthday || "", // "1997-01-01"
		passport_expire_at: currentPassenger?.passport_expire_at || "", //"2024-01-01";
	});

	// passenger data validation
	const [passengerDataValidation, setPassengerDataValidation] = useState({
		id: true,
		title: true,
		is_lead_pax: true, //"0"
		pax_type: true, //"1"
		first_name: false,
		last_name: false,
		email: false,
		passport_number: false,
		nationality: true,
		gender: true,
		phone: false,
		country_code: true,
		country_name: true,
		city: true,
		address_one: true,
		birthday: false,
		passport_expire_at: false,
	});

	const handleInput = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		//console.log("input", name, value);
		setPassengerData({ ...passengerData, [name]: value });
	};

	// get current search data
	const currentSearchData = getLocalStorage("flight_form");

	//for gender
	const handleGenderRadio = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		//console.log("input", name, value);
		setPassengerData({ ...passengerData, gender: value });
	};

	// for birthday
	const birthdayRef: any = useRef();
	const [isOpenbirthday, setOpenBirthday] = useState(false);
	const [birthdayDate, setBirthdayDate] = useState(
		format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
	);
	const handleSelectBirthday = (date: any) => {
		setBirthdayDate(format(date, "yyyy-MM-dd"));
		setPassengerData({
			...passengerData,
			birthday: format(date, "yyyy-MM-dd"),
		});
		setOpenBirthday(false);
	};
	UseRemoveInputFocus(birthdayRef, () => setOpenBirthday(false));

	// phone
	const [phoneVal, setPhoneVal] = useState("");
	const handelphone = (value: string) => {
		const name = "phone";
		setPhoneVal(value);

		setPassengerData({
			...passengerData,
			[name]: value,
		});
	};

	// for passport date
	const passportDateRef: any = useRef();
	const [isOpenPassportDate, setOpenPassportDate] = useState(false);
	const [passportDate, setPassportDate] = useState(
		format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
	);
	const handleSelectPassportDate = (date: any) => {
		setPassportDate(format(date, "yyyy-MM-dd"));
		setPassengerData({
			...passengerData,
			passport_expire_at: format(date, "yyyy-MM-dd"),
		});
		setOpenPassportDate(false);
	};
	UseRemoveInputFocus(passportDateRef, () => setOpenPassportDate(false));

	// keep first card open
	useEffect(() => {
		if (type == "Adult" && level == 0) {
			setShowDetails(true);
		}
	}, [type]);

	//validation
	useEffect(() => {
		setPassengerDataValidation({
			...passengerDataValidation,
			first_name: STRING_REGEX.test(passengerData.first_name),
			last_name: STRING_REGEX.test(passengerData.last_name),
			email: EMAIL_REGEX.test(passengerData.email),
			passport_number: passengerData.passport_number.length > 0 ? true : false,
			nationality: STRING_REGEX.test(passengerData.nationality),
			phone: PHONE_REGEX.test(passengerData.phone),
			birthday: passengerData.birthday.length > 0 ? true : false,
			passport_expire_at:
				passengerData.passport_expire_at.length > 0 ? true : false,
		});
	}, [passengerData, phoneVal, passportDate, birthdayDate]);

	useEffect(() => {
		if (
			passengerDataValidation.first_name &&
			passengerDataValidation.last_name &&
			passengerDataValidation.email &&
			passengerDataValidation.passport_number &&
			passengerDataValidation.nationality &&
			passengerDataValidation.gender &&
			passengerDataValidation.phone &&
			passengerDataValidation.birthday &&
			passengerDataValidation.passport_expire_at
		) {
			setPassengers(passengerData);
			//console.log("passenger", passengerData, passengerDataValidation);
		}
	}, [passengerDataValidation]);

	return (
		<div className="py-2 px-8 bg-white shadow-2xl shadow-clr-primary-gray rounded-md relative flex flex-col">
			<h1 className="text-clr-simple-bg text-lg font-semibold py-8">
				{type == "Child"
					? `Passenger ${level + 1 + currentSearchData.AdultCount}: (${type})`
					: type == "Infant"
					? `Passenger ${
							level +
							1 +
							currentSearchData.AdultCount +
							+currentSearchData.ChildCount
					  }: (${type})`
					: `Passenger ${level + 1}: (${type})`}
			</h1>
			<div
				className={classNames(
					" flex-col space-y-5 border-t pt-10 pb-16",
					showDetails ? "flex" : "hidden"
				)}
			>
				{/* row */}
				<div className="flex flex-col space-y-1">
					<span className="text-lg font-semibold text-clr-simple-bg">
						Gender *
					</span>
					<div className="flex flex-row space-x-6">
						<div className="flex flex-row space-x-1">
							<input
								id={`${type}_${level}_male`}
								type="radio"
								value="male"
								onChange={(e) => handleGenderRadio(e)}
								name={`${type}_${level}_gender`}
								checked={passengerData.gender === "male"}
							/>
							<label
								htmlFor={`${type}_${level}_male`}
								className="text-clr-simple-bg text-sm font-medium cursor-pointer"
							>
								Male
							</label>
						</div>
						<div className="flex flex-row space-x-1">
							<input
								id={`${type}_${level}_female`}
								type="radio"
								value="female"
								onChange={(e) => handleGenderRadio(e)}
								name={`${type}_${level}_gender`}
								checked={passengerData.gender === "female"}
							/>
							<label
								htmlFor={`${type}_${level}_female`}
								className="text-clr-simple-bg text-sm font-medium cursor-pointer"
							>
								Female
							</label>
						</div>
					</div>
				</div>
				{/* row */}
				<div className="grid s_900:grid-cols-3 s_500:grid-cols-2 grid-cols-1 gap-4">
					{/* first name */}
					<div className="flex flex-col space-y-1">
						<label
							htmlFor="firstName"
							className="text-lg font-semibold text-clr-simple-bg"
						>
							First Name *
						</label>
						<input
							id="firstName"
							type="text"
							placeholder="First Name"
							name="first_name"
							value={passengerData.first_name}
							onChange={handleInput}
							className="border border-clr-stroke py-2 px-2 rounded-md placeholder:text-sm placeholder:text-clr-light-gray"
						/>
					</div>

					{/* middle name */}
					<div className="flex flex-col space-y-1">
						<label
							htmlFor="middleName"
							className="text-lg font-semibold text-clr-simple-bg"
						>
							Middle Name *
						</label>
						<input
							id="middleName"
							type="text"
							placeholder="Middle Name"
							className="border border-clr-stroke py-2 px-2 rounded-md placeholder:text-sm placeholder:text-clr-light-gray"
						/>
					</div>

					{/* last name */}
					<div className="flex flex-col space-y-1">
						<label
							htmlFor="lastName"
							className="text-lg font-semibold text-clr-simple-bg"
						>
							Last Name *
						</label>
						<input
							id="lastName"
							type="text"
							placeholder="Last Name"
							name="last_name"
							value={passengerData.last_name}
							onChange={handleInput}
							className="border border-clr-stroke py-2 px-2 rounded-md placeholder:text-sm placeholder:text-clr-light-gray"
						/>
					</div>
				</div>

				{/* row */}
				<div className="grid s_900:grid-cols-3 s_500:grid-cols-2 grid-cols-1 gap-4">
					{/* birthday  */}
					<div className="flex flex-col space-y-1">
						<label
							htmlFor="birthday"
							className="text-lg font-semibold text-clr-simple-bg"
						>
							Birthday *
						</label>
						<div className="flex flex-row items-center space-x-1 relative py-[10px] px-2 rounded-md  border border-clr-stroke">
							<span className={""}>
								<FaCalendar className={"text-clr-primary-green"} />
							</span>
							<input
								className={
									"placeholder:text-sm placeholder:text-clr-light-gray text-sm text-clr-simple-bg appearance-none outline-none"
								}
								id="birthday"
								placeholder="Birthday"
								type="text"
								name="birthday"
								autoComplete="off"
								value={passengerData.birthday}
								onChange={() => {}}
								onClick={() => setOpenBirthday(!isOpenbirthday)}
								readOnly
							/>
							{isOpenbirthday && (
								<div
									ref={birthdayRef}
									className="bg-white rounded-lg z-20 shadow-listShadow absolute top-10 py-1 px-1"
								>
									<Calendar
										date={new Date()}
										onChange={handleSelectBirthday}
										// minDate={new Date()}
										className={"text-clr-primary-green "}
									/>
								</div>
							)}
						</div>
					</div>

					{/* nationality */}
					<div className="flex flex-col space-y-1">
						<label
							htmlFor="nationality"
							className="text-lg font-semibold text-clr-simple-bg"
						>
							Nationality *
						</label>
						<input
							id="nationality"
							type="text"
							placeholder="Nationality"
							name="nationality"
							value={passengerData.nationality}
							onChange={handleInput}
							className="border border-clr-stroke py-2 px-2 rounded-md placeholder:text-sm placeholder:text-clr-light-gray"
						/>
					</div>

					{/* phone */}
					<div className="flex flex-col space-y-1">
						<label
							htmlFor="phone"
							className="text-lg font-semibold text-clr-simple-bg"
						>
							Phone *
						</label>
						<PhoneInput
							country={"ae"}
							value={passengerData.phone}
							onChange={(phone) => handelphone(phone)}
							autoFormat={false}
							inputProps={{
								name: "phone",
								id: "phone",
								required: true,
								autoFocus: true,
							}}
							inputStyle={{
								background: "#fff",
								color: "#002B56",
								fontSize: "18px",
								padding: "20px 48px",
								width: "100%",
								border: "#E1F2EC solid 1px",
							}}
							buttonClass="countryClassBtn"
							buttonStyle={{
								background: "transparent",
								border: "none",
								fontSize: "18px",
								textAlign: "center",
								padding: "10px 10px",
							}}
							dropdownClass="countryClassDropdown"
							dropdownStyle={{
								background: "#1ABB9A",
								border: "1px solid #1ABB9A",
								borderRadius: "10px",
								padding: "10px 00px",
								display: "flex",
								flexDirection: "column",
								alignItems: "start",
							}}
						/>
					</div>
				</div>

				{/* row */}
				<div className="grid s_900:grid-cols-3 s_500:grid-cols-2 grid-cols-1 gap-4">
					{/* email */}
					<div className="flex flex-col space-y-1">
						<label
							htmlFor="email"
							className="text-lg font-semibold text-clr-simple-bg"
						>
							Email *
						</label>
						<input
							id="email"
							type="email"
							name="email"
							value={passengerData.email}
							onChange={handleInput}
							className="border border-clr-stroke py-2 px-2 rounded-md"
						/>
					</div>
					<p className="text-clr-light-gray text-sm font-normal flex items-end pb-2">
						Booking confirmation will be sent to this email address.
					</p>
				</div>

				{/* row */}
				<div className="flex flex-col space-y-2">
					<h1 className="text-xl text-clr-primary-green font-semibold">
						Passport Details
					</h1>
					<p className="text-clr-primary-gray text-sm font-normal">
						Please enter details exactly as they appear on your passport/travel
						document.
					</p>
					<div className="grid s_900:grid-cols-3 s_500:grid-cols-2 grid-cols-1 gap-4">
						{/* nationality */}
						<div className="flex flex-col space-y-1">
							<label
								htmlFor="passportNumber"
								className="text-lg font-semibold text-clr-simple-bg"
							>
								Passport Number *
							</label>
							<input
								id="passportNumber"
								type="text"
								placeholder="Passport Number"
								name="passport_number"
								value={passengerData.passport_number}
								onChange={handleInput}
								className="border border-clr-stroke py-2 px-2 rounded-md placeholder:text-sm placeholder:text-clr-light-gray"
							/>
						</div>

						{/* passport expiration  */}
						<div className="flex flex-col space-y-1">
							<label
								htmlFor="passportExpiration"
								className="text-lg font-semibold text-clr-simple-bg"
							>
								Passport Expiration *
							</label>
							<div className="flex flex-row items-center space-x-1 relative py-[10px] px-2 rounded-md  border border-clr-stroke">
								<span className={""}>
									<FaCalendar className={"text-clr-primary-green"} />
								</span>
								<input
									className={
										"placeholder:text-sm placeholder:text-clr-light-gray text-sm text-clr-simple-bg appearance-none outline-none"
									}
									id="passportExpiration"
									placeholder="Passport Expiration"
									type="text"
									name="passportExpiration"
									autoComplete="off"
									value={passengerData.passport_expire_at}
									onChange={() => {}}
									onClick={() => setOpenPassportDate(!isOpenPassportDate)}
									readOnly
								/>
								{isOpenPassportDate && (
									<div
										ref={passportDateRef}
										className="bg-white rounded-lg z-20 shadow-listShadow absolute top-10 py-1 px-1"
									>
										<Calendar
											date={new Date()}
											onChange={handleSelectPassportDate}
											minDate={new Date()}
											className={"text-clr-primary-green "}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<span
				className="bg-white py-1 px-4 rounded-full shadow-xl shadow-clr-primary-gray cursor-pointer w-5 flex flex-col items-center justify-center absolute -bottom-3 left-1/2"
				onClick={() => setShowDetails(!showDetails)}
			>
				{showDetails ? (
					<IoIosArrowUp className="w-6 h-6" />
				) : (
					<IoIosArrowDown className="w-6 h-6" />
				)}
			</span>
		</div>
	);
}

export default FlightPassengersCard;
