import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

// lib
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//custom
import {
	About,
	BookingSuccess,
	Contact,
	Error,
	FAQs,
	Home,
	Policy,
	Terms,
} from "./pages/Static";
import { Footer, Navbar, Sidebar } from "./components/Shared";
import { useAuthContext } from "./contexts/auth_context";
import {
	ForgetPassword,
	Login,
	PrivatKey,
	Register,
	ResetPassword,
	Verify,
} from "./pages/Auth";
import { useMainContext } from "./contexts/main_context";
import { Flight, FlightBooking } from "./pages/Flight";
import PrivateKeyModal from "./components/Shared/PrivateKeyModal";
import CreditNoteModal from "./components/Shared/CreditNoteModal";
import CashbackModal from "./components/Shared/CashbackModal";
import { DeleteAccount, Profile } from "./pages/User";

function App() {
	const { user } = useAuthContext();
	const { theme, prevPage } = useMainContext();

	return (
		<Router>
			<ToastContainer />
			{theme.navShow && <Navbar />}
			<Sidebar />
			<Routes>
				{/* base pages */}
				<Route path="/" element={<Home />}>
					{" "}
				</Route>
				<Route path="/about" element={<About />}>
					{" "}
				</Route>
				<Route path="/contact" element={<Contact />}>
					{" "}
				</Route>

				<Route path="/policy" element={<Policy />}>
					{" "}
				</Route>
				<Route path="/terms" element={<Terms />}>
					{" "}
				</Route>
				<Route path="/faqs" element={<FAQs />}>
					{" "}
				</Route>

				{/* success */}
				<Route
					path="/booking-sucess"
					element={
						user.isLoggedin ? (
							<BookingSuccess />
						) : (
							<Navigate to="/flights"></Navigate>
						)
					}
				>
					{" "}
				</Route>

				{/* flight pages */}
				<Route path="/flights" element={<Flight />}>
					{" "}
				</Route>
				<Route
					path="/flights-booking"
					element={
						user.isLoggedin ? (
							<FlightBooking />
						) : (
							<Navigate to="/login"></Navigate>
						)
					}
				>
					{" "}
				</Route>

				{/* user */}
				{/* profile */}
				<Route
					path="/user/profile"
					element={
						user.isLoggedin ? <Profile /> : <Navigate to="/login"></Navigate>
					}
				>
					{" "}
				</Route>
				{/* delete account */}
				<Route
					path="/user/delete"
					element={
						user.isLoggedin ? (
							<DeleteAccount />
						) : (
							<Navigate to="/login"></Navigate>
						)
					}
				>
					{" "}
				</Route>

				{/* auth */}
				{/* register */}
				<Route
					path="/register"
					element={
						!user.isLoggedin ? <Register /> : <Navigate to="/"></Navigate>
					}
				>
					{" "}
				</Route>
				{/* login */}
				<Route
					path="/login"
					element={!user.isLoggedin ? <Login /> : <Navigate to="/"></Navigate>}
				>
					{" "}
				</Route>
				{/* verify */}
				<Route
					path="/verify"
					element={
						// <Verify />
						!user.isLoggedin ? (
							<Verify />
						) : user.keySaved ? (
							<Navigate to={prevPage}></Navigate>
						) : (
							<Navigate to="/private-key"></Navigate>
						)
					}
				>
					{" "}
				</Route>
				{/* forget password */}
				<Route
					path="/forget-password"
					element={
						!user.isLoggedin ? <ForgetPassword /> : <Navigate to="/"></Navigate>
					}
				>
					{" "}
				</Route>
				{/* private key */}
				<Route
					path="/private-key"
					element={
						user.isLoggedin ? <PrivatKey /> : <Navigate to="/login"></Navigate>
					}
				>
					{" "}
				</Route>
				{/* reset password */}
				<Route
					path="/reset-password"
					element={
						!user.isLoggedin ? <ResetPassword /> : <Navigate to="/"></Navigate>
					}
				>
					{" "}
				</Route>

				{/* error - unkown pages */}
				<Route path="/*" element={<Error />}>
					{" "}
				</Route>
			</Routes>
			{theme.footerShow && <Footer />}
			<PrivateKeyModal />
			{user.isLoggedin && <CreditNoteModal />}
			{user.isLoggedin && <CashbackModal />}
		</Router>
	);
}

export default App;
