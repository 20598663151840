import React from "react";

// lib
import { FaUserAlt } from "react-icons/fa";
import { BsCalendar4, BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";

// custom
import Airplane from "../../assets/imgs/airplane_icon.svg";
import spot_d from "../../assets/imgs/g_spot_d.svg";
import spot_u from "../../assets/imgs/g_spot_u.svg";
import { packages } from "../../utils/constants";

function Packages() {
	const getStarsRate = (stars: number) => {
		const tempStars = Array.from({ length: 5 }, (_, index) => {
			const number = index + 0.5;
			return (
				<span key={index} className="mr-1 text-base text-primary-clr-star">
					{stars >= index + 1 ? (
						<BsStarFill />
					) : stars >= number ? (
						<BsStarHalf />
					) : (
						<BsStar />
					)}
				</span>
			);
		});
		return tempStars;
	};

	return (
		<div className="relative flex flex-col items-center justify-center pb-10">
			<img src={spot_u} alt="spot" className="absolute top-32 right-20 -z-10" />
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col space-y-10 mt-10">
				{/* desc */}
				<div className="flex flex-col items-center justify-center space-y-6">
					<h1 className="text-clr-simple-bg font-bold md:text-6xl text-4xl flex flex-row items-start space-x-0 w-[60%] md:leading-[4.2rem] text-center">
						<img src={Airplane} alt="airline" className="w-16 ml-2" />
						You’re halfway there.
					</h1>
					<p className="text-clr-primary-gray text-lg font-normal text-center w-[78%] mx-auto">
						We aim to inspire. View our curated travel packages for select
						destinations, complete with must-see attractions and things to do.
					</p>
				</div>
				{/* cards */}
				<div className="grid lg:grid-cols-3 s_700:grid-cols-2 grid-cols-1 gap-10">
					{packages.map((item: any) => {
						return (
							<div
								key={item.id}
								className="flex flex-col space-y-14 relative bg-white rounded-lg"
							>
								{/* imgs */}
								<div className="flex flex-col relative">
									<img src={item.img} alt="card image" />
									<span className="p-[0.1px] bg-white absolute -bottom-10 left-5 rounded-full shadow-2xl">
										<img
											src={item.icon}
											alt="card image"
											className="w-20 shadow-picShadow"
										/>
									</span>
								</div>
								{/* details */}
								<div className="flex flex-col relative space-y-3 ">
									{/* duration & people */}
									<div className="flex flex-row space-x-16">
										{/* duration */}
										<p className="flex flex-row items-center justify-center space-x-1 text-clr-light-gray font-normal text-base">
											<span>
												<BsCalendar4 />
											</span>
											<span>{item.duration}</span>
										</p>
										{/* people */}
										<p className="flex flex-row items-center justify-center space-x-1 text-clr-light-gray font-normal text-base">
											<span>
												<FaUserAlt />
											</span>
											<span>{item.people}</span>
										</p>
									</div>
									{/* title & rate */}
									<div className="flex flex-row items-center justify-between">
										<h1 className="text-clr-simple-bg text-2xl font-bold">
											{item.title}
										</h1>
										<span className="flex flex-row text-clr-gold">
											{getStarsRate(item.rate)}
										</span>
									</div>
									{/* prices */}
									<div className="flex flex-row space-x-6">
										{/* old */}
										<p className="text-lg font-semibold text-clr-primary-green">
											{item.new_price}
										</p>
										{/* new */}
										<p className="text-lg font-semibold text-clr-light-gray line-through">
											{item.old_price}
										</p>
									</div>
									{/* text */}
									<p className="text-sm w-[90%] text-clr-simple-bg font-normal">
										{item.text}
									</p>
									{/* btn */}
									<button className="bg-clr-primary-green py-3 px-2 text-white text-base font-semibold w-1/3 rounded-lg">
										Explore Now
									</button>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<img
				src={spot_d}
				alt="spot"
				className="absolute bottom-10 left-20 -z-10"
			/>
		</div>
	);
}

export default Packages;
