import React from "react";

// custom
import FlightCard from "./FlightCard";
import { useFlightContext } from "../../../contexts/flight_context";

function FlightList() {
	const { flights } = useFlightContext();
	return (
		<div className="flex flex-col space-y-8">
			{flights.length > 0 && (
				<>
					{flights.map((item: any) => {
						return <FlightCard key={item.ResultToken} data={item} />;
					})}
				</>
			)}
		</div>
	);
}

export default FlightList;
