import React, { useState, useRef, useEffect } from "react";

// lib
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format, addDays } from "date-fns";
import {
	FaArrowRight,
	FaCalendar,
	FaExchangeAlt,
	FaPlaneArrival,
	FaPlaneDeparture,
	FaTimes,
} from "react-icons/fa";
import { ImLocation } from "react-icons/im";

// custom
import { axios } from "../../../../utils/axios";
import UseRemoveInputFocus from "../../../../hooks/useRemoveInputFocus";

function FlightTour({ tourType, id, remove, add }: any) {
	/* form data*/
	// object to get data from tour input fields
	const [tourData, setTourData] = useState({
		id: id,
		Origin: "",
		Destination: "",
		DepartureDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
		ReturnDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
	});

	/* validation */
	// object to check validation from tour input fields
	const [tourValidation, setTourValidation] = useState({
		id: id,
		Origin: false,
		Destination: false,
		DepartureDate: false,
		ReturnDate: false,
	});

	/* error */
	// object to get errors from tour input fields
	const [tourErrors, setTourErrors] = useState({
		id: id,
		Origin: "",
		Destination: "",
		DepartureDate: "",
		ReturnDate: "",
	});

	// function to get value from tour input fields
	const handleInput = async (e: any) => {
		let name = e.currentTarget.name;
		let value = e.currentTarget.value;
		setTourData({ ...tourData, [name]: value });
		//console.log(name, value);
		// condition value bigger than 3 & not copied data
		if (value.length >= 3 && value.includes("(") === false) {
			// process for form input
			if (name === "Origin") {
				try {
					const res = await axios.get(
						"/v1/flight/search-airport?search=" + value
					);

					if (res.data.status == true && res.data.data.data.length > 0) {
						//console.log("from", res.data.data.data);
						setFromCitiesGenerated(res.data.data.data);
						setPlaceListOpen({ ...placeListOpen, Origin: true });
					} else {
						// set from error
						setTourErrors({ ...tourErrors, Origin: res.data.message });
						// set from validation
						setTourValidation({ ...tourValidation, Origin: false });
					}
				} catch (error) {
					// set from error
					setTourErrors({
						...tourErrors,
						Origin: "there is something wrong,Try Again",
					});
					// set from validation
					setTourValidation({ ...tourValidation, Origin: false });
				}
			}
			// process for to input
			if (name === "Destination") {
				try {
					const res = await axios.get(
						"/v1/flight/search-airport?search=" + value
					);
					if (res.data.status == true && res.data.data.data.length > 0) {
						//console.log("to", res.data.data.data);
						setToCitiesGenerated(res.data.data.data);
						setPlaceListOpen({ ...placeListOpen, Destination: true });
					} else {
						// set to error
						setTourErrors({ ...tourErrors, Destination: res.data.message });
						// set to validation
						setTourValidation({ ...tourValidation, Destination: false });
					}
				} catch (error) {
					// set to error
					setTourErrors({
						...tourErrors,
						Destination: "there is something wrong,Try Again",
					});
					// set to validation
					setTourValidation({ ...tourValidation, Destination: false });
				}
			}
		} else {
			setPlaceListOpen({
				Origin: false,
				Destination: false,
			});
		}
	};

	// auto generate cities for from & to inputs
	const [fromCitiesGenerated, setFromCitiesGenerated] = useState([]);
	const [toCitiesGenerated, setToCitiesGenerated] = useState([]);
	const [placeListOpen, setPlaceListOpen] = useState({
		Origin: false,
		Destination: false,
	});
	const FromListRef: any = useRef();
	const ToListRef: any = useRef();

	const handlePlace = (e: any, source: string) => {
		//console.log(e.target.innerText, source)
		setTourData({ ...tourData, [source]: e.target.innerText });
		setPlaceListOpen({ ...placeListOpen, [source]: false });
		// set from error
		setTourErrors({ ...tourErrors, [source]: "" });
		// set from validation
		setTourValidation({
			...tourValidation,
			[source]: e.target.innerText ? true : false,
		});
	};

	UseRemoveInputFocus(FromListRef, () =>
		setPlaceListOpen({ ...placeListOpen, Origin: false })
	);
	UseRemoveInputFocus(ToListRef, () =>
		setPlaceListOpen({ ...placeListOpen, Destination: false })
	);

	// for departure
	const DepartureRef: any = useRef();
	const [isOpenDeparture, setOpenDeparture] = useState(false);
	const [departureDate, setDepartureDate] = useState(
		format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
	);
	const handleSelectDeparture = (date: any) => {
		setDepartureDate(format(date, "yyyy-MM-dd'T'HH:mm:ss"));
		setTourData({
			...tourData,
			DepartureDate: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
		});
		setOpenDeparture(false);
	};
	UseRemoveInputFocus(DepartureRef, () => setOpenDeparture(false));

	// for departure
	const ReturnRef: any = useRef();
	const [isOpenReturn, setOpenReturn] = useState(false);
	const [returnDate, setReturnDate] = useState(
		format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
	);
	const handleSelectReturn = (date: any) => {
		setReturnDate(format(date, "yyyy-MM-dd'T'HH:mm:ss"));
		setTourData({
			...tourData,
			ReturnDate: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
		});
		setOpenReturn(false);
	};
	UseRemoveInputFocus(ReturnRef, () => setOpenReturn(false));

	// delete tour
	const handleDelete = (e: any) => {
		const id = e.currentTarget.id;
		if (tourData.id === id) {
			setTourData({
				...tourData,
				Origin: "",
				Destination: "",
				DepartureDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
				ReturnDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
			});
			remove(id);
		}
	};

	useEffect(() => {
		// assign data to parent
		add(tourData);
		// check validation
		setTourValidation({
			...tourValidation,
			Origin: tourData.Origin !== "" && tourData.Origin !== null ? true : false,
			Destination:
				tourData.Destination !== "" && tourData.Destination !== null
					? true
					: false,
			DepartureDate:
				tourData.DepartureDate !== "" && tourData.DepartureDate !== null
					? true
					: false,
			ReturnDate:
				tourData.ReturnDate !== "" && tourData.ReturnDate !== null
					? true
					: false,
		});
		// check if return date bigger than departure date
		if (
			new Date(tourData.ReturnDate) < new Date(tourData.DepartureDate) &&
			tourType == "Return"
		) {
			// check validation
			setTourValidation({
				...tourValidation,
				ReturnDate: false,
			});
			// set error
			setTourErrors({
				...tourErrors,
				ReturnDate: "Arrival Date can not be less than Dept date",
			});
		}

		// assign validation data to parent
		// addValidate(tourValidation)
		// assign error data to parent
		// addErrors(tourErrors)
	}, [tourData]);

	return (
		<div className="grid grid-cols-12 gap-2  md:pb-0 pb-10">
			{/* from & to */}
			<div className="s_1000:col-span-6 col-span-12 grid grid-cols-12 md:gap-0 gap-2 s_1000:border-r">
				{/* from */}
				<div className="flex flex-col space-y-1 md:col-span-5 col-span-12 relative md:border-none border-b">
					<label
						htmlFor="Origin"
						className="text-sm text-clr-simple-bg font-medium"
					>
						Flying From
					</label>
					<div className="flex flex-row items-center space-x-1">
						<span className={""}>
							<FaPlaneDeparture className={"text-clr-primary-green"} />
						</span>
						<input
							className={
								"py-1 px-1 placeholder:text-sm placeholder:text-clr-light-gray text-sm text-clr-simple-bg appearance-none outline-none"
							}
							id="Origin"
							placeholder="your place"
							type="text"
							name="Origin"
							autoComplete="off"
							value={tourData.Origin}
							onChange={(e) => handleInput(e)}
						/>
					</div>
					{fromCitiesGenerated.length > 0 && placeListOpen.Origin && (
						<div
							className={
								"bg-white rounded-lg shadow-listShadow absolute top-14 w-[350px] max-h-[250px] overflow-y-auto z-20"
							}
							ref={FromListRef}
						>
							<div className={"py-2 w-full"}>
								{fromCitiesGenerated.map((city: any) => {
									return (
										<button
											key={city.code}
											type={"button"}
											className={
												"group flex flex-row space-x-1 items-center justify-start py-3 px-6 border-b last:border-none w-full"
											}
											onClick={(e) => handlePlace(e, "Origin")}
											id={`${city.city}(${city.code})`}
										>
											<ImLocation
												className={"w-3 h-3 text-clr-primary-green"}
											/>
											<span
												className={
													"text-clr-light-gray group-hover:text-clr-primary-gray"
												}
											>
												{`${city.city}, ${city.country} (${city.code})`}
											</span>
										</button>
									);
								})}
							</div>
						</div>
					)}
				</div>
				{/* mark */}
				<span className="md:col-span-1 hidden md:flex flex-col items-center justify-end pb-2">
					{tourType == "Return" ? (
						<FaExchangeAlt className="text-clr-light-gray" />
					) : (
						<FaArrowRight className="text-clr-light-gray" />
					)}
				</span>
				{/* to */}
				<div className="flex flex-col space-y-1 md:col-span-5 col-span-12 relative md:border-none border-b">
					<label
						htmlFor="Destination"
						className="text-sm text-clr-simple-bg font-medium"
					>
						Flying To
					</label>
					<div className="flex flex-row items-center space-x-1 relative">
						<span className={""}>
							<FaPlaneArrival className={"text-clr-primary-green"} />
						</span>
						<input
							className={
								"py-1 px-1 placeholder:text-sm placeholder:text-clr-light-gray text-sm text-clr-simple-bg appearance-none outline-none"
							}
							id="Destination"
							placeholder="Destination"
							type="text"
							name="Destination"
							autoComplete="off"
							value={tourData.Destination}
							onChange={(e) => handleInput(e)}
						/>
					</div>
					{toCitiesGenerated.length > 0 && placeListOpen.Destination && (
						<div
							className={
								"bg-white rounded-lg shadow-listShadow absolute top-14 w-[350px] max-h-[250px] overflow-y-auto z-20"
							}
							ref={ToListRef}
						>
							<div className={"py-2 w-full"}>
								{toCitiesGenerated.map((city: any) => {
									return (
										<button
											key={city.code}
											type={"button"}
											className={
												"group flex flex-row space-x-1 items-center justify-start py-3 px-6 border-b last:border-none w-full"
											}
											onClick={(e) => handlePlace(e, "Destination")}
											id={`${city.city}(${city.code})`}
										>
											<ImLocation
												className={"w-3 h-3 text-clr-primary-green"}
											/>
											<span
												className={
													"text-clr-light-gray group-hover:text-clr-primary-gray"
												}
											>
												{`${city.city}, ${city.country} (${city.code})`}
											</span>
										</button>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>
			{/* departure & return */}
			<div className="s_1000:col-span-5 col-span-12 grid grid-cols-12 md:gap-0 gap-2">
				{/* departure */}
				<div className="flex flex-col space-y-1 md:col-span-6 col-span-12 relative md:border-none border-b">
					<label
						htmlFor="DepartureDate"
						className="text-sm text-clr-simple-bg font-medium"
					>
						Departure
					</label>
					<div className="flex flex-row items-center space-x-1">
						<span className={""}>
							<FaCalendar className={"text-clr-primary-green"} />
						</span>
						<input
							className={
								"py-1 px-1 placeholder:text-sm placeholder:text-clr-light-gray text-sm text-clr-simple-bg appearance-none outline-none cursor-pointer"
							}
							id="DepartureDate"
							placeholder="Departure Date"
							type="text"
							name="DepartureDate"
							autoComplete="off"
							value={tourData.DepartureDate}
							onChange={() => {}}
							onClick={() => setOpenDeparture(!isOpenDeparture)}
							readOnly
						/>
					</div>
					{isOpenDeparture && (
						<div
							ref={DepartureRef}
							className="bg-white rounded-lg z-20 shadow-listShadow absolute top-12 py-1 px-1"
						>
							<Calendar
								date={new Date()}
								onChange={handleSelectDeparture}
								minDate={new Date()}
								// maxDate={addDays(new Date(), 300)}
								className={"text-clr-primary-green "}
							/>
						</div>
					)}
				</div>
				{/* Return */}
				{tourType == "Return" && (
					<div className="flex flex-col space-y-1 md:col-span-6 col-span-12 relative md:border-none border-b">
						<label
							htmlFor="ReturnDate"
							className="text-sm text-clr-simple-bg font-medium"
						>
							Return
						</label>
						<div className="flex flex-row items-center space-x-1">
							<span className={""}>
								<FaCalendar className={"text-clr-primary-green"} />
							</span>
							<input
								className={
									"py-1 px-1 placeholder:text-sm placeholder:text-clr-light-gray text-sm text-clr-simple-bg appearance-none outline-none"
								}
								id="ReturnDate"
								placeholder="Retuen Date"
								type="text"
								name="ReturnDate"
								autoComplete="off"
								value={tourData.ReturnDate}
								onChange={() => {}}
								onClick={() => setOpenReturn(!isOpenReturn)}
								readOnly
							/>
						</div>
						{isOpenReturn && (
							<div
								ref={ReturnRef}
								className="bg-white rounded-lg z-20 shadow-listShadow absolute top-12 py-1 px-1"
							>
								<Calendar
									date={new Date()}
									onChange={handleSelectReturn}
									minDate={new Date()}
									// maxDate={addDays(new Date(), 300)}
									className={"text-clr-primary-green "}
								/>
							</div>
						)}
					</div>
				)}
			</div>
			{/* passenger */}
			{id !== "form_0" && id !== "form_1" && (
				<div className={"col-span-1"}>
					<button
						className={"text-red-600"}
						onClick={handleDelete}
						id={id}
						type="button"
					>
						<FaTimes className="" />
					</button>
				</div>
			)}
		</div>
	);
}

export default FlightTour;
