import React from "react";

// custom
import { aboutStatics } from "../../utils/constants";
import spot_u from "../../assets/imgs/g_spot_u.svg";
import spot_d from "../../assets/imgs/g_spot_d.svg";

function Statiscs() {
	return (
		<div className="relative bg-about_vision_bg bg-cover bg-no-repeat  bg-center py-20 flex flex-col items-center justify-center">
			<img src={spot_u} alt="spot" className="absolute top-32 right-20 -z-10" />
			<div className="max-w-custom-full w-smallWidth mx-auto">
				<div className="grid md:grid-cols-4 s_500:grid-cols-3 grid-cols-1 gap-5">
					{aboutStatics.map((item: any) => {
						return (
							<div
								key={item.id}
								className="flex flex-col items-center justify-center space-y-5"
							>
								<p className="text-clr-primary-green font-extrabold text-5xl">
									{item.count}
								</p>
								<h3 className="font-normal text-base text-clr-primary-gray">
									{item.title}
								</h3>
							</div>
						);
					})}
				</div>
			</div>
			<img
				src={spot_d}
				alt="spot"
				className="absolute bottom-10 left-20 -z-10"
			/>
		</div>
	);
}

export default Statiscs;
