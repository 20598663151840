import React, { useEffect } from "react";

//lib

// custom
import RegisterContent from "../../components/Register/RegisterContent";
import { useMainContext } from "../../contexts/main_context";

function RegisterPage() {
	const { changeTheme } = useMainContext();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		changeTheme({
			navShow: true,
			navbg: "white",
			footerShow: false,
		});
	}, []);

	return (
		<div className="bg-base_bg_1 bg-cover bg-no-repeat bg-center w-full">
			<div className="max-w-custom-full w-smallWidth mx-auto flex flex-col relative">
				<RegisterContent />
			</div>
		</div>
	);
}

export default RegisterPage;
