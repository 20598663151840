
// lib 
import { format, addDays } from "date-fns";

// custom
import {
  
	GET_FLIGHTS_BEGIN,
	GET_FLIGHTS_SUCCESS,
	GET_FLIGHTS_ERROR,
	SET_FLIGHTS_SESSION,
	UPDATE_FILTERS,
	FILTER_FLIGHTS,
	CLEAR_FILTERS,
  FILTER_MODAL_OPEN,
	FILTER_MODAL_CLOSE,
	GET_SINGLE_FLIGHTS_BEGIN,
	GET_SINGLE_FLIGHTS_SUCCESS,
	GET_SINGLE_FLIGHTS_ERROR,
  SET_PASSENGERS_DATA,
  SET_BOOKING_BODY,
	FLIGHT_BOOKING_BEGIN,
	FLIGHT_BOOKING_SUCCESS,
	FLIGHT_BOOKING_ERROR,
  } from '../utils/actions'
import { convertArrtoBase64String } from "../utils/helpers";
  
  
  const flight_reducer = (state : any , action : any) => {
  
    switch (action.type) {
      
      case GET_FLIGHTS_BEGIN:
        return {
          ...state,
          flights_loading: true,
          flights_error: false,
          flights: [],
          filterCategories: {
            stops: [],
            price: [],
            air_lines: [],
            duration: [],
            time: {
              arrivalTime: {
                startTime: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                endTime: format(
                  new Date().setHours(23, 59, 59, 999),
                  "yyyy-MM-dd HH:mm:ss"
                ),
              },
              departTime: {
                startTime: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                endTime: format(
                  new Date().setHours(23, 59, 59, 999),
                  "yyyy-MM-dd HH:mm:ss"
                ),
              },
            },
          },
          passengers : [],
          // filters : {
          //   stops: -1,
          //   min_price: state.filterCategories.price[0],
          //   max_price: state.filterCategories.price[1],
          //   min_duration: state.filterCategories.duration[0],
          //   max_duration: state.filterCategories.duration[1],
          //   airports: convertArrtoBase64String({"airports" : state.filterCategories.air_lines}),
          //   time: convertArrtoBase64String(state.filterCategories.time),
          //   sort: "price",
          // }
        }
      case GET_FLIGHTS_SUCCESS:
          const { flights , available_filters} = action.payload
    
          return {
            ...state,
            flights_loading: false,
            flights_error: false,
            flights: flights,
            filterCategories: available_filters,
            filters : {
              stops: -1,
              min_price: available_filters.price[0],
              max_price: available_filters.price[1],
              min_duration: available_filters.duration[0],
              max_duration: available_filters.duration[1],
              airports: convertArrtoBase64String({"airports" : available_filters.air_lines}),
              time: convertArrtoBase64String(available_filters.time),
              sort: "price",
            }
          }
      case GET_FLIGHTS_ERROR:
          return { ...state, flights_loading: false, flights_error: true }  
      case GET_SINGLE_FLIGHTS_BEGIN:
          return {
            ...state,
            single_flight_loading: true,
            single_flight_error: false,
            single_flight: {},
          }
      case GET_SINGLE_FLIGHTS_SUCCESS:
        const { currentFlight} = action.payload
  
        return {
          ...state,
          single_flight_error: false,
          single_flight_loading: false,
          single_flight: currentFlight,
        }
      case GET_SINGLE_FLIGHTS_ERROR:
        return { ...state, single_flight_loading: false, single_flight_error: true } 
      case SET_PASSENGERS_DATA:
        const { currentPassengers} = action.payload
        return {...state, passengers : currentPassengers} 

      case SET_BOOKING_BODY:
        const { bookingBodyReq} = action.payload
        return {...state, bookingBody : bookingBodyReq}  


      case FLIGHT_BOOKING_BEGIN:
        return {
          ...state,
          flight_booking_loading : true,
          flight_booking_error : false,
          flight_booking_success : false
        }
      case FLIGHT_BOOKING_SUCCESS:
        return {
          ...state,
          flight_booking_loading : false,
          flight_booking_error : false,
          flight_booking_success : true
        }
      case FLIGHT_BOOKING_ERROR:
        return {
          ...state,
          flight_booking_loading : false,
          flight_booking_error : true,
          flight_booking_success : false
        } 
        
      case UPDATE_FILTERS: 
        const { newFilter} = action.payload 
        return {...state, filters : newFilter} 
      case FILTER_FLIGHTS:
        const { filterdFlights} = action.payload 
        return {...state, flights : filterdFlights} 
      case CLEAR_FILTERS: 
        return {...state, filters : {
          stops: -1,
          min_price: state.filterCategories.price[0],
          max_price: state.filterCategories.price[1],
          min_duration: state.filterCategories.duration[0],
          max_duration: state.filterCategories.duration[1],
          airports: convertArrtoBase64String({"airports" : state.filterCategories.air_lines}),
          time: convertArrtoBase64String(state.filterCategories.time),
          sort: "price",
        }}
      case FILTER_MODAL_OPEN : 
        return {...state , isFilterModalOpen : true}  
      case FILTER_MODAL_CLOSE:
        return {...state , isFilterModalOpen : false}    
      default:
        return state
      //throw new Error(`No Matching "${action.type}" - action type`)
    }
  }
  
  export default flight_reducer