import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//custom
import { AuthProvider } from "./contexts/auth_context";
import { MainProvider } from "./contexts/main_context";
import { FlightProvider } from "./contexts/flight_context";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<AuthProvider>
		<MainProvider>
			<FlightProvider>
				<App />
			</FlightProvider>
		</MainProvider>
	</AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
