import React from "react";

//custom
import FlightPassengersCard from "./FlightPassengersCard";
import { getLocalStorage } from "../../../utils/helpers";

function FlightPassengers() {
	// get current search data
	const currentSearchData = getLocalStorage("flight_form");

	// adultcards
	let adultCards: any = [];
	Array.from({ length: currentSearchData.AdultCount }).forEach(
		(inpElement, index) => {
			adultCards.push(<FlightPassengersCard type={"Adult"} level={index} />);
		}
	);
	// child cards
	let childCards: any = [];
	Array.from({ length: currentSearchData.ChildCount }).forEach(
		(inpElement, index) => {
			childCards.push(<FlightPassengersCard type={"Child"} level={index} />);
		}
	);
	// infant cards
	let infantCards: any = [];
	Array.from({ length: currentSearchData.InfantCount }).forEach(
		(inpElement, index) => {
			infantCards.push(<FlightPassengersCard type={"Infant"} level={index} />);
		}
	);

	return (
		<div className="w-full flex flex-col space-y-5">
			{adultCards}

			{childCards}

			{infantCards}
		</div>
	);
}

export default FlightPassengers;
