import React from "react";

// custom
import featureImg from "../../assets/imgs/about_feature.png";
import { aboutFeatures } from "../../utils/constants";

function Features() {
	return (
		<div className="s_900:py-20 py-5 relative">
			<div className="max-w-custom-full w-smallWidth mx-auto flex s_900:flex-row flex-col">
				{/* description */}
				<div className="flex flex-col space-y-8 items-start justify-start s_900:w-1/2 ">
					<img src={featureImg} alt="feature" />
					<h1 className="capitalize text-clr-simple-bg text-5xl font-bold">
						What’s not to love?
					</h1>
					<p className="text-base text-clr-primary-gray font-normal">
						We pride ourselves in enhancing the travel booking experience
						complete with next-gen functionalities, payment capabilities and the
						best post-booking experience.
					</p>
				</div>
				{/* features */}
				<div className="flex flex-col items-center justify-start space-y-10 mt-12 s_900:w-1/2">
					{aboutFeatures.map((item: any) => {
						return (
							<div
								key={item.id}
								className="flex flex-row items-center justify-center space-x-4 border-b pb-5"
							>
								<span className="p-5 rounded-full bg-clr-stroke-gray">
									<img src={item.imgSrc} alt={item.title} />
								</span>
								<div className="flex flex-col space-y-2">
									<h3 className="text-clr-simple-bg text-xl font-bold">
										{item.title}
									</h3>
									<p className="text-sm font-normal text-clr-primary-gray">
										{item.desc}
									</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default Features;
