import React, { useState, useEffect } from "react";

// lib
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

// custom
import { classNames } from "../../utils/helpers";
import { useAuthContext } from "../../contexts/auth_context";

function PrivateKeyContent() {
	const { user } = useAuthContext();
	const navigate = useNavigate();
	const [pkey, setPKey] = useState("");
	const [copiedKey, setCopiedKey] = useState(false);
	const [downloadedKey, setDownloadedKey] = useState(false);

	const handleCopy = () => {
		const key: any = document.getElementById("key");
		navigator.clipboard.writeText(key.innerHTML);
		setCopiedKey(true);
		toast.success("key is copied", {
			position: "top-left",
		});
	};

	useEffect(() => {
		const hashedKey = Cookies.get("key");
		//console.log("key", hashedKey);
		if (hashedKey !== undefined) {
			const bytes = CryptoJS.AES.decrypt(
				hashedKey,
				process.env.REACT_APP_KEY_HASH
			);
			const decrypted = bytes.toString(CryptoJS.enc.Utf8);
			setPKey(decrypted);
		} else {
			navigate("/");
		}
	}, []);

	const handleDownload = () => {
		const key: any = document.getElementById("key");
		html2canvas(key).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF();
			//pdf.addImage(imgData, 'JPEG', 50, 20 , 100 , 30)
			pdf.text(key.innerHTML, 5, 20);
			pdf.save("private-key.pdf");
			setDownloadedKey(true);
			toast.success("key is downloaded", {
				position: "top-left",
			});
		});
	};

	const handleKey = () => {
		Cookies.remove("key");
		const currentAuth = {
			user: user.user,
			isLoggedin: true,
			keySaved: true,
		};
		localStorage.setItem("auth", JSON.stringify(currentAuth));
		navigate("/");
	};

	return (
		<div className="flex s_900:flex-row flex-col items-center justify-center s_900:my-10 my-8">
			<div className="py-20 my-5 rounded-3xl bg-white shadow-boxShadow flex flex-col space-y-12 items-center justify-center s_900:w-1/2 w-10/12 ">
				{/* head */}
				<div className="flex flex-col items-center text-center space-y-3">
					<h1 className="uppercase text-4xl font-semibold text-clr-primary-green font-jihoBold">
						Congrats !
					</h1>
					<p className="text-lg text-clr-primary-gray font-normal text-center w-[60%]">
						We have Created you CashCare Wallet Please{" "}
						<span className="text-clr-primary-green font-semibold">Copy</span>{" "}
						and{" "}
						<span className="text-clr-primary-green font-semibold">
							Download
						</span>{" "}
						You{" "}
						<span className="text-clr-primary-green font-semibold">
							Privet Key
						</span>{" "}
						Below
					</p>
				</div>
				{/* key */}
				<div className="flex flex-col space-y-1">
					<span className="w-[95%] mx-auto text-clr-simple-bg text-xl font-semibold">
						Private Key
					</span>
					<p
						id="key"
						className="py-4 px-8 bg-white rounded-xl shadow-boxShadow w-[95%] mx-auto break-all text-clr-light-gray text-xl font-semibold"
					>
						{pkey}
					</p>
				</div>
				{/* btns */}
				<div className="flex flex-col space-y-4 items-center justify-center w-[80%]">
					<button
						onClick={handleCopy}
						className="w-full rounded-xl bg-clr-primary-green uppercase py-2 text-xl font-bold text-white border border-clr-primary-green shadow-btnShadow hover:bg-transparent hover:text-clr-primary-green transition-all ease-in-out duration-75"
					>
						copy
					</button>
					<button
						onClick={handleDownload}
						className="w-full rounded-xl bg-white uppercase py-2 text-xl font-bold text-clr-primary-green border border-clr-primary-green hover:bg-clr-primary-green hover:text-white transition-all ease-in-out duration-75"
					>
						Download
					</button>
					<p className="text-lg text-clr-light-gray font-normal text-center mx-auto w-[85%]">
						You’ll be able to proceed after{" "}
						<span className="font-semibold">Copying</span> and{" "}
						<span className="font-semibold">Downloading</span> your{" "}
						<span className="font-semibold">private Key</span>
					</p>
				</div>
				{/* continue */}
				<div className="flex flex-col space-y-4 items-center justify-center w-[80%]">
					<button
						disabled={!copiedKey || !downloadedKey}
						onClick={() => handleKey()}
						className={classNames(
							!copiedKey || !downloadedKey ? "opacity-60" : "",
							"w-full rounded-xl bg-clr-primary-green uppercase py-2 text-xl font-bold text-white border border-clr-primary-green shadow-btnShadow hover:bg-transparent disabled:bg-clr-primary-green disabled:text-white hover:text-clr-primary-green transition-all ease-in-out duration-75"
						)}
					>
						CONTINUE
					</button>
				</div>
			</div>
		</div>
	);
}

export default PrivateKeyContent;
