import React, { useState, useEffect } from "react";

// lib
import { Modal } from "antd";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

// custom
import { useMainContext } from "../../contexts/main_context";
import { classNames } from "../../utils/helpers";
import { useFlightContext } from "../../contexts/flight_context";

// regex
const key_REGEX = /^[a-zA-Z0-9]{64,66}$/;

function PrivateKeyModal() {
	const { isPrivateKeyModalOpen, openPrivateKeyModal, closePrivateKeyModal } =
		useMainContext();
	const { bookingBody, setBookingBody } = useFlightContext();

	const [pKey, setPKey] = useState("");
	const [pKeyValid, setPKeyValid] = useState(false);
	const [pKeyFocus, setPKeyfocus] = useState(false);

	//set private key
	const handlePrivate = (e: any) => {
		const val = e.currentTarget.value;
		setPKey(val);
	};

	// save Data
	const handleSubmit = () => {
		if (pKeyValid) {
			const encrypted = CryptoJS.AES.encrypt(
				pKey,
				process.env.REACT_APP_KEY_HASH
			).toString();
			Cookies.set("key", encrypted, {
				expires: 7,
				path: "/",
			});
			setBookingBody({ ...bookingBody, private_key: encrypted });
			closePrivateKeyModal();
		} else {
			toast.error("Please fill in the private key field", {
				position: "top-left",
			});
		}
	};

	//check the validation for private key
	useEffect(() => {
		setPKeyValid(key_REGEX.test(pKey));
	}, [pKey]);

	return (
		<Modal
			title={
				<div className="flex flex-row space-x-1 items-center ">
					<h1 className="text-3xl font-bold text-clr-primary-green bg-transparent">
						Connect With Private Key
					</h1>
				</div>
			}
			style={{ top: 20 }}
			footer={<div></div>}
			open={isPrivateKeyModalOpen}
			onOk={closePrivateKeyModal}
			onCancel={closePrivateKeyModal}
		>
			{/* private */}
			<div className="flex flex-col space-y-8 py-5">
				<div className="flex flex-col items-start justify-start w-full space-y-1">
					<h2 className="text-2xl font-semibold text-clr-simple-bg ">
						Private key
					</h2>
					<input
						id="p_key"
						name="p_key"
						type={"password"}
						autoComplete="off"
						aria-invalid={pKeyValid ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() => setPKeyfocus(true)}
						onBlur={() => setPKeyfocus(false)}
						value={pKey}
						onChange={(e) => handlePrivate(e)}
						className={classNames(
							"px-4 py-2 rounded-xl bg-transparent w-full font-bold text-clr-primary-gray text-base outline-none",
							pKeyValid
								? "border-2 border-clr-primary-green"
								: "border border-clr-stroke",
							!pKeyValid && pKeyFocus
								? "border-2 border-red-600"
								: "border border-clr-stroke"
						)}
					/>
				</div>
			</div>

			<div className="flex flex-row space-x-5 items-center justify-center mt-14">
				<button
					onClick={handleSubmit}
					className="px-4 py-1 rounded-md shadow-2xl text-lg font-semibold bg-clr-primary-green text-white border-2 border-clr-primary-green hover:shadow-btnShadow"
				>
					submit
				</button>
			</div>
		</Modal>
	);
}

export default PrivateKeyModal;
