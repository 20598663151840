import React, { useState, useEffect } from "react";

// lib
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

//custom
import { classNames, getLocalStorage } from "../../../utils/helpers";
import { useFlightContext } from "../../../contexts/flight_context";

function FlightPaymentCard({ type, level }: any) {
	const [showDetails, setShowDetails] = useState(false);
	const { setPassengers, passengers } = useFlightContext();

	const currentID = type + "_" + level;
	const currentPassenger = passengers.find((item: any) => item.id == currentID);

	// get current search data
	const currentSearchData = getLocalStorage("flight_form");

	// keep first card open
	useEffect(() => {
		if (type == "Adult" && level == 0) {
			setShowDetails(true);
		}
	}, [type]);

	return (
		<div className="py-2 px-8 bg-white shadow-2xl shadow-clr-primary-gray rounded-md relative flex flex-col">
			<h1 className="text-clr-simple-bg text-lg font-semibold py-8">
				{type == "Child"
					? `Passenger ${level + 1 + currentSearchData.AdultCount}: (${type})`
					: type == "Infant"
					? `Passenger ${
							level +
							1 +
							currentSearchData.AdultCount +
							+currentSearchData.ChildCount
					  }: (${type})`
					: `Passenger ${level + 1}: (${type})`}
			</h1>
			<div
				className={classNames(
					" flex-col space-y-8 border-t pt-10 pb-16",
					showDetails ? "flex" : "hidden"
				)}
			>
				{/* row */}
				<div className="flex flex-col space-y-2">
					<h1 className="text-lg text-clr-primary-green font-semibold">
						Contact Details
					</h1>
					<p className="flex flex-row space-x-5 items-center text-clr-simple-bg text-sm">
						<span className="font-medium">Phone number:</span>
						<span className="font-semibold">{currentPassenger?.phone}</span>
					</p>
					<p className="flex flex-row space-x-5 items-center text-clr-simple-bg text-sm">
						<span className="font-medium">email:</span>
						<span className="font-semibold">{currentPassenger?.email}</span>
					</p>
				</div>
				{/* row */}
				<div className="flex flex-col space-y-2">
					<h1 className="text-lg text-clr-primary-green font-semibold">
						Passenger Details
					</h1>
					<p className="flex flex-row space-x-5 items-center text-clr-simple-bg text-sm">
						<span className="font-medium">Traveler 1: Adult</span>
						<span className="font-semibold text-clr-primary-green">
							{`${currentPassenger?.first_name} ${currentPassenger?.last_name}`}
						</span>
					</p>
					<p className="flex flex-row space-x-5 items-center text-clr-simple-bg text-sm">
						<span className="font-medium">gender:</span>
						<span className="font-semibold">{currentPassenger?.gender}</span>
					</p>
					<p className="flex flex-row space-x-5 items-center text-clr-simple-bg text-sm">
						<span className="font-medium">full name:</span>
						<span className="font-semibold">{`${currentPassenger?.first_name} ${currentPassenger?.last_name}`}</span>
					</p>
					<p className="flex flex-row space-x-5 items-center text-clr-simple-bg text-sm">
						<span className="font-medium">Passport Number:</span>
						<span className="font-semibold">
							{currentPassenger?.passport_number}
						</span>
					</p>
					<p className="flex flex-row space-x-5 items-center text-clr-simple-bg text-sm">
						<span className="font-medium">Passport Expiration:</span>
						<span className="font-semibold">
							{currentPassenger?.passport_expire_at}
						</span>
					</p>
				</div>
			</div>
			<span
				className="bg-white py-1 px-4 rounded-full shadow-xl shadow-clr-primary-gray cursor-pointer w-5 flex flex-col items-center justify-center absolute -bottom-3 left-1/2"
				onClick={() => setShowDetails(!showDetails)}
			>
				{showDetails ? (
					<IoIosArrowUp className="w-6 h-6" />
				) : (
					<IoIosArrowDown className="w-6 h-6" />
				)}
			</span>
		</div>
	);
}

export default FlightPaymentCard;
